import * as React from "react";
import ApolloVirtualizedTable, {
  GridColumn,
  ApolloListResult,
  DefaultPageInfoValue,
  ListItemRenderProps,
  ApolloVirtualizedTableProps,
} from "mui-apollo-virtualized-table";
import Skeleton from "@mui/material/Skeleton";
import { propertyTypeOptions } from "./propertyTypeOptions";
import { propertyStatusOptions } from "./propertyStatusOptions";
import { pricingMethodOptions } from "./pricingMethodOptions";
import { landTypeOptions } from "./landTypeOptions";
import accounting from "accounting";
import { propertyFindAllQuery } from "./graphql";
import { propertyActiveStatusOptions } from "./propertyActiveStatusOptions";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import { PropertyFindAll, PropertyFindAllVariables, PropertyFindAll_propertyQuery_properties_edges } from "./__generated__/PropertyFindAll";
import { ApolloConsumer } from "@apollo/client/react";
import { useNavigate } from "react-router-dom";
export type PropertyGridProps = {
  fetchAllVariables: PropertyFindAllVariables;
  onVariableChange: (variables: PropertyFindAllVariables) => void;
};

const PropertyVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<PropertyFindAll_propertyQuery_properties_edges>>;

const _sx = {
  loadingIndicator: {
    backgroundColor: "#DDDDDD",
    color: "#DDDDDD",
    width: 150,
    display: "inline-block",
  },
};


function PropertyGrid({ fetchAllVariables = { pagination: { pageSize: 20 } }, onVariableChange }: PropertyGridProps) {
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<PropertyFindAll_propertyQuery_properties_edges>>
  >([
    {
      label: "Code",
      key: "propertyCode",
      width: 150,
      sortable: true,
    },
    {
      label: "Owner",
      key: "owner_Name",
      format: ({ rowData }) => rowData.owner.name,
      width: 200,
      sortable: true,
    },
    {
      label: "Property Type",
      key: "propertyType",
      width: 150,
      sortable: true,
      format: ({ rowData }) => propertyTypeOptions[rowData.propertyType].text,
    },
    {
      label: "Property Status",
      key: "propertyStatus",
      width: 150,
      format: ({ rowData }) => propertyStatusOptions[rowData.propertyStatus].text,
      sortable: true,
    },
    {
      label: "Land Type",
      key: "landType",
      width: 150,
      format: ({ rowData }) => landTypeOptions[rowData.landType].text,
      sortable: true,
    },
    {
      label: "Pricing",
      key: "pricingMethod",
      width: 100,
      format: ({ rowData }) => pricingMethodOptions[rowData.pricingMethod].text,
      sortable: true,
    },
    {
      label: "Price",
      key: "price",
      width: 75,
      sortable: true,
      format: ({ rowData }) => rowData?.price ? accounting.formatNumber(rowData.price) : "",
      textAlign: "right"
    },
    {
      label: "Area",
      width: 75,
      sortable: true,
      key: "area",
      textAlign: "right"
    },
    {
      label: "Active",
      width: 100,
      sortable: true,
      key: "activeStatus",
      format: ({ rowData }) => propertyActiveStatusOptions[rowData.activeStatus].text
    },
    {
      label: "Address",
      width: 150,
      sortable: true,
      key: "area",
      flexGrow: 1,
      textAlign: "left"
    },
  ]);

  const listItemRenderer = React.useCallback(
    (renderProps: ListItemRenderProps<PropertyFindAll_propertyQuery_properties_edges>) => {
      const { rowData, key, style, className, onClick } = renderProps;
      if (rowData) {
        const { propertyCode, owner } = rowData;
        return (
          <div onClick={onClick} style={style} className={className} key={key}>
            <ListItem>
              <ListItemText primary={propertyCode} secondary={`${owner.code}-${owner.name}`} />
            </ListItem>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemText
                primary={
                  <Skeleton sx={_sx.loadingIndicator} />
                }
                secondary={
                  <Skeleton sx={_sx.loadingIndicator} />
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    []
  );

  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  return (
    <ApolloConsumer>
      {(client) => (
        <PropertyVirtualizedGrid
          onRowClick={(item, index) => {
            navigate(`/properties/${item.id}/edit`);
            setSelectedIndex(index);
          }}
          apolloClient={client}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={320}
          onColumnPropsChanged={(columns, orderBy: any) => {
            const newVariables: PropertyFindAllVariables = {
              ...fetchAllVariables,
              orderBy,
            };
            setColumns(columns);
            onVariableChange(newVariables);
          }}
          selectedItems={[selectedIndex]}
          columns={columns}
          graphqlQuery={propertyFindAllQuery}
          variables={fetchAllVariables}
          pageSize={fetchAllVariables?.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: PropertyFindAll) => {
            const list: ApolloListResult<PropertyFindAll_propertyQuery_properties_edges> = queryResult?.propertyQuery?.properties ??
            {
              edges: [],
              pageInfo: DefaultPageInfoValue,
            };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...fetchAllVariables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default PropertyGrid;
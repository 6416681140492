import { PropertyType } from "../../types/global-types";

const propertyTypeOptions: {[ key: string] : PropertyTypeOptionType} = {
    [PropertyType.APARTMENT]: { value: PropertyType.APARTMENT, text: "Apartment"},
    [PropertyType.MINI_CONDO] : {value:PropertyType.MINI_CONDO, text:"Mini Condo"},
    [PropertyType.CONDO]: { value:PropertyType.CONDO, text:"Condo"},
    [PropertyType.LAND]:{value:PropertyType.LAND, text:"Land"},
    [PropertyType.HOUSING]: { value:PropertyType.HOUSING, text:"Housing"},
    [PropertyType.ZONE] : { value: PropertyType.ZONE, text:"Zone"},
    [PropertyType.COMMERCIAL]: { value: PropertyType.COMMERCIAL, text: "Commercial" },
    [PropertyType.OFFICE] : {value:PropertyType.OFFICE, text:"Office"}
};

const propertyTypeOptionList = Object.keys(propertyTypeOptions).map(e => propertyTypeOptions[e]);



export type PropertyTypeOptionType = {
    value: PropertyType,
    text: string
};

export  { propertyTypeOptions ,  propertyTypeOptionList  };
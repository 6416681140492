import { UserRole } from "../../types/global-types";


const userRoleOptions: {[ key: string] : UserRoleOptionType} = {
    [UserRole.ADMIN]: { value: UserRole.ADMIN, text: "Admin"},
    [UserRole.OPERATOR]: { value: UserRole.OPERATOR, text: "Operator" },
    [UserRole.USER]: { value: UserRole.USER, text: "User" }
};

const userRoleOptionList = Object.keys(userRoleOptions).map(e => userRoleOptions[e]);



export type UserRoleOptionType = {
    value: UserRole,
    text: string
};

export  { userRoleOptions,  userRoleOptionList};
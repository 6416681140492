import gql from "graphql-tag";

const propertyFindAllQuery = gql`
  query PropertyFindAll(
    $where: PropertyWhereInput
    $orderBy: [PropertyOrderByInput]
    $pagination: PaginationInput
  ) {
    propertyQuery {
      id
      properties(where: $where, orderBy: $orderBy, pagination: $pagination) {
        edges {
          id
          address
          area
          city {
            id
            alias
            name
            region {
              id
              alias
              name
            }
          }
          electricAndWaterMeter
          elevator
          floor
          height
          homeLoanAvailable
          landType
          length
          mBRoom
          owner {
            id
            code
            name
          }
          price
          pricingMethod
          propertyCode
          propertyType
          propertyStatus
          activeStatus
          sBRoom
          swimmingPool
          width
          sortOrder
          createdAt
          updatedAt
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          pageCount
          pageSize
          rowCount
        }
      }
    }
  }
`;

const deletePropertyPhotoMutation = gql`
  mutation DeletePropertyPhoto($id: ID!) {
    propertyPhotoMutation {
      id
      deletePropertyPhoto(id: $id)
    }
  }
`;

const checkPropertyCodeExistsQuery = gql`
  query CheckPropertyCodeExists($id: ID, $checkFor: String!) {
    propertyQuery {
      id
      propertyCodeExists(id: $id, checkFor: $checkFor)
    }
  }
`;

const getPropertyByIdQuery = gql`
  query GetPropertyById($id: ID!) {
    propertyQuery {
      id
      property(id: $id) {
        id
        address
        area
        city {
          id
          alias
          name
          region {
            id
            alias
            name
            sortOrder
          }
          sortOrder
        }
        electricAndWaterMeter
        elevator
        floor
        height
        homeLoanAvailable
        landType
        length
        mBRoom
        owner {
          id
          code
          name
        }
        price
        pricingMethod
        propertyCode
        propertyType
        propertyStatus
        sBRoom
        swimmingPool
        width
        propertyPhotos {
          id
          caption
          description
          sortOrder
          url
          propertyId
        }
        activeStatus
        about
        sortOrder
        createdAt
        updatedAt
      }
    }
  }
`;

const propertyPhotosFragment = gql`
  fragment PropertyPhotos on Property {
    id
    propertyPhotos {
      id
      caption
      description
      sortOrder
      url
      propertyId
    }
  }
`;

const deletePropertyMutation = gql`
  mutation DeleteProperty($id: ID!) {
    propertyMutation {
      id
      deleteProperty(id: $id)
    }
  }
`;

const duplicatePropertyMutation = gql`
mutation DuplicateProperty($id:ID!,$propertyCode:String!,$includePhoto:Boolean!){
  propertyMutation{
    id
    duplicateProperty(id:$id,propertyCode:$propertyCode, includePhoto:$includePhoto){
      id
        address
        area
        city {
          id
          alias
          name
          region {
            id
            alias
            name
            sortOrder
          }
          sortOrder
        }
        electricAndWaterMeter
        elevator
        floor
        height
        homeLoanAvailable
        landType
        length
        mBRoom
        owner {
          id
          code
          name
        }
        price
        pricingMethod
        propertyCode
        propertyType
        propertyStatus
        sBRoom
        swimmingPool
        width
        propertyPhotos {
          id
          caption
          description
          sortOrder
          url
          propertyId
        }
        activeStatus
        about
        sortOrder
        createdAt
        updatedAt
      }
    }
}

`;

const savePropertyMutation = gql`
  mutation SaveProperty($property: PropertyInput!) {
    propertyMutation {
      id
      saveProperty(property: $property) {
        id
        address
        area
        city {
          id
          alias
          name
          region {
            id
            alias
            name
            sortOrder
          }
          sortOrder
        }
        electricAndWaterMeter
        elevator
        floor
        height
        homeLoanAvailable
        landType
        length
        mBRoom
        owner {
          id
          code
          name
        }
        price
        pricingMethod
        propertyCode
        propertyType
        propertyStatus
        sBRoom
        swimmingPool
        width
        propertyPhotos {
          id
          url
          propertyId
          caption
          description
          sortOrder
        }
        activeStatus
        about
        sortOrder
        createdAt
        updatedAt
      }
    }
  }
`;

export {
  propertyFindAllQuery,
  getPropertyByIdQuery,
  deletePropertyMutation,
  savePropertyMutation,
  checkPropertyCodeExistsQuery,
  deletePropertyPhotoMutation,
  propertyPhotosFragment,
  duplicatePropertyMutation
};

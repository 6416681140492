import * as React from "react";
import RegionEditControl from "./RegionEditControl";
import DialogActionLoadingIndicator from "../components/DialogActionLoadingIndicator";
import { createRegionMutation, regionFindAllQuery } from "./graphql";
import update from "immutability-helper";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { RegionFindAll, RegionFindAllVariables } from "./__generated__/RegionFindAll";
import { CityFindAll_cityQuery_cities_edges_region } from "./__generated__/CityFindAll";
import { CreateRegion, CreateRegionVariables } from "./__generated__/CreateRegion";
import { useMutation } from "@apollo/client";
export type RegionCreateDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchAllVariables: RegionFindAllVariables;
  onError: (error: Error) => void;
};
const regionDefaultValue: CityFindAll_cityQuery_cities_edges_region = {
  id: null,
  name: "",
  alias: "",
  sortOrder: null,
};
function RegionCreateDialog({
  open,
  onClose,
  fetchAllVariables,
  onError,
}: RegionCreateDialogProps) {
  const [draft, setDraft] = React.useState<CityFindAll_cityQuery_cities_edges_region>(regionDefaultValue);

  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [
    createRegion,
    { loading: creatingRegion, error: createError },
  ] = useMutation<CreateRegion, CreateRegionVariables>(
    createRegionMutation,
    {
      update: (cache, { data }) => {
        if (!data)
          return;
        if (data?.regionMutation?.createRegion) {
          const old = cache.readQuery<
            RegionFindAll,
            RegionFindAllVariables
          >({ query: regionFindAllQuery, variables: fetchAllVariables });
          if (!old)
            return;
          cache.writeQuery<RegionFindAll, RegionFindAllVariables>({
            query: regionFindAllQuery,
            variables: fetchAllVariables,
            data: update(old, {
              regionQuery: {
                regions: {
                  edges: {
                    $push: [data.regionMutation.createRegion],
                  },
                  pageInfo: {
                    rowCount: {
                      $set: old?.regionQuery?.regions?.pageInfo?.rowCount ?? 0 + 1,
                    },
                  },
                },
              },
            }),
          });
        }
      },
    }
  );

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.alias) {
      e.alias = "";
    } else {
      valid = false;
      e.alias = "Alias is required";
    }
    if (draft.name) {
      e.name = "";
    } else {
      valid = false;
      e.alias = "Name is required";
    }

    setError(e);
    return valid;
  }, [draft.alias, draft.name, error]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const { name, alias, sortOrder } = draft;
      await createRegion({
        variables: {
          region: {
            id: null,
            name,
            alias,
            sortOrder,
          },
        },
      });
      setDraft(regionDefaultValue);
      onClose();
    }
  }, [createRegion, draft, onClose, validate]);

  React.useEffect(() => {
    if (createError) onError(createError);
  }, [createError, onError]);
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Create New Region</DialogTitle>
      <DialogContent>
        <RegionEditControl
          value={draft}
          setValue={setDraft}
          error={error}
          setError={setError}
        />
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={creatingRegion}
          text="Creating region..."
        />
        <Button disabled={creatingRegion} onClick={submit} color="secondary">
          OK
        </Button>
        <Button disabled={creatingRegion} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RegionCreateDialog;

import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText/DialogContentText";
import * as React from "react";

export type ActivityIndicatorDialogProps = {
  open: boolean;
  message: string;
};

const _sx={
  dialogContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  message: {
    width: 300,
    paddingTop:2
  }
};

function ActivityIndicatorDialog(props: ActivityIndicatorDialogProps) {
  const { open, message } = props;
  return (
    <Dialog open={open}>
      <DialogContent sx={_sx.dialogContent}>
        <CircularProgress />
        <DialogContentText sx={_sx.message}>
          {message}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ActivityIndicatorDialog;

import * as React from "react";
import update from "immutability-helper";
import NumberEditor from "./NumberEditor";
import Chip from "@mui/material/Chip/Chip";
import Box from '@mui/material/Box';
interface Props {
  value: ReadonlyArray<number>;
  onChanged?: (value: ReadonlyArray<number>) => void;
  allowDuplicate?: boolean;
}

const _sx = {
  root: {
    borderColor: "divider",
    borderWidth: 1,
    borderStyle: "solid",
    padding: .5,
  },
  chip: {
    margin: .5,
  },
  numberEditor: {
    width: 150,
    paddingLeft: .5,
    paddingRight: .5,
  },
  alignLeft: {
    textAlign: "left",
  },
};

type State = {
  editingNumber?: number;
};

class NumberListEditor extends React.Component<Props, State> {
  inputEl?: HTMLInputElement = undefined;
  state: State = {
    editingNumber: undefined,
  };
  static defaultProps: Props = {
    value: [],
  };
  onDelete(index: number) {
    const { onChanged, value } = this.props;
    const newValue = update(value, {
      $splice: [[index, 1]],
    });
    if (onChanged)
      onChanged(newValue);
  }
  addItem(v: number) {
    const { value, onChanged } = this.props;
    const newValue = update(value, {
      $push: [v],
    });
    if (onChanged)
      onChanged(newValue);
  }
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { editingNumber } = this.state;
    const { value } = this.props;
    switch (e.key) {
      case "backspace":
        if (
          (editingNumber === undefined || editingNumber === null) &&
          value.length > 0
        ) {
          this.onDelete(value.length - 1);
        }
    }
  };
  render() {
    const { value, allowDuplicate } = this.props;
    const { editingNumber } = this.state;
    return (
      <Box
        sx={_sx.root}
        onClick={() => {
          this.inputEl?.focus();
        }}
      >
        {value.map((v, index) => (
          <Chip
            sx={_sx.chip}
            key={allowDuplicate ? index.toString() : v?.toString()}
            label={v?.toString()}
            onDelete={() => {
              this.onDelete(index);
            }}
          />
        ))}
        <NumberEditor
          value={editingNumber}
          sx={_sx.numberEditor}
          inputElementType="InputBase"
          placeholder="Enter number"
          inputReference={(input: any) => {
            this.inputEl = input;
          }}
          onValidating={(v) => {
            if (v === null || v === undefined) return "Value is empty";
            if (!allowDuplicate && value.indexOf(v) > -1) {
              return "Duplicate value";
            } else return "";
          }}
          onChanged={(v) => {
            this.setState({ editingNumber: v });
          }}
          onValidated={(v) => {
            if(v)
              this.addItem(v);
          }}
          onKeyDown={this.onKeyDown.bind(this)}
          validateOnBlur={false}
        />
      </Box>
    );
  }
}

export default NumberListEditor;

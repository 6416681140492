import * as React from "react";
import { resetPasswordMutation } from "./graphql";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText/DialogContentText";
import Grid from "@mui/material/Grid/Grid";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { UserFindAll_userQuery_users_edges } from "./__generated__/UserFindAll";
import { ResetPassword, ResetPasswordVariables } from "./__generated__/ResetPassword";
import { UserRole } from "../../types/global-types";
import { useMutation } from "@apollo/client";
import TextEditor from "../../components/TextEditor";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
export type RegisterUserDialogProps = {
  open: boolean;
  onClose: () => void;
  edit: UserFindAll_userQuery_users_edges;
};

const _sx = {
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
};

type ResetPasswordUserType = {
  id: string;
  name: string;
  fullName: string;
  password: string;
  confirmPassword: string;
  userRole: UserRole;
};

function ResetPasswordDialog({ open, onClose, edit }: RegisterUserDialogProps) {
  const [draft, setDraft] = React.useState<ResetPasswordUserType>({
    ...edit,
    password: "",
    confirmPassword: "",
    id: edit?.id??""
  });

  React.useEffect(() => {
    setDraft({ ...edit, password: "", confirmPassword: "",id: edit?.id! });
  }, [edit]);

  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [
    resetPassword,
    { error: resetPasswordError, loading: resetingPassword },
  ] = useMutation<ResetPassword, ResetPasswordVariables>(
    resetPasswordMutation
  );

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.password) {
      e.password = "";
    } else {
      valid = false;
      e.password = "Enter password";
    }
    if (draft.confirmPassword) {
      e.confirmPassword = "";
    } else {
      valid = false;
      e.confirmPassword = "Enter passward again!";
    }

    if (draft.password && draft.confirmPassword) {
      if (draft.password !== draft.confirmPassword) {
        valid = false;
        e.password = "Password not match";
        e.confirmPassword = "Password not match";
      } else {
        e.password = "";
        e.confirmPassword = "";
      }
    }
    setError(e);
    return valid;
  }, [draft.confirmPassword, draft.password, error]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const {  password } = draft;
      await resetPassword({
        variables: {
          id: edit.id!,
          newPassword: password,
        },
      });
      onClose();
    }
  }, [draft, edit?.id, onClose, resetPassword, validate]);
  const [openErrorMsgDialog, setOpenErrorMsgDialog] = React.useState(false);
  React.useEffect(() => {
    if (resetPasswordError) setOpenErrorMsgDialog(true);
  }, [resetPasswordError]);
  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="sm" open={open && Boolean(edit?.id)}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>{draft.fullName}</DialogContentText>
          <Grid container direction="column">
            <TextEditor
              sx={_sx.formControl}
              label="Password"
              type="password"
              value={draft.password}
              error={!!error.password}
              helperText={error.password}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  password: v,
                });
                setError({ ...error, password: "" });
              }}
            />
            <TextEditor
              sx={_sx.formControl}
              label="Confirm password"
              type="password"
              value={draft.confirmPassword}
              error={!!error.confirmPassword}
              helperText={error.confirmPassword}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  confirmPassword: v,
                });
                setError({ ...error, confirmPassword: "" });
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActionLoadingIndicator
            loading={resetingPassword}
            text="Reseting password..."
          />
          <Button
            disabled={resetingPassword}
            onClick={submit}
            color="secondary"
          >
            OK
          </Button>
          <Button disabled={resetingPassword} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {resetPasswordError ? (
        <ErrorMessageDialog
          open={openErrorMsgDialog}
          onClose={() => {
            setOpenErrorMsgDialog(false);
          }}
          title={`Error`}
          message="Could not reset password."
          detail={resetPasswordError.message}
        />
      ) : null}
    </React.Fragment>
  );
}

export default ResetPasswordDialog;

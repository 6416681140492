import Grid from "@mui/material/Grid/Grid";
import * as React from "react";

const _sx={
    root: {
      paddingLeft:2,
      paddingRight:2
    }
  };



class FieldGroup extends React.PureComponent<{children: React.ReactNode}> {
  render() {
    const { children } = this.props;
    return (
      <Grid container sx={_sx.root}  item direction="column" md={6}>
        {children}
      </Grid>
    );
  }
}
export default FieldGroup;

import * as React from "react";
import { getOwnerByIdQuery, ownerFragment } from "./graphql";
import { ownerTypeOptions } from "./ownerTypeOptions";
import Edit from "@mui/icons-material/Edit";
import update from "immutability-helper";
import Container from "@mui/material/Container/Container";
import IconButton from "@mui/material/IconButton/IconButton";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import { GetOwnerById, GetOwnerByIdVariables, GetOwnerById_ownerQuery_owner } from "./__generated__/GetOwnerById";
import { useApolloClient, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import config from "../../config.json";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import FilePickerHidden from "../../components/FilePickerHidden";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";

const _sx = {
  profilePicWrapper: {
    position: "absolute",
    width: 128,
    height: 128,
    bottom: 0,
    left: 32,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  headerOffset: {
    height: 32,
  },
  headerBg: {
    height: 320,
    marginLeft: -24,
    marginRight: -24,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  container: {
    background:"background.default",
    "WebkitBoxShadow": "0px 0px 6px 0px rgba(0,0,0,0.75)",
    "MozBoxShadow": "0px 0px 6px 0px rgba(0,0,0,0.75)",
    boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)",
  },
  nameHeader: {
    marginBottom: 1,
  },
  codeHeader: {
    marginTop: 0,
  },
  fieldLabel: {
    fontWeight: "bold",
    marginRight: 1,
  },
  address: {
    marginTop: 2,
  },

  profilePicEditBtn: {
    marginTop: 5,
    marginLeft: 5,
    backgroundColor: "#ffffff40",
  },
  coverPicEditBtn: {
    position: "absolute",
    left: "50%",
    bottom: "50%",
    backgroundColor: "#ffffff40",
  },
  profilePhotoUploadingProgress: {
    position: "absolute",
    left: "50%",
    bottom: "50%",
    marginLeft: -20,
    marginBottom: -20
  },
  coverPhotoUploadingProgress: {
    position: "absolute",
    left: "50%",
    bottom: "50%",
  },
};

function Profile() {
  const { id: ownerId } = useParams<{ id: string }>();
  const { data: ownerQueryResult } = useQuery<
    GetOwnerById,
    GetOwnerByIdVariables
  >(getOwnerByIdQuery, {
    variables: {
      id: ownerId!,
    },
    skip: !ownerId,
    fetchPolicy: "network-only",
  });

  const coverPhotoInputRef = React.useRef<HTMLInputElement>(null);
  const profilePhotoInputRef = React.useRef<HTMLInputElement>(null);
  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");

  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );
  const [coverPhoto, setCoverPhoto] = React.useState("");
  const [coverPhotoUploading, setCoverPhotoUploading] = React.useState(false);

  const [profilePhoto, setProfilePhoto] = React.useState("");
  const [profilePhotoUploading, setProfilePhotoUploading] = React.useState(
    false
  );

  React.useEffect(() => {
    const coverPhoto = ownerQueryResult?.ownerQuery?.owner?.coverPhoto;
    if (coverPhoto) setCoverPhoto(`${config.cloudfront_url}${coverPhoto}`);
    else setCoverPhoto(`${config.cloudfront_url}owner/cover/default.jpg`);

    const profilePhoto = ownerQueryResult?.ownerQuery?.owner?.profilePhoto;
    if (profilePhoto)
      setProfilePhoto(`${config.cloudfront_url}${profilePhoto}`);
    else setProfilePhoto("");
  }, [ownerQueryResult]);

  const token = useSelector(
    (state: RootState) => state.site.userSession.accessToken
  );

  var apolloClient = useApolloClient();

  return (
    <Container maxWidth="md" sx={_sx.container}>
      <Box sx={{position:"relative", bgcolor:"action.active"}}>
        <Box
          style={
            coverPhoto
              ? {
                backgroundImage: `url(${coverPhoto})`,
              }
              : {}
          }
          sx={_sx.headerBg}
        ></Box>
        <Box sx={_sx.headerOffset}></Box>
        <Box
          sx={_sx.profilePicWrapper}
          style={
            profilePhoto
              ? {
                backgroundImage: `url(${profilePhoto})`,
              }
              : { backgroundColor: "#fff" }
          }
        >
          {
            profilePhotoUploading ? <CircularProgress sx={_sx.profilePhotoUploadingProgress} /> : <IconButton
              onClick={() => {
                profilePhotoInputRef.current?.click();
              }}
              color="secondary"
              sx={_sx.profilePicEditBtn}
              aria-label="Change profile picture"
            >
              <Edit />
            </IconButton>
          }
        </Box>

        {coverPhotoUploading ? (
          <CircularProgress sx={_sx.coverPhotoUploadingProgress} />
        ) : (
          <IconButton
            onClick={() => {
              coverPhotoInputRef.current?.click();
            }}
            color="secondary"
            sx={_sx.coverPicEditBtn}
            aria-label="Change cover photo"
          >
            <Edit />
          </IconButton>
        )}
      </Box>
      <Box>
        <Typography variant="h2" sx={_sx.nameHeader}>
          {ownerQueryResult?.ownerQuery?.owner?.name}
        </Typography>
        <Typography variant="h4" sx={_sx.codeHeader}>
          {ownerQueryResult?.ownerQuery?.owner?.code}
        </Typography>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography sx={_sx.fieldLabel}>Owner type:</Typography>
            <Typography>
              {ownerQueryResult?.ownerQuery?.owner?.ownerType
                ? ownerTypeOptions[
                  ownerQueryResult?.ownerQuery?.owner?.ownerType
                ].text
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={_sx.fieldLabel}>Contact name:</Typography>
            <Typography>{ownerQueryResult?.ownerQuery?.owner?.contactPerson}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={_sx.fieldLabel}>Contact number:</Typography>
            <Typography>{ownerQueryResult?.ownerQuery?.owner?.contactNumber}</Typography>
          </Grid>
        </Grid>
        <Typography  sx={_sx.address}>
          {ownerQueryResult?.ownerQuery?.owner?.address}
        </Typography>
      </Box>
      <FilePickerHidden
        inputRef={coverPhotoInputRef}
        multiple={false}
        accept="image/*"
        onFilesAccepted={async (files) => {
          const [file] = files;
          if (file) {
            const image = new Image();
            image.onload = async (e) => {
              if (image.width < 960 || image.height < 320) {
                setOperationError(
                  new Error(
                    "Cover photo must have at least 960px x 320px width and height."
                  )
                );
                setOperationErrorMessage("Invalid image size.");
                setErrorMessageDialogOpen(true);
              } else {
                setCoverPhoto(image.src);

                const form = new FormData();
                const fileExt =
                  file.name.substring(
                    file.name.lastIndexOf(".") + 1,
                    file.name.length
                  ) || file.name;
                form.append("file", file, `${ownerId}.${fileExt}`);
                try {
                  setCoverPhotoUploading(true);
                  const result = await fetch("/FileUpload/OwnerCoverPhoto", {
                    method: "POST",
                    body: form,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  var cachedOwnerInfo = apolloClient.readFragment<
                    Partial<GetOwnerById_ownerQuery_owner>
                  >({
                    id: `Owner:${ownerId}`,
                    fragment: ownerFragment,
                  });
                  const uploadedUrl = await result.text();
                  apolloClient.writeFragment({
                    id: `Owner:${ownerId}`,
                    fragment: ownerFragment,
                    data: update(cachedOwnerInfo, {
                      coverPhoto: {
                        $set: uploadedUrl,
                      },
                    }),
                  });
                } finally {
                  setCoverPhotoUploading(false);
                }
              }
            };
            image.src = window.URL.createObjectURL(file);
          }
        }}
      />

      <FilePickerHidden
        inputRef={profilePhotoInputRef}
        multiple={false}
        accept="image/*"
        onFilesAccepted={async (files) => {
          const [file] = files;
          if (file) {
            const image = new Image();
            image.onload = async (e) => {
              if (image.width < 128 || image.height < 128) {
                setOperationError(
                  new Error(
                    "Profile photo must have at least 128px x 128px width and height."
                  )
                );
                setOperationErrorMessage("Invalid image size.");
                setErrorMessageDialogOpen(true);
              } else {
                setProfilePhoto(image.src);

                const form = new FormData();
                const fileExt =
                  file.name.substring(
                    file.name.lastIndexOf(".") + 1,
                    file.name.length
                  ) || file.name;
                form.append("file", file, `${ownerId}.${fileExt}`);
                try {
                  setProfilePhotoUploading(true);
                  const result = await fetch("/FileUpload/OwnerProfilePhoto", {
                    method: "POST",
                    body: form,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  var cachedOwnerInfo = apolloClient.readFragment<
                    Partial<GetOwnerById_ownerQuery_owner>
                  >({
                    id: `Owner:${ownerId}`,
                    fragment: ownerFragment,
                  });
                  const uploadedUrl = await result.text();
                  apolloClient.writeFragment({
                    id: `Owner:${ownerId}`,
                    fragment: ownerFragment,
                    data: update(cachedOwnerInfo, {
                      profilePhoto: {
                        $set: uploadedUrl,
                      },
                    }),
                  });
                } finally {
                  setProfilePhotoUploading(false);
                }
              }
            };
            image.src = window.URL.createObjectURL(file);
          }
        }}
      />
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          title={`Error`}
          message={operationErrorMessage}
          detail={operationError.message}
        />
      ) : null}
    </Container>
  );
}

export default Profile;

import { LandType } from "../../types/global-types";



const landTypeOptions: {[ key: string] : LandTypeOptionType} = {
    [LandType.FREEHOLD]: { value: LandType.FREEHOLD, text: "Freehold" },
    [LandType.GRANT]: {value: LandType.GRANT, text:"Grant"},
    [LandType.AGRICULTURAL]: { value: LandType.AGRICULTURAL, text: "Agricultural"},
    [LandType.GARDEN]: {value: LandType.GARDEN, text:"Garden"},
    [LandType.ZONE]: {value:LandType.ZONE, text:"Zone"},    
};

const landTypeOptionList = Object.keys(landTypeOptions).map(e => landTypeOptions[e]);



export type LandTypeOptionType = {
    value: LandType,
    text: string
};

export  { landTypeOptions ,  landTypeOptionList  };
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";

function DrawerHeader(props: { open: boolean; toggleDrawer: () => void }) {
    const { open, toggleDrawer } = props;
    return (
      <Hidden xsDown implementation="css">
        <Box paddingLeft={1} paddingRight={1} display="flex" flexWrap="nowrap">
          <Box flex={1} />
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        <Divider />
      </Hidden>
    );
  }

  export default DrawerHeader;
import * as React from "react";
import accounting from "accounting";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import TextField from "@mui/material/TextField/TextField";
import Input, { InputProps } from "@mui/material/Input/Input";
import InputBase, { InputBaseProps } from "@mui/material/InputBase/InputBase";

const _sx = {
  input: {
    textAlign: "right",
  },
};

function moveDecimal(n: number, l: number) {
  let v = l > 0 ? n * Math.pow(10, l) : n / Math.pow(10, l * -1);
  return v;
}

type OwnProps = {
  validateOnBlur?: boolean;
  retainFocusOnError?: boolean;
  onValidating?: (value?: number) => string;
  onValidated: (value?: number) => void;
  selectAllOnFocus?: boolean;
  numberPrecision?: number;
  value?: number;
  inputElementType?: "InputBase" | "Input" | "TextField";
  onChanged?: (value: number) => void;
  inputReference?: (ref: React.Ref<any>) => void;
  showErrorAsHelperText?: boolean;

};

export type NumberEditorProps = OwnProps & TextFieldProps;
function NumberEditor(props: NumberEditorProps) {
  const {
    showErrorAsHelperText,
    error,
    helperText,
    onValidating,
    onValidated,
    selectAllOnFocus,
    numberPrecision,
    onBlur,
    value,
    onFocus,
    onKeyDown,
    onKeyPress,
    inputRef,
    inputReference,
    onChanged,
    inputProps,
    inputElementType,
    retainFocusOnError,
    validateOnBlur,
    ...textFieldProps
  } = props;
  let _Component: any = TextField;
  switch (inputElementType) {
    case "Input":
      _Component = Input;
      break;
    case "InputBase":
      _Component = InputBase;
      break;
    case "TextField":
    default:
      break;
  }

  const InputComponent = _Component as React.ElementType<TextFieldProps | InputProps | InputBaseProps>;
  const textField = React.useRef<HTMLInputElement>(null);
  const [active, setActive] = React.useState<boolean>(false);
  const [draft, setDraft] = React.useState<string>(
    value === 0 || value ? value.toString() : ""
  );
  const shouldValidateOnBlur = React.useRef<boolean>(true);
  React.useEffect(() => {
    setDraft(value === 0 || value ? value.toString() : "");
  }, [value]);

  const num = React.useMemo(() => {
    if (!draft) return undefined;
    else {
      const n = Number.parseFloat(draft);
      return isNaN(n) ? undefined : n;
    }
  }, [draft]);
  const text = React.useMemo(() => {
    if (!draft) return "";
    else return active ? draft : accounting.formatNumber(num??0, numberPrecision);
  }, [draft, active, num, numberPrecision]);
  const validate = () => {
    if (num === value) {
      setValidationError("");
      return true;
    }
    if (onValidating) {
      const error = onValidating(num);
      setValidationError(error);
      if (!error) {
        onValidated(num);
        return true;
      } else {
        return false;
      }
    } else {
      onValidated(num);
      return true;
    }
  };
  const [validationError, setValidationError] = React.useState("");
  const handleUpDownKey = (isUp: boolean) => {
    let n = num;
    if (!n) n = 0;
    let dec = text.indexOf(".");
    let sign = isUp ? 1 : -1;
    if (dec > -1) {
      // right of decimal
      let digitsAfterPoint = text.split(".")[1].length;
      let fnum = moveDecimal(n, digitsAfterPoint);
      fnum += sign;
      n = moveDecimal(fnum, digitsAfterPoint * -1);
    } else {
      // no decimal
      n += sign;
    }
    setDraft(n.toString());
  };
  return (
    <InputComponent
      helperText={
        validationError && showErrorAsHelperText ? validationError : helperText
      }
      error={!!validationError || error}
      inputRef={textField}
      sx={{ "& .MuiInputBase-input": _sx.input }}
      {...textFieldProps}
      value={text}
      onFocus={(e: any) => {
        setActive(true);
        if (selectAllOnFocus) {
          setTimeout(() => {
            textField?.current?.setSelectionRange(
              0,
              textField.current.value.length
            );
          }, 10);
        }
        if (onFocus) onFocus(e);
      }}
      onKeyPress={(e: any) => {
        if (e.charCode === 13) {
          validate();
          setTimeout(() => {
            textField?.current?.setSelectionRange(
              0,
              textField.current.value.length
            );
          }, 10);
        } else if (
          (e.charCode < 48 || e.charCode > 57) &&
          e.charCode !== 45 &&
          e.charCode !== 46
        ) {
          // digit and minus sign
          e.preventDefault();
        }

        if (onKeyPress) onKeyPress(e);
      }}
      onBlur={(e: any) => {
        setActive(false);
        if (validateOnBlur) {
          if (
            shouldValidateOnBlur.current &&
            !validate() &&
            retainFocusOnError
          ) {
            e.preventDefault();
            setTimeout(() => {
              textField?.current?.setSelectionRange(
                0,
                textField.current.value.length
              );
              textField?.current?.focus();
            }, 10);
          }
        }
        shouldValidateOnBlur.current = true;
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={(e: any) => {
        let v = e.target.value.toString();
        const n = Number.parseFloat(v);
        if (isNaN(n)) {
          setDraft("");
        } else {
          setDraft(v);
        }
      }}
      onKeyDown={(e: any) => {
        switch (e.keyCode) {
          case 38: // up
            e.preventDefault();
            handleUpDownKey(true);
            break;
          case 40: // down
            e.preventDefault();
            handleUpDownKey(false);
            break;
          case 27: // escape key
            shouldValidateOnBlur.current = false;
            textField?.current?.blur();
            setDraft(value === 0 || value ? value.toString() : "");
            if (onValidating) {
              const error = onValidating(value);
              setValidationError(error);
            }
            break;
          default:
            break;
        }
        if (onKeyDown) onKeyDown(e);
      }}
    />
  );
}

NumberEditor.defaultProps = {
  numberPrecision: 2,
  retainFocusOnError: true,
  selectAllOnFocus: true,
  inputElementType: "TextField",
  validateOnBlur: true,
  showErrorAsHelperText: true,
};

export default NumberEditor;

import * as React from "react";
import TextEditor from "../components/TextEditor";
import { checkRegionNameExistsQuery } from "./graphql";
import Grid from "@mui/material/Grid/Grid";
import NumberEditor from "../components/NumberEditor";
import { CheckRegionNameExists, CheckRegionNameExistsVariables } from "./__generated__/CheckRegionNameExists";
import { CityFindAll_cityQuery_cities_edges_region } from "./__generated__/CityFindAll";
import { CheckRegionAliasExists, CheckRegionAliasExistsVariables } from "./__generated__/CheckRegionAliasExists";
import { useLazyQuery } from "@apollo/client";

export type RegionEditControlProps = {
  value: CityFindAll_cityQuery_cities_edges_region;
  setValue: (value: CityFindAll_cityQuery_cities_edges_region) => void;
  error: { [key: string]: string };
  setError: (error: { [key: string]: string }) => void;
};
const _sx={
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
};
function RegionEditControl({
  value,
  setValue,
  error,
  setError,
}: RegionEditControlProps) {
  const [checkRegionName] = useLazyQuery<
    CheckRegionNameExists,
    CheckRegionNameExistsVariables
  >(checkRegionNameExistsQuery, {
    onCompleted: (data) => {
      if (data?.regionQuery?.nameExists) {
        setError({ ...error, name: "Region name already exists!" });
      } else {
        setError({ ...error, name: "" });
      }
    },
    fetchPolicy: "network-only",
  });
  const [checkRegionAlias] = useLazyQuery<
    CheckRegionAliasExists,
    CheckRegionAliasExistsVariables
  >(checkRegionNameExistsQuery, {
    onCompleted: (data) => {
      if (data?.regionQuery?.aliasExists) {
        setError({ ...error, alias: "Region alias already exists!" });
      } else {
        setError({ ...error, alias: "" });
      }
    },
    fetchPolicy: "network-only",
  });
  return (
    <Grid container direction="column">
      <TextEditor
        sx={_sx.formControl}
        label="Name"
        value={value.name}
        error={!!error.name}
        helperText={error.name}
        onValidated={(v) => {
          setValue({
            ...value,
            name: v,
          });
          setError({ ...error, name: "" });
          if (v) checkRegionName({ variables: { id: value.id, checkFor: v } });
        }}
      />
      <TextEditor
        sx={_sx.formControl}
        label="Alias"
        value={value.alias??undefined}
        error={!!error.alias}
        helperText={error.alias}
        onValidated={(v) => {
          setValue({
            ...value,
            alias: v,
          });
          setError({ ...error, alias: "" });
          if (v) checkRegionAlias({ variables: { id: value.id, checkFor: v } });
        }}
      />

      <NumberEditor
        sx={_sx.formControl}
        numberPrecision={0}
        label="Sort order"
        value={value.sortOrder??undefined}
        onValidated={async (v) => {
          setValue({ ...value, sortOrder: v??null });
        }}
      />
    </Grid>
  );
}

export default RegionEditControl;

import * as React from "react";
import ApolloVirtualizedTable, {
    GridColumn,
    ApolloListResult,
    DefaultPageInfoValue,
    ListItemRenderProps,
    ApolloVirtualizedTableProps,
  } from "mui-apollo-virtualized-table";
import { userRoleOptions } from "./userRoleOptions";
import config from "../../config.json";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import { userFindAllQuery } from "./graphql";
import Skeleton from '@mui/material/Skeleton';
import format from "date-fns/format";
import { UserFindAll, UserFindAllVariables, UserFindAll_userQuery_users_edges } from "./__generated__/UserFindAll";
import { ApolloConsumer } from "@apollo/client/react";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
export type UserGridProps = {
    fetchAllVariables: UserFindAllVariables,
    onVariableChange: (variables:UserFindAllVariables)=>void;
    setSelectedItem : (user:UserFindAll_userQuery_users_edges)=>void;
};

const UserVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<UserFindAll_userQuery_users_edges>>;

function UserGrid({fetchAllVariables= { pagination: { pageSize: 20 } }, onVariableChange,setSelectedItem}: UserGridProps){
    const [columns,setColumns] = React.useState<ReadonlyArray<GridColumn<UserFindAll_userQuery_users_edges>>>([
        {
            label:"User Name",
            key:"name",
            flexGrow:1,
            width:150,
            sortable:true
        },
        {
            label:"Full Name",
            key:"fullName",
            flexGrow:1,
            width:150,
            sortable:true
        },
        {
            label:"User Role",
            key:"userRole",
            width:150,
            sortable:true,
            format:({rowData})=> userRoleOptions[rowData.userRole].text
        },
        {
            label:"Status",
            key:"active",
            width:100,
            sortable:true,
            format:({rowData})=> rowData.active? <Check color="secondary"/> : <Close color="disabled"/>
        },
        {
            label:"Created At",
            width:200,
            sortable:true,
            key:"createdAt",
            format:({rowData})=> format(new Date(rowData.createdAt), config.longDateFormat)
        }
    ]);

    const listItemRenderer = React.useCallback(
        (renderProps: ListItemRenderProps<UserFindAll_userQuery_users_edges>) => {
          const { rowData, key, style, className, onClick } = renderProps;
          if (rowData) {
            const { userRole, name } = rowData;
            return (
              <div onClick={onClick} style={style} className={className} key={key}>
                <ListItem>
                  <ListItemText primary={name} secondary={userRoleOptions[userRole].text} />
                </ListItem>
                <Divider />
              </div>
            );
          } else {
            return (
              <div style={style} key={key}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Skeleton/>
                    }
                    secondary={
                      <Skeleton/>
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            );
          }
        },
        []
      );

    const [selectedIndex, setSelectedIndex] = React.useState(-1);


    return (
        <ApolloConsumer>
          {(client) => (
            <UserVirtualizedGrid
              onRowClick={(item,index)=>{
                setSelectedItem(item);
                setSelectedIndex(index);
              }}
              selectedItems = {[selectedIndex]}
              apolloClient={client}
              listModeBreakPoint={0}
              onColumnPropsChanged={(columns, orderBy: any) => {
                const newVariables: UserFindAllVariables = {
                  ...fetchAllVariables,
                  orderBy,
                };
                setColumns(columns);
                onVariableChange(newVariables);
              }}
              columns={columns}
              graphqlQuery={userFindAllQuery}
              variables={fetchAllVariables}
              pageSize={fetchAllVariables?.pagination?.pageSize!}
              listItemRenderer={listItemRenderer}
              parseListFromQueryResult={(queryResult: UserFindAll) => {
                const list: ApolloListResult<UserFindAll_userQuery_users_edges> = queryResult?.userQuery?.users?? {
                      edges: [],
                      pageInfo: DefaultPageInfoValue,
                    };
                return list;                
              }}
              onLoadMore={(pageInfo) => {
                return {
                 ...fetchAllVariables,
                  pagination: {
                    page: pageInfo.page,
                    pageSize: pageInfo.pageSize,
                  },
                };
              }}
            />
          )}
        </ApolloConsumer>
      );
}

export default UserGrid;
import AppBar from "@mui/material/AppBar/AppBar";
import Grid from "@mui/material/Grid/Grid";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import CsSearchBox, { OperandType } from "../components/CsSearchBox";
import DrawerToggleButton from "../app/DrawerToggleButton";
import update from "immutability-helper";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box/Box";
import ConfirmDialog from "../components/ConfirmDialog";
import ErrorMessageDialog from "../components/ErrorMessageDialog";
import RegionCreateDialog from "./RegionCreateDialog";
import RegionUpdateDialog from "./RegionUpdateDialog";
import RegionGrid from "./RegionGrid";
import { deleteRegionMutation, regionFindAllQuery } from "./graphql";
import { RegionFindAll, RegionFindAllVariables } from "./__generated__/RegionFindAll";
import { DeleteRegion, DeleteRegionVariables } from "./__generated__/DeleteRegion";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
const _sx = {
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
};

function RegionBrowser() {
  const [variables, setVariables] = React.useState<RegionFindAllVariables>(
    {
      pagination: {
        pageSize: 80,
      },
    }
  );
  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );
  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");
  const { id: regionId } = useParams();
  const [deleteRegion] = useMutation<
    DeleteRegion,
    DeleteRegionVariables
  >(deleteRegionMutation, {
    update: (cache, { data }) => {
      if (data?.regionMutation?.deleteRegion) {
        const old = cache.readQuery<
          RegionFindAll,
          RegionFindAllVariables
        >({ query: regionFindAllQuery, variables });
        if (!old)
          return;
        const index = old?.regionQuery?.regions?.edges?.findIndex(
          (region) => region.id === regionId
        ) ?? -1;
        if (index > -1)
          cache.writeQuery<RegionFindAll, RegionFindAllVariables>({
            query: regionFindAllQuery,
            variables: variables,
            data: update(old, {
              regionQuery: {
                regions: {
                  edges: {
                    $splice: [[index, 1]],
                  },
                  pageInfo: {
                    rowCount: {
                      $set: old?.regionQuery?.regions?.pageInfo?.rowCount ?? 1 - 1,
                    },
                  },
                },
              },
            }),
          });
      }
    },
  });
  const navigate = useNavigate();
  return (
    <>
      <Grid container direction="column">
        <AppBar position="static" >
          <Toolbar sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minHeight: 48,
            },
            paddingLeft: 12,
          })}>
            <DrawerToggleButton />
            <Typography
              variant="h6"
              sx={_sx.title}
              color="inherit"
              noWrap
            >
              Regions
            </Typography>
            <CsSearchBox
              onConditionChanged={(conditions: any) => {
                setVariables(
                  update(variables, {
                    where: {
                      $set: { aND: conditions },
                    },
                  })
                );
              }}
              popperSx={theme => ({
                marginTop: 2,
                [theme.breakpoints.up("md")]: {
                  marginTop: 1
                },
              })}
              operands={{
                "alias": {
                  name: "Alias",
                  propName: "alias",
                  type: OperandType.STRING,
                },
                "name": {
                  name: "Name",
                  propName: "name",
                  type: OperandType.STRING,
                },
              }}
            />
            <Tooltip title="Edit region info.">
              <IconButton
                color="inherit"
                onClick={() => {
                  setUpdateDialogOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete region.">
              <IconButton
                color="inherit"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="New Region">
              <IconButton
                color="inherit"
                onClick={() => {
                  setCreateDialogOpen(true);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box
          sx={_sx.box}
          flex={1}
          display="flex"
          flexDirection="row"
        >
          <RegionGrid
            fetchAllVariables={variables}
            onVariableChange={setVariables}
          />
        </Box>
      </Grid>
      <RegionCreateDialog
        fetchAllVariables={variables}
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
        }}
        onError={(error) => {
          setOperationError(error);
          setOperationErrorMessage("Could not create region.");
          setErrorMessageDialogOpen(true);
        }}
      />
      {
        regionId ? <RegionUpdateDialog
          open={updateDialogOpen}
          onClose={() => {
            setUpdateDialogOpen(false);
          }}
          onError={(error) => {
            setOperationError(error);
            setOperationErrorMessage("Could not update region info.");
            setErrorMessageDialogOpen(true);
          }}
          id={regionId!}
        /> : null
      }
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          title={`Error`}
          message={operationErrorMessage}
          detail={operationError?.message??""}
        />
      ) : null}

      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Region"
        message={`Are you sure to delete Region?`}
        rejectLabel="No"
        acceptLabel="Yes"
        onRejected={() => {
          setDeleteDialogOpen(false);
        }}
        onAccepted={async () => {
          if (!regionId)
            return;
          await deleteRegion({
            variables: { id: regionId },
          });
          setDeleteDialogOpen(false);
          navigate("/regions", { replace: true });
        }}
      />
    </>
  );
}
export default RegionBrowser;

import * as React from "react";
import ApolloVirtualizedTable, {
  GridColumn,
  ApolloListResult,
  DefaultPageInfoValue,
  ListItemRenderProps,
  ApolloVirtualizedTableProps,
} from "mui-apollo-virtualized-table";
import { regionFindAllQuery } from "./graphql";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import Avatar from "@mui/material/Avatar/Avatar";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import { RegionFindAll, RegionFindAllVariables, RegionFindAll_regionQuery_regions_edges } from "./__generated__/RegionFindAll";
import { ApolloConsumer } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const RegionVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<RegionFindAll_regionQuery_regions_edges>>;
const Span = styled("span")((theme) => ({
  backgroundColor: "#DDDDDD",
  color: "#DDDDDD",
  width: 150,
  display: "inline",
}));
export type RegionGridProps = {
  fetchAllVariables: RegionFindAllVariables;
  onVariableChange: (variables: RegionFindAllVariables) => void;
};

function RegionGrid({ fetchAllVariables = { pagination: { pageSize: 20 } }, onVariableChange }: RegionGridProps) {

  const navigate = useNavigate();
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<RegionFindAll_regionQuery_regions_edges>>
  >([
    {
      label: "Alias",
      key: "alias",
      width: 150,
      sortable: true,
    },
    {
      label: "Name",
      key: "name",
      width: 200,
      sortable: true,
    },
    {
      label: "Sort Order",
      key: "sortOrder",
      flexGrow:1,
      width:200,
      sortable: true,
    }
  ]);

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const listItemRenderer = React.useCallback(
    (renderProps: ListItemRenderProps<RegionFindAll_regionQuery_regions_edges>) => {
      const { rowData, key, style, className, onClick } = renderProps;
      if (rowData) {
        const { alias, name } = rowData;
        return (
          <div onClick={onClick} style={style} className={className} key={key}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>{name[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} secondary={alias} />
            </ListItem>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>{ }</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Span >
                    A...........................
                  </Span>
                }
                secondary={
                  <Span >A..........</Span>
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    []
  );

  return (
    <ApolloConsumer>
      {(client) => (
        <RegionVirtualizedGrid
          onRowClick={(item, index) => {
            setSelectedIndex(index);
            navigate(`/regions/${item.id}`, { replace: true });
          }}
          selectedItems={selectedIndex === -1? []:[selectedIndex]}
          apolloClient={client}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={320}
          onColumnPropsChanged={(columns, orderBy: any) => {
            const newVariables: RegionFindAllVariables = {
              ...fetchAllVariables,
              orderBy,
            };
            setColumns(columns);
            onVariableChange(newVariables);
          }}
          columns={columns}
          graphqlQuery={regionFindAllQuery}
          variables={fetchAllVariables}
          pageSize={fetchAllVariables?.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: RegionFindAll) => {
            const list: ApolloListResult<RegionFindAll_regionQuery_regions_edges> =
              queryResult?.regionQuery?.regions ?? {
                edges: [],
                pageInfo: DefaultPageInfoValue,
              };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...fetchAllVariables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default RegionGrid;

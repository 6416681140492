import { PricingMethod } from "../../types/global-types";

const pricingMethodOptions: {[ key: string] : PricingMethodOptionType} = {
    [PricingMethod.NFA]:{value:PricingMethod.NFA, text:"NFA"},
    [PricingMethod.GFA]: { value: PricingMethod.GFA, text: "GFA" }
};

const pricingMethodOptionList = Object.keys(pricingMethodOptions).map(e => pricingMethodOptions[e]);



export type PricingMethodOptionType = {
    value: PricingMethod,
    text: string
};

export  { pricingMethodOptions ,  pricingMethodOptionList  };
import * as React from "react";
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Typography from "@mui/material/Typography/Typography";

export type DialogActionLoadingIndicatorProps = {
  text: string;
  loading: boolean;
};

const _sx={
  loadingIndicator: {
    paddingLeft: 2,
    paddingRight: 2,
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  loadingText: {
    marginLeft: 2,
  },
};
function DialogActionLoadingIndicator({
  loading,
  text,
}: DialogActionLoadingIndicatorProps) {
  if (loading)
    return (
      <Box sx={_sx.loadingIndicator}>
        <CircularProgress size={32} />
        <Typography sx={_sx.loadingText} variant="body1">
          {text}
        </Typography>
      </Box>
    );
  else return null;
}

export default DialogActionLoadingIndicator;

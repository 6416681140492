import { PropertyStatus } from "../../types/global-types";
const propertyStatusOptions: {[ key: string] : PropertyStatusOptionType} = {
    [PropertyStatus.RENT]: { value: PropertyStatus.RENT, text: "Rent" },
    [PropertyStatus.SELL]:{value:PropertyStatus.SELL, text:"Sell"},
    [PropertyStatus.PROJECT]: { value: PropertyStatus.PROJECT, text: "Project"}
};

const propertyStatusOptionList = Object.keys(propertyStatusOptions).map(e => propertyStatusOptions[e]);



export type PropertyStatusOptionType = {
    value: PropertyStatus,
    text: string
};

export  { propertyStatusOptions ,  propertyStatusOptionList };
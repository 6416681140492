import * as React from "react";
import Downshift, { GetItemPropsOptions } from "downshift";
import { regionFindAllQuery } from "./graphql";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Close from "@mui/icons-material/Close";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem/MenuItem";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper/Paper";
import { CityFindAll_cityQuery_cities_edges_region } from "./__generated__/CityFindAll";
import { useQuery } from "@apollo/client";
import { RegionFindAll, RegionFindAllVariables, RegionFindAll_regionQuery_regions_edges } from "./__generated__/RegionFindAll";
import { RegionOrderBy } from "../types/global-types";

type PopupMenuProps = {
  menuProps: any;
  getItemProps: (options: GetItemPropsOptions<CityFindAll_cityQuery_cities_edges_region>) => any;
  selectedItem: CityFindAll_cityQuery_cities_edges_region | null;
  inputValue?: string;
  highlightedIndex: number | null;
}
function PopupMenu({ menuProps: downshiftProps, selectedItem, inputValue, highlightedIndex, getItemProps }: PopupMenuProps) {
  const { data } = useQuery<RegionFindAll, RegionFindAllVariables>(regionFindAllQuery, {
    variables: {
      where: {
        name_Contains:
          selectedItem && selectedItem.name === inputValue
            ? ""
            : inputValue,
      },
      pagination: {
        take: 20,
      },
      orderBy: [RegionOrderBy.name],
    }
  });
  return (<Paper {...downshiftProps} sx={{
    zIndex: 2,
    maxHeight: 300,
    overflowY: "scroll",
    position: "absolute",
    left: 0,
    right: 0,
  }} square>
    {
      data?.regionQuery?.regions?.edges?.map(
        (suggestion: any, index: any) =>
          renderSuggestion({
            suggestion,
            index,
            itemProps: getItemProps({ item: suggestion }),
            highlightedIndex,
            selectedItem,
          })
      )
    }
  </Paper>);
}


type RenderInputProps = TextFieldProps & {
  ref?: React.Ref<HTMLDivElement>;
  clearSelection?: () => void;
  openMenu?: () => void;
};

function renderInput(inputProps: RenderInputProps) {
  const {
    InputProps,
    classes,
    clearSelection,
    ref,
    openMenu,
    ...other
  } = inputProps;

  return (
    <TextField
      onChange={(e) => {
        if (e.target.value === "" && clearSelection) clearSelection();
      }}
      InputProps={{
        inputRef: ref,
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={clearSelection}>
              <Close fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={openMenu}>
              <ArrowDropDown />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}

interface RenderSuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<"div", { button?: never }>;
  selectedItem: RegionFindAll_regionQuery_regions_edges | null;
  suggestion: RegionFindAll_regionQuery_regions_edges;
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = suggestion === selectedItem;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 700 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  );
}



type RegionAutoCompleteProps = TextFieldProps & {
  onSelectedItemChange: (oldItem: CityFindAll_cityQuery_cities_edges_region | null) => void;
  selectedItem: CityFindAll_cityQuery_cities_edges_region | null;
  className?: string;
};

function RegionAutoComplete(props: RegionAutoCompleteProps) {
  const {
    onSelectedItemChange,
    selectedItem,
    className,
    classes: textFieldclasses,
    sx,
    ...textFieldProps
  } = props;
  return (
    <Downshift
      onSelect={onSelectedItemChange}
      selectedItem={selectedItem}
      itemToString={(item) => (item ? item.name : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        clearSelection,
        getRootProps,
        openMenu,
      }) => {
        const { onBlur, ...inputProps } = getInputProps({});

        return (
          <Box {...getRootProps()} position="relative" sx={sx}>
            {renderInput({
              fullWidth: true,
              label: "Region",
              InputLabelProps: getLabelProps(),
              InputProps: {
                onBlur,
                onFocus: () => {
                  openMenu();
                },
              },
              inputProps,
              clearSelection,
              openMenu,
              ...textFieldProps,
            })}
            {isOpen ? (
              <PopupMenu getItemProps={getItemProps} menuProps={getMenuProps()} selectedItem={selectedItem} inputValue={inputValue ?? ""} highlightedIndex={highlightedIndex} />
            ) : null}
          </Box>
        );
      }}
    </Downshift>
  );
}

export default RegionAutoComplete;

import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Grid from "@mui/material/Grid/Grid";
import * as React from "react";
import {
  checkPropertyCodeExistsQuery,
  duplicatePropertyMutation,
  propertyFindAllQuery,
} from "./graphql";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import update from "immutability-helper";
import { Typography } from "@mui/material";
import { PropertyFindAll, PropertyFindAllVariables } from "./__generated__/PropertyFindAll";
import { DuplicateProperty, DuplicatePropertyVariables } from "./__generated__/DuplicateProperty";
import { CheckPropertyCodeExists, CheckPropertyCodeExistsVariables } from "./__generated__/CheckPropertyCodeExists";
import { useLazyQuery, useMutation } from "@apollo/client/react/hooks";
import TextEditor from "../../components/TextEditor";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
type DuplicatePropertyDialogProps = {
  open: boolean;
  sourceId: string;
  onClose: (newId?: string) => void;
  fetchAllVariables: PropertyFindAllVariables;
};

const _sx={
  formControl: {
    marginTop: 0.5,
    marginBottom: 0.5,
  },
  errorMessageContainer: {
    paddingLeft: 2,
    paddingRight: 2,
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
};

function DuplicatePropertyDialog({
  open,
  sourceId,
  onClose,
  fetchAllVariables,
}: DuplicatePropertyDialogProps) {
  const [propertyCode, setPropertyCode] = React.useState("");
  const [includePhoto, setIncludePhoto] = React.useState(true);
  const [
    checkPropertyCode,
    { data: checkPropertyCodeResult, loading: checkingPropertyCode },
  ] = useLazyQuery<
    CheckPropertyCodeExists,
    CheckPropertyCodeExistsVariables
  >(checkPropertyCodeExistsQuery, {
    fetchPolicy: "network-only",
  });
  const propertyCodeError = React.useMemo(() => {
    if (checkingPropertyCode) return "";
    else if (checkPropertyCodeResult?.propertyQuery?.propertyCodeExists) {
      return "Property code already exits";
    } else {
      return "";
    }
  }, [checkPropertyCodeResult, checkingPropertyCode]);
  const [
    duplicateProperty,
    { loading: duplicating, error: duplicateError },
  ] = useMutation<
    DuplicateProperty,
    DuplicatePropertyVariables
  >(duplicatePropertyMutation, {
    update: (cache, { data }) => {
      if (data?.propertyMutation?.duplicateProperty) {
        const old = cache.readQuery<
          PropertyFindAll,
          PropertyFindAllVariables
        >({ query: propertyFindAllQuery, variables: fetchAllVariables });
        cache.writeQuery<PropertyFindAll, PropertyFindAllVariables>({
          query: propertyFindAllQuery,
          variables: fetchAllVariables,
          data: update(old!, {
            propertyQuery: {
              properties: {
                edges: {
                  $push: [data.propertyMutation.duplicateProperty],
                },
                pageInfo: {
                  rowCount: {
                    $set: old?.propertyQuery?.properties?.pageInfo?.rowCount??0 + 1,
                  },
                },
              },
            },
          }),
        });
      }
    },
  });
  const submit = React.useCallback(async () => {
    const { errors, data } = await duplicateProperty({
      variables: {
        id: sourceId,
        includePhoto,
        propertyCode,
      },
    });
    if (!errors && data) {
      onClose(data?.propertyMutation?.duplicateProperty?.id!);
    }
  }, [duplicateProperty, sourceId, includePhoto, propertyCode, onClose]);
  const onCancel = React.useCallback(() => {
    onClose();
  }, [onClose]);
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Duplicate Property</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <TextEditor
            sx={_sx.formControl}
            label="Property Code"
            value={propertyCode}
            error={!!propertyCodeError}
            helperText={propertyCodeError}
            onValidated={(v) => {
              setPropertyCode(v);
              if (v) checkPropertyCode({ variables: { checkFor: v } });
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
              sx={_sx.formControl}
                checked={includePhoto}
                onChange={(e) => {
                  setIncludePhoto(e.target.checked);
                }}
              />
            }
            label="Include photo"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={duplicating}
          text="Duplicating new property..."
        />
        {duplicateError ? (
          <Box sx={_sx.errorMessageContainer}>
            <Typography color="error">Could not duplicate property.</Typography>
          </Box>
        ) : null}
        <Button disabled={duplicating} onClick={submit} color="secondary">
          OK
        </Button>
        <Button disabled={duplicating} onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DuplicatePropertyDialog;

import * as React from "react";
import update from "immutability-helper";
import Box from '@mui/material/Box';
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Chip from "@mui/material/Chip/Chip";
import Popper from "@mui/material/Popper/Popper";
import Paper from "@mui/material/Paper/Paper";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import List from "@mui/material/List/List";
import { SxProps } from '@mui/system';
export type EnumSelectItem = {
  value: any;
  text: string;
};

export type EnumSelectOptions = {[ key: string] : EnumSelectItem};

export type EnumSelectEditorProps = {
  value: ReadonlyArray<any>;
  options: EnumSelectOptions;
  onChange: (values: ReadonlyArray<any>) => void;
  className?:string;
  sx?:SxProps;
};

const _sx={
  root: {
    borderColor: "divider",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 0.5,
    minWidth: 200,
    minHeight:56
  },
  chip: {
    margin: 0.5,
  },
  rootList: {
    maxWidth: 400,
    minWidth: 320,
    maxHeight: 400,
    overflowY: "scroll",
  },
};

type EnumListItemProps = {
  item: EnumSelectItem;
  selectedItems: EnumSelectItem[];
  onChange: (selectedItems: EnumSelectItem[]) => void;
};
function EnumListItem({ item, selectedItems, onChange }: EnumListItemProps) {
  const selected = React.useMemo(() => {
    return selectedItems.indexOf(item) > -1;
  }, [item, selectedItems]);
  return (
    <ListItem
      selected={selected}
      dense
      button
      onClick={() => {
        if (!selected) {
          onChange([...selectedItems, item]);
        } else {
          const index = selectedItems.indexOf(item);
          onChange(
            update(selectedItems, {
              $splice: [[index, 1]],
            })
          );
        }
      }}
    >
      <ListItemIcon>
        <Checkbox checked={selected} />
      </ListItemIcon>
      <ListItemText>{item.text}</ListItemText>
    </ListItem>
  );
}

function EnumMultiSelectEditor({
  options,
  value,
  sx,
  onChange,
}: EnumSelectEditorProps) {
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLDivElement>(null);
  const data = React.useMemo(()=>{
      return Object.keys(options).map(e => options[e]);
  }, [options]);
  const selectedItems = React.useMemo(() => {
    const items = value? value.map((v) => options[v]) : [];
    return items;
  }, [value, options]);
  return (
    <Box
      ref={anchorEl}
      sx={{..._sx.root,...sx}}
      onClick={() => {
        setIsPopperOpen(true);
      }}
    >
      {selectedItems.map((v, index) => (
        <Chip
          sx={_sx.chip}
          key={v.value.toString()}
          label={v.text}
          onDelete={() => {
            const index = selectedItems.indexOf(v);
            onChange(
              update(selectedItems, {
                $splice: [[index, 1]],
              }).map(item=>item.value)
            );
          }}
        />
      ))}
      <Popper
      disablePortal
        open={isPopperOpen}
        anchorEl={anchorEl.current}
        placement="bottom-start"
      >
        <Paper elevation={5} >
          <ClickAwayListener
            onClickAway={() => {
              setIsPopperOpen(false);
            }}
          >
            <List dense sx={_sx.rootList}>
              {data.map((v, i) => {
                return (
                  <EnumListItem
                    key={v.value}
                    item={v}
                    selectedItems={selectedItems}
                    onChange={(selectedItems) => {
                      onChange(selectedItems.map((item) => item.value));
                    }}
                  />
                );
              })}
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
}

export default EnumMultiSelectEditor;

import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";

export type ErrorMessageDialogProps = {
    title: string,
    message: string,
    detail: string,
    open:boolean,
    onClose:()=>void
};
function ErrorMessageDialog(props: ErrorMessageDialogProps){
    const {title,message, detail,open,onClose} = props;
    return (
        <Dialog open = {open} onClose={onClose} maxWidth="sm">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                <Typography variant="body1" color="error">{detail}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorMessageDialog;
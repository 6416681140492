import * as React from "react";
import CityEditControl from "./CityEditControl";
import DialogActionLoadingIndicator from "../components/DialogActionLoadingIndicator";
import { getCityByIdQuery, updateCityMutation } from "./graphql";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { CityFindAll_cityQuery_cities_edges } from "./__generated__/CityFindAll";
import { GetCityById, GetCityByIdVariables } from "./__generated__/GetCityById";
import { UpdateCity, UpdateCityVariables } from "./__generated__/UpdateCity";
import { useMutation, useQuery } from "@apollo/client";
export type CityUpdateDialogProps = {
  open: boolean;
  onClose: () => void;
  onError: (error: Error) => void;
  id: string;
};
const cityDefaultValue: CityFindAll_cityQuery_cities_edges = {
  id:null,
  alias:"",
  name:"",
  sortOrder:null,
  region:null
};
function CityUpdateDialog({
  open,
  onClose,
  onError,
  id,
}: CityUpdateDialogProps) {
  const { data: cityQueryResult, loading } = useQuery<
    GetCityById,
    GetCityByIdVariables
  >(getCityByIdQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "network-only",
  });
  const [draft, setDraft] = React.useState<CityFindAll_cityQuery_cities_edges>(cityDefaultValue);
  React.useEffect(() => {
    if (
      cityQueryResult &&
      cityQueryResult.cityQuery &&
      cityQueryResult.cityQuery.city
    ) {
      setDraft({
        ...cityQueryResult.cityQuery.city,
      });
      setError({});
    }
  }, [cityQueryResult]);

  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [
    createCity,
    { loading: updatingCity, error: createError },
  ] = useMutation<UpdateCity, UpdateCityVariables>(
    updateCityMutation
  );

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.alias) {
      e.alias = "";
    } else {
      valid = false;
      e.alias = "Alias is required";
    }
    if (draft.name) {
      e.name = "";
    } else {
      valid = false;
      e.name = "Name is required";
    }

    if(draft.region?.id){
        e.region= "";
    }else{
        valid = false;
        e.region = "Select region.";
    }

    setError(e);
    return valid;
  }, [draft.alias, draft.name, draft.region?.id, error]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const {
          id,
        name,
        alias,
        sortOrder,
        region
      } = draft;
      await createCity({
        variables: {
          city: {
            id,
            name,
            alias,
            sortOrder,
            regionId:region?.id!
          },
        },
      });
      onClose();
    }
  }, [createCity, draft, onClose, validate]);

  React.useEffect(() => {
    if (createError) onError(createError);
  }, [createError, onError]);
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Update City Info</DialogTitle>
      <DialogContent>
        <CityEditControl
          value={draft}
          setValue={setDraft}
          error={error}
          setError={setError}
        />
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={updatingCity || loading}
          text={loading? "Loading city info..." : "Updating city info..."}
        />
        <Button disabled={updatingCity || loading} onClick={submit} color="secondary">
          OK
        </Button>
        <Button disabled={updatingCity || loading} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CityUpdateDialog;

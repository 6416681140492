import * as React from "react";
import ApolloVirtualizedTable, {
  GridColumn,
  ApolloListResult,
  DefaultPageInfoValue,
  ListItemRenderProps,
  ApolloVirtualizedTableProps,
} from "mui-apollo-virtualized-table";
import { ownerTypeOptions } from "./ownerTypeOptions";
import { ownerFindAllQuery } from "./graphql";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import Avatar from "@mui/material/Avatar/Avatar";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import { OwnerFindAll, OwnerFindAllVariables, OwnerFindAll_ownerQuery_owners_edges } from "./__generated__/OwnerFindAll";
import { ApolloConsumer } from "@apollo/client/react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

const OwnerVirtualizedGrid = ApolloVirtualizedTable as React.ElementType<ApolloVirtualizedTableProps<OwnerFindAll_ownerQuery_owners_edges>>;
export type OwnerGridProps = {
  fetchAllVariables: OwnerFindAllVariables;
  onVariableChange: (variables: OwnerFindAllVariables) => void;
};

const _sx = {
  loadingIndicator: {
    backgroundColor: "#DDDDDD",
    color: "#DDDDDD",
    width: 150,
    display: "inline-block",
  },
};

function OwnerGrid({ fetchAllVariables = { pagination: { pageSize: 20 } }, onVariableChange }: OwnerGridProps) {
  const [columns, setColumns] = React.useState<
    ReadonlyArray<GridColumn<OwnerFindAll_ownerQuery_owners_edges>>
  >([
    {
      label: "Code",
      key: "code",
      width: 150,
      sortable: true,
    },
    {
      label: "Name",
      key: "name",
      width: 200,
      sortable: true,
    },
    {
      label: "Owner Type",
      key: "ownerType",
      width: 150,
      sortable: true,
      format: ({ rowData }) => ownerTypeOptions[rowData.ownerType].text,
    },
    {
      label: "Contact",
      key: "contactPerson",
      width: 200,
      sortable: true,
    },
    {
      label: "Phone No",
      key: "contactNumber",
      width: 150,
      sortable: true,
    },
    {
      label: "Address",
      width: 200,
      sortable: true,
      flexGrow: 1,
      key: "address",
    },
  ]);

  const listItemRenderer = React.useCallback(
    (renderProps: ListItemRenderProps<OwnerFindAll_ownerQuery_owners_edges>) => {
      const { rowData, key, style, className, onClick } = renderProps;
      if (rowData) {
        const { code, name, profilePhoto } = rowData;
        return (
          <div onClick={onClick} style={style} className={className} key={key}>
            <ListItem>
              <ListItemAvatar>
                {profilePhoto ? (
                  <Avatar src={profilePhoto} />
                ) : (
                  <Avatar>{name[0]}</Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={name} secondary={code} />
            </ListItem>
            <Divider />
          </div>
        );
      } else {
        return (
          <div style={style} key={key}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>{ }</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Skeleton sx={_sx.loadingIndicator} />
                }
                secondary={
                  <Skeleton sx={_sx.loadingIndicator} />
                }
              />
            </ListItem>
            <Divider />
          </div>
        );
      }
    },
    []
  );
  const navigate = useNavigate();

  return (
    <ApolloConsumer>
      {(client) => (
        <OwnerVirtualizedGrid
          onRowClick={(item, index) => {
            navigate(`/owners/${item.id}`);
          }}
          apolloClient={client}
          listItemHeight={72}
          listItemRenderer={listItemRenderer}
          listModeBreakPoint={320}
          onColumnPropsChanged={(columns, orderBy: any) => {
            const newVariables: OwnerFindAllVariables = {
              ...fetchAllVariables,
              orderBy,
            };
            setColumns(columns);
            onVariableChange(newVariables);
          }}
          columns={columns}
          graphqlQuery={ownerFindAllQuery}
          variables={fetchAllVariables}
          pageSize={fetchAllVariables?.pagination?.pageSize!}
          parseListFromQueryResult={(queryResult: OwnerFindAll) => {
            const list: ApolloListResult<OwnerFindAll_ownerQuery_owners_edges> = queryResult?.ownerQuery?.owners ?? {
              edges: [],
              pageInfo: DefaultPageInfoValue,
            };
            return list;
          }}
          onLoadMore={(pageInfo) => {
            return {
              ...fetchAllVariables,
              pagination: {
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
              },
            };
          }}
        />
      )}
    </ApolloConsumer>
  );
}

export default OwnerGrid;

import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import siteReducer from "../features/site/siteSlice";
import {
  loadingBarReducer,
  loadingBarMiddleware,
} from "react-redux-loading-bar";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
} from "redux-persist";
import { createWhitelistFilter } from "redux-persist-transform-filter";

const storage = require("redux-persist/lib/storage");
const autoMergeLevel2 = require("redux-persist/lib/stateReconciler/autoMergeLevel2");
const persistConfig = {
  key: "primary",
  storage: storage.default,
  whitelist: ["site"],
  blacklist: ["loadingBar"],
  transforms: [createWhitelistFilter("site", ["userSession"])],
  stateReconciler: autoMergeLevel2.default,
};

const rootReducers = combineReducers({
  counter: counterReducer,
  site: siteReducer,
  loadingBar: loadingBarReducer,
});

export type RootState = ReturnType<typeof rootReducers>;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      loadingBarMiddleware({
        promiseTypeSuffixes: ["pending", "fulfilled", "rejected"],
      })
    ),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

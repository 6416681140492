import * as React from "react";
import OwnerEditControl from "./OwnerEditControl";
import { createOwnerMutation, ownerFindAllQuery } from "./graphql";
import update from "immutability-helper";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { OwnerFindAll, OwnerFindAllVariables } from "./__generated__/OwnerFindAll";
import { GetOwnerById_ownerQuery_owner } from "./__generated__/GetOwnerById";
import { CreateOwner, CreateOwnerVariables } from "./__generated__/CreateOwner";
import { OwnerType } from "../../types/global-types";
import { useMutation } from "@apollo/client/react";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
export type OwnerCreateDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchAllVariables: OwnerFindAllVariables;
  onError: (error: Error) => void;
};
const ownerDefaultValue: GetOwnerById_ownerQuery_owner = {
  code: "",
  name: "",
  ownerType: OwnerType.INDIVIDUAL,
  contactPerson: "",
  contactNumber: "",
  profilePhoto: "",
  coverPhoto: "",
  address: "",
  id: null,
  properties: null,
};
function OwnerCreateDialog({
  open,
  onClose,
  fetchAllVariables,
  onError,
}: OwnerCreateDialogProps) {
  const [draft, setDraft] = React.useState<GetOwnerById_ownerQuery_owner>(ownerDefaultValue);

  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [
    createOwner,
    { loading: creatingOwner, error: createError },
  ] = useMutation<CreateOwner, CreateOwnerVariables>(
    createOwnerMutation,
    {
      update: (cache, { data }) => {
        if (data?.ownerMutation?.createOwner) {
          const old = cache.readQuery<
            OwnerFindAll,
            OwnerFindAllVariables
          >({ query: ownerFindAllQuery, variables: fetchAllVariables });
          if(!old)
            return;
          cache.writeQuery<OwnerFindAll, OwnerFindAllVariables>({
            query: ownerFindAllQuery,
            variables: fetchAllVariables,
            data: update(old!, {
              ownerQuery: {
                owners: {
                  edges: {
                    $push: [data.ownerMutation.createOwner],
                  },
                  pageInfo: {
                    rowCount: {
                      $set: old?.ownerQuery?.owners?.pageInfo?.rowCount??0 + 1,
                    },
                  },
                },
              },
            }),
          });
        }
      },
    }
  );

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.code) {
      e.code = "";
    } else {
      valid = false;
      e.code = "Owner code is required";
    }
    if (draft.name) {
      e.name = "";
    } else {
      valid = false;
      e.name = "Name is required";
    }

    setError(e);
    return valid;
  }, [draft]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const {
        name,
        code,
        ownerType,
        contactPerson,
        contactNumber,
        address,
        profilePhoto,
        coverPhoto,
      } = draft;
      await createOwner({
        variables: {
          owner: {
            id: null,
            code,
            name,
            ownerType,
            contactPerson,
            contactNumber,
            address,
            profilePhoto,
            coverPhoto,
          },
        },
      });
      setDraft(ownerDefaultValue);
      onClose();
    }
  }, [createOwner, draft, onClose, validate]);

  React.useEffect(() => {
    if (createError) onError(createError);
  }, [createError, onError]);
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Create New Owner</DialogTitle>
      <DialogContent>
        <OwnerEditControl
          value={draft}
          setValue={setDraft}
          error={error}
          setError={setError}
        />
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={creatingOwner}
          text="Creating owner..."
        />
        <Button disabled={creatingOwner} onClick={submit} color="secondary">
          OK
        </Button>
        <Button disabled={creatingOwner} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OwnerCreateDialog;

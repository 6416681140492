import * as React from "react";
import PropertyEditControl, { PropertyEdit } from "./PropertyEditControl";
import { AutoSizer, AutoSizerProps } from "react-virtualized";
import {
  savePropertyMutation,
  propertyFindAllQuery,
} from "./graphql";
import update from "immutability-helper";
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import { PropertyFindAll, PropertyFindAllVariables } from "./__generated__/PropertyFindAll";
import { SaveProperty, SavePropertyVariables } from "./__generated__/SaveProperty";
import { LandType, PricingMethod, PropertyActiveStatus, PropertyStatus, PropertyType } from "../../types/global-types";
import { useMutation } from "@apollo/client/react";
import ActivityIndicatorDialog from "../../components/ActivityIndicatorDialog";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import { usePropertyOutletContext } from ".";

const AutoSizerFixed = AutoSizer as unknown as React.ElementType<AutoSizerProps>;

const propertyDefaultValue: PropertyEdit = {
  id: "",
  propertyCode: "",
  owner: null,
  landType: LandType.FREEHOLD,
  propertyType: PropertyType.APARTMENT,
  propertyStatus: PropertyStatus.RENT,
  price: null,
  area: null,
  length: null,
  width: null,
  height: null,
  mBRoom: null,
  sBRoom: null,
  floor: null,
  city: null,
  address: "",
  pricingMethod: PricingMethod.GFA,
  elevator: false,
  swimmingPool: false,
  electricAndWaterMeter: false,
  homeLoanAvailable: false,
  propertyPhotos: [],
  activeStatus: PropertyActiveStatus.ACTIVE,
  about: "",
  sortOrder: null,
  createdAt: undefined,
  updatedAt: undefined
};

const _sx = {
  content: {
    paddingTop: 2,
    paddingBottom: 2,
  },
};



function PropertyCreator() {
  const {
    registerSaveEvent,
    fetchAllVariables,
  } = usePropertyOutletContext();
  const [draft, setDraft] = React.useState<
    PropertyEdit
  >(propertyDefaultValue);
  const [error, setError] = React.useState<{ [key: string]: string }>({});

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.propertyCode) {
      e.propertyCode = "";
    } else {
      valid = false;
      e.propertyCode = "Property code is required";
    }
    if (draft.owner) {
      e.owner = "";
    } else {
      valid = false;
      e.owner = "Choose owner.";
    }

    if (draft.city) {
      e.city = "";
    } else {
      valid = false;
      e.city = "Choose city.";
    }

    setError(e);
    return valid;
  }, [draft.city, draft.owner, draft.propertyCode, error]);

  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );

  const [
    createProperty,
    { loading: creatingProperty, error: createError },
  ] = useMutation<SaveProperty, SavePropertyVariables>(
    savePropertyMutation,
    {
      update: (cache, { data }) => {
        if (data?.propertyMutation?.saveProperty) {
          const old = cache.readQuery<
            PropertyFindAll,
            PropertyFindAllVariables
          >({ query: propertyFindAllQuery, variables: fetchAllVariables });
          if (!old)
            return;
          cache.writeQuery<PropertyFindAll, PropertyFindAllVariables>(
            {
              query: propertyFindAllQuery,
              variables: fetchAllVariables,
              data: update(old!, {
                propertyQuery: {
                  properties: {
                    edges: {
                      $push: [data.propertyMutation.saveProperty],
                    },
                    pageInfo: {
                      rowCount: {
                        $set:
                          old?.propertyQuery?.properties?.pageInfo?.rowCount ?? 0 + 1,
                      },
                    },
                  },
                },
              }),
            }
          );
        }
      },
    }
  );

  React.useEffect(() => {
    if (createError) {
      setOperationError(createError);
      setOperationErrorMessage("Could not create property.");
      setErrorMessageDialogOpen(true);
    }
  }, [createError]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const { owner, city, __typename, createdAt, updatedAt, ...propertyInput } = draft;
      const variables: SavePropertyVariables = {
        property: {
          ...propertyInput,
          ownerId: owner?.id!,
          cityId: city?.id!,
        },
      };
      const { data } = await createProperty({
        variables,
      });
      return data?.propertyMutation?.saveProperty ?? null;
    }
    return null;
  }, [createProperty, draft, validate]);

  React.useEffect(() => {
    if (registerSaveEvent)
      registerSaveEvent(submit);
  }, [registerSaveEvent, submit]);
  return (
    <Box flex={1}>
      <AutoSizerFixed disableWidth>
        {({ height }) => (
          <div style={{ height, overflowY: "scroll" }}>
            <Box flex={1} sx={_sx.content}>
              <Container maxWidth="lg">
                <Box
                  flexDirection="row"
                  boxShadow={1}
                  p={2}
                  display="flex"
                  flexWrap="wrap"
                  className="bg"
                >
                  <PropertyEditControl
                    value={draft}
                    setValue={setDraft}
                    error={error}
                    setError={setError}
                  />
                </Box>
              </Container>
            </Box>
            <ActivityIndicatorDialog
              open={creatingProperty}
              message={creatingProperty ? "Creating property" : ""}
            />
            <ErrorMessageDialog
              open={errorMessageDialogOpen}
              onClose={() => {
                setErrorMessageDialogOpen(false);
              }}
              title="Error!"
              message={operationErrorMessage}
              detail={operationError?.message!}
            />
          </div>
        )}
      </AutoSizerFixed>
    </Box>
  );
}

export default PropertyCreator;

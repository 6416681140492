import * as React from "react";
import attrAccept from "attr-accept";
export type FilePickerHiddenProps = {
  multiple: boolean;
  accept: string;
  onFilesAccepted: (files: File[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
};

function FilePickerHidden({
  multiple,
  accept,
  onFilesAccepted,
  inputRef
}: FilePickerHiddenProps) {
  return (
    <input
      type="file"
      ref={inputRef}
      style={{ display: "none" }}
      multiple={multiple}
      accept={accept}
      onChange={(e) => {
        e.preventDefault();
        const fileList = e.target.files;
        const files: File[] = [];
        if (!fileList)
          return;
        for (var f = 0; f < fileList.length; f++) {
          const file = fileList?.item(f);
          if (file && attrAccept(file, accept)) files.push(file);
        }
        onFilesAccepted(files);
      }}
    />
  );
}

export default FilePickerHidden;

import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import * as React from "react";

export type ConfirmDialogProps = {
    title: string,
    message: string,
    acceptLabel: string,
    rejectLabel: string,
    onAccepted:()=>void,
    onRejected:()=>void,
    open: boolean,
};
function ConfirmDialog(props: ConfirmDialogProps){
    const {title,message, acceptLabel,rejectLabel, onAccepted, onRejected ,open} = props;
    return (
        <Dialog fullWidth maxWidth="sm" open = {open} onClose={onRejected}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAccepted} color="secondary">
                    {acceptLabel}
                </Button>
                <Button onClick={onRejected}>
                    {rejectLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
import * as React from "react";
import { userRoleOptions } from "./userRoleOptions";
import {
  checkUserNameExistQuery,
  registerUserMutation,
  userFindAllQuery,
} from "./graphql";
import update from "immutability-helper";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Grid from "@mui/material/Grid/Grid";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { UserFindAll, UserFindAllVariables } from "./__generated__/UserFindAll";
import { CheckUserNameExists, CheckUserNameExistsVariables } from "./__generated__/CheckUserNameExists";
import { RegisterUser, RegisterUserVariables } from "./__generated__/RegisterUser";
import { UserRole } from "../../types/global-types";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import TextEditor from "../../components/TextEditor";
import EnumSingleSelectEditor from "../../components/EnumSingleSelectEditor";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
export type RegisterUserDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchAllVariables: UserFindAllVariables;
};

const _sx = {
  formControl: {
    marginTop: 1,
    marginBottom: 1,
    flex: 1,
  },
};

type RegisterUserType = {
  name: string;
  fullName: string;
  password: string;
  confirmPassword: string;
  userRole: UserRole;
};

function RegisterUserDialog({
  open,
  onClose,
  fetchAllVariables,
}: RegisterUserDialogProps) {
  const [draft, setDraft] = React.useState<RegisterUserType>({
    name: "",
    fullName: "",
    password: "",
    confirmPassword: "",
    userRole: UserRole.USER,
  });
  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [checkUserName] = useLazyQuery<
    CheckUserNameExists,
    CheckUserNameExistsVariables
  >(checkUserNameExistQuery, {
    onCompleted: (data) => {
      if (data?.userQuery?.userNameExists) {
        setError({ ...error, name: "User name already exists!" });
      } else {
        setError({ ...error, name: "" });
      }
    },
    fetchPolicy: "network-only",
  });
  const [
    registerUser,
    { loading: registeringUser, error: registerError },
  ] = useMutation<RegisterUser, RegisterUserVariables>(
    registerUserMutation,
    {
      update: (cache, { data }) => {
        if (data?.userMutation?.registerUser) {
          const old = cache.readQuery<
            UserFindAll,
            UserFindAllVariables
          >({ query: userFindAllQuery, variables: fetchAllVariables });
          cache.writeQuery<UserFindAll, UserFindAllVariables>({
            query: userFindAllQuery,
            variables: fetchAllVariables,
            data: update(old!, {
              userQuery: {
                users: {
                  edges: {
                    $push: [data.userMutation.registerUser],
                  },
                  pageInfo:{
                    rowCount:{
                      $set:old?.userQuery?.users?.pageInfo?.rowCount??0 + 1
                    }
                  }
                },
              },
            }),
          });
        }
      },
    }
  );
  const validate = React.useCallback( () => {
    var valid = true;
    const e = { ...error };
    if (draft.name) {
      e.name = "";
    } else {
      valid = false;
      e.name = "User name is required";
    }
    if (draft.fullName) {
      e.fullName = "";
    } else {
      valid = false;
      e.fullName = "Full name is required";
    }

    if (draft.password) {
      e.password = "";
    } else {
      valid = false;
      e.password = "Enter password";
    }
    if (draft.confirmPassword) {
      e.confirmPassword = "";
    } else {
      valid = false;
      e.confirmPassword = "Enter passward again!";
    }

    if (draft.password && draft.confirmPassword) {
      if (draft.password !== draft.confirmPassword) {
        valid = false;
        e.password = "Password not match";
        e.confirmPassword = "Password not match";
      } else {
        e.password = "";
        e.confirmPassword = "";
      }
    }
    setError(e);
    return valid;
  }, [draft.confirmPassword, draft.fullName, draft.name, draft.password, error]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const { name, fullName, userRole, password } = draft;
      await registerUser({
        variables: {
          user: {
            name,
            fullName,
            password,
            userRole,
          },
        },
      });
      onClose();
    }
  }, [draft, onClose, registerUser, validate]);
  const [openErrorMsgDialog, setOpenErrorMsgDialog] = React.useState(false);
  React.useEffect(() => {
    if (registerError) setOpenErrorMsgDialog(true);
  }, [registerError]);
  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle>Register User</DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <TextEditor
              sx={_sx.formControl}
              label="Full name"
              value={draft.fullName}
              error={!!error.fullName}
              helperText={error.fullName}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  fullName: v,
                });
                setError({ ...error, fullName: "" });
              }}
            />
            <EnumSingleSelectEditor
              options={userRoleOptions}
              sx={_sx.formControl}
              label="User role"
              value={draft.userRole}
              onChange={(e) => {
                setDraft({ ...draft, userRole: e.target.value as any });
              }}
            />
            <TextEditor
              sx={_sx.formControl}
              label="User name"
              value={draft.name}
              error={!!error.name}
              helperText={error.name}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  name: v,
                });
                setError({ ...error, name: "" });
                checkUserName({ variables: { id: null, checkFor: v } });
              }}
            />
            <TextEditor
              sx={_sx.formControl}
              label="Password"
              type="password"
              value={draft.password}
              error={!!error.password}
              helperText={error.password}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  password: v,
                });
                setError({ ...error, password: "" });
              }}
            />
            <TextEditor
              sx={_sx.formControl}
              label="Confirm password"
              type="password"
              value={draft.confirmPassword}
              error={!!error.confirmPassword}
              helperText={error.confirmPassword}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  confirmPassword: v,
                });
                setError({ ...error, confirmPassword: "" });
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActionLoadingIndicator
            loading={registeringUser}
            text="Registering user..."
          />
          <Button disabled={registeringUser} onClick={submit} color="secondary">
            OK
          </Button>
          <Button disabled={registeringUser} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {registerError ? (
        <ErrorMessageDialog
          open={openErrorMsgDialog}
          onClose={() => {
            setOpenErrorMsgDialog(false);
          }}
          title={`Error`}
          message="Could not register new user."
          detail={registerError.message}
        />
      ) : null}
    </React.Fragment>
  );
}

export default RegisterUserDialog;

import * as React from "react";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import CalendarToday from "@mui/icons-material/CalendarToday";
import settings from "../config.json";
import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Popper from "@mui/material/Popper/Popper";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Paper from "@mui/material/Paper/Paper";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import format from "date-fns/format";
import startOfDay from "date-fns/startOfDay";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import addWeeks from "date-fns/addWeeks";
import addQuarters from "date-fns/addQuarters";
import addMonths from "date-fns/addMonths";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfQuarter from "date-fns/startOfQuarter";
import endOfQuarter from "date-fns/endOfQuarter";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import endOfDay from "date-fns/endOfDay";
export interface DateRange {
  from: Date;
  to: Date;
}

export const formatDateRange = (value: DateRange, enableTime: boolean) => {
  if (!value) return "";
  const { from, to } = value;
  const formatString = `${settings.shortDateFormat} ${enableTime ? settings.shortTimeFormat : ""
    }`;
  const fromText = from ? format(from, formatString) : "";
  const toText = to ? format(to, formatString) : "";
  return `${fromText} - ${toText}`;
};

export class DateRangeWithLabel {
  label: string;
  value: DateRange;
  constructor(
    value: DateRange,
    label: string = "",
    enableTime: boolean = false
  ) {
    this.value = value;
    this.label = label ? label : formatDateRange(value, enableTime);
  }
}

export interface DateRangeValues {
  [key: string]: DateRangeWithLabel;
}

export const populateCommonDateRange = () => {
  const now = new Date();
  const yesterday = addDays(now, -1);
  const lastMonth = addMonths(now, -1);
  const nextMonth = addMonths(now, 1);
  const lastQuarter = addQuarters(now, -1);
  const nextQuarter = addQuarters(now, 1);
  const lastWeek = addWeeks(now, -1);
  const nextWeek = addWeeks(now, 1);
  const lastYear = addYears(now, -1);
  const nextYear = addYears(now, 1);
  const tomorrow = addDays(now, 1);
  const values: DateRangeValues = {
    TODAY: {
      label: "Today",
      value: {
        from: startOfDay(now),
        to: startOfDay(now)
      }
    },
    THIS_WEEK: {
      label: "This Week",
      value: {
        from: startOfWeek(now),
        to: endOfWeek(now)
      }
    },
    THIS_MONTH: {
      label: "This Month",
      value: {
        from: startOfMonth(now),
        to: endOfMonth(now)
      }
    },
    THIS_QUARTER: {
      label: "This Quarter",
      value: {
        from: startOfQuarter(now),
        to: endOfQuarter(now)
      }
    },
    THIS_YEAR: {
      label: "This Year",
      value: {
        from: startOfYear(now),
        to: endOfYear(now)
      }
    },
    YESTERDAY: {
      label: "Yesterday",
      value: {
        from: startOfDay(yesterday),
        to: endOfDay(yesterday)
      }
    },
    LAST_WEEK: {
      label: "Last Week",
      value: {
        from: startOfWeek(lastWeek),
        to: endOfWeek(lastWeek)
      }
    },
    LAST_MONTH: {
      label: "Last Month",
      value: {
        from: startOfMonth(lastMonth),
        to: endOfMonth(lastMonth)
      }
    },
    LAST_QUARTER: {
      label: "Last Quarter",
      value: {
        from: startOfQuarter(lastQuarter),
        to: endOfQuarter(lastQuarter)
      }
    },
    LAST_YEAR: {
      label: "Last Year",
      value: {
        from: startOfYear(lastYear),
        to: endOfYear(lastYear)
      }
    },
    LAST_7_DAYS: {
      label: "Last 7 Days",
      value: {
        from: startOfDay(addDays(now, -6)),
        to: endOfDay(now)
      }
    },
    LAST_30_DAYS: {
      label: "Last 30 Days",
      value: {
        from: startOfDay(addDays(now, -29)),
        to: endOfDay(now)
      }
    },
    LAST_90_DAYS: {
      label: "Last 90 Days",
      value: {
        from: startOfDay(addDays(now, -89)),
        to: endOfDay(now)
      }
    },
    LAST_365_DAYS: {
      label: "Last 365 Days",
      value: {
        from: startOfDay(addDays(now, -364)),
        to: endOfDay(now)
      }
    },
    TOMORROW: {
      label: "Tomorrow",
      value: {
        from: startOfDay(tomorrow),
        to: endOfDay(tomorrow)
      }
    },
    NEXT_WEEK: {
      label: "Next Week",
      value: {
        from: startOfWeek(nextWeek),
        to: endOfWeek(nextWeek)
      }
    },
    NEXT_MONTH: {
      label: "Next Month",
      value: {
        from: startOfMonth(nextMonth),
        to: endOfMonth(nextMonth)
      }
    },
    NEXT_QUARTER: {
      label: "Next Quarter",
      value: {
        from: startOfQuarter(nextQuarter),
        to: endOfQuarter(nextQuarter)
      }
    },
    NEXT_YEAR: {
      label: "Next Year",
      value: {
        from: startOfYear(nextYear),
        to: endOfYear(nextYear)
      }
    }
  };
  return values;
};

const _sx = {
  list: {
    marginTop: 1,
    height: 200,
    overflow: "scroll"
  },
  popover: {
    zIndex: 1300
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 1
  },
  datePicker: {
    marginLeft: 2,
    marginRight: 2
  }
};

interface Props {
  value: DateRangeWithLabel;
  enableTime?: boolean;
  predifiedDateRange?: DateRangeValues;
  onChange: (value: DateRangeWithLabel) => void;
  error: boolean;
}

type State = {
  draftValue: DateRangeWithLabel;
  popperOpen: boolean;
  predifiedDateRange: DateRangeValues;
  selectedDateRangeKey: string;
  previousValueProp?: DateRangeWithLabel;
};

const defaultDateRangeValues = populateCommonDateRange();



class CsDateRangeEditor extends React.Component<Props, State> {
  input?: HTMLInputElement;
  calendarOpen: boolean = false;
  static initialValue = new DateRangeWithLabel(defaultDateRangeValues.TODAY.value, defaultDateRangeValues.TODAY.label);
  state: State = {
    draftValue: CsDateRangeEditor.initialValue,
    popperOpen: false,
    predifiedDateRange: {
      ...this.props.predifiedDateRange,
      ...populateCommonDateRange()
    },
    selectedDateRangeKey: ""
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { previousValueProp } = prevState;
    let { value } = nextProps;
    if (!value) value = CsDateRangeEditor.initialValue;
    if (previousValueProp !== value) {
      return {
        draftValue: value,
        previousValueProp: value
      };
    } else return null;
  }

  commitEditor(draftValue: DateRangeWithLabel) {
    const { onChange } = this.props;
    onChange(draftValue);
  }

  render() {
    const { enableTime, error } = this.props;
    const {
      draftValue,
      popperOpen,
      predifiedDateRange,
      selectedDateRangeKey
    } = this.state;
    const {
      value: { from, to }
    } = draftValue;
    const text = selectedDateRangeKey
      ? predifiedDateRange[selectedDateRangeKey].label
      : draftValue.label;

    const Picker = (enableTime ? DateTimePicker : DatePicker) as React.ElementType<DatePickerProps<Date, any> | DateTimePickerProps<Date, any>>

    return (
      <React.Fragment>
        <TextField
          onClick={() => {
            this.setState({ popperOpen: true });
          }}
          value={text}
          label="Select Date"
          inputRef={ref => {
            this.input = ref;
          }}
          error={error}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    this.setState({ popperOpen: true });
                  }}
                >
                  <CalendarToday />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Popper
          open={popperOpen}
          sx={_sx.popover}
          anchorEl={this.input}
          placement="bottom-start"
        >
          <ClickAwayListener
            onClickAway={() => {
              if (this.calendarOpen) {
                this.calendarOpen = false;
              } else this.setState({ popperOpen: false });
            }}
          >
            <Paper sx={_sx.paper}>
              <Picker
                renderInput={(props) => (<TextField {...props} />)}
                label="From"
                InputProps={{ sx: _sx.datePicker }}
                value={from}
                onChange={date => {
                  const newValue = new DateRangeWithLabel(
                    { from: date, to },
                    "",
                    enableTime
                  );
                  this.setState({
                    draftValue: newValue,
                    selectedDateRangeKey: ""
                  });
                  this.commitEditor(newValue);
                }}
                inputFormat={`${settings.shortDateFormat} ${enableTime ? settings.shortTimeFormat : ""
                  }`}
                onOpen={() => {
                  this.calendarOpen = true;
                }}
              />
              <Picker
                renderInput={(props) => (<TextField {...props} />)}
                InputProps={{ sx: _sx.datePicker }}
                label="To"
                value={to}
                inputFormat={`${settings.shortDateFormat} ${enableTime ? settings.shortTimeFormat : ""
                  }`}
                onChange={date => {
                  const newValue = new DateRangeWithLabel(
                    { from, to: date },
                    "",
                    enableTime
                  );
                  this.setState({
                    draftValue: newValue,
                    selectedDateRangeKey: ""
                  });
                  this.commitEditor(newValue);
                }}
                onOpen={() => {
                  this.calendarOpen = true;
                }}
              />
              <List sx={_sx.list}>
                {Object.keys(predifiedDateRange).map((key, index) => {
                  const dateRange = predifiedDateRange[key];
                  return (
                    <ListItem
                      key={key}
                      button
                      selected={key === selectedDateRangeKey}
                      onClick={() => {
                        this.setState({
                          draftValue: dateRange,
                          selectedDateRangeKey: key
                        });
                        this.commitEditor(dateRange);
                      }}
                    >
                      <ListItemIcon>
                        {key === selectedDateRangeKey ? (
                          <RadioButtonChecked color="primary" />
                        ) : (
                          <RadioButtonUnchecked />
                        )}
                      </ListItemIcon>
                      <ListItemText>{dateRange.label}</ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </React.Fragment>
    );
  }
}
export default CsDateRangeEditor;
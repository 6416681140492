import * as React from "react";
import Downshift, { GetItemPropsOptions } from "downshift";
import { ownerFindAllQuery } from "./graphql";
import Close from "@mui/icons-material/Close";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import { MenuItemProps } from "@mui/material/MenuItem/MenuItem";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Paper from "@mui/material/Paper/Paper";
import { OwnerFindAll, OwnerFindAllVariables } from "./__generated__/OwnerFindAll";
import { useQuery } from "@apollo/client/react";
import { OwnerOrderByInput } from "../../types/global-types";
import Box from '@mui/material/Box';
export type OwnerOptionType = {
  id: string,
  code: string,
  name: string
}

const _sx = {
  root: {
    position: "relative"
  },
  paper: {
    zIndex: 1,
    marginTop:1,
    maxHeight: 300,
    overflowY: "scroll",
    position: "absolute",
    left: 0,
    right: 0
  },
  chip: {
    marginTop:0.5,
    marginBottom:0.5,
    marginLeft:0.25,
    marginRight:0.25
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height:2,
  },
};

type RenderInputProps = TextFieldProps & {
  ref?: React.Ref<HTMLDivElement>;
  className?: string;
  clearSelection: () => void;
  openMenu?: () => void;
};

function renderInput(inputProps: RenderInputProps) {
  const {
    InputProps,
    classes,
    clearSelection,
    ref,
    openMenu,
    ...other
  } = inputProps;

  return (
    <TextField
      onChange={(e) => {
        if (e.target.value === "" && clearSelection) clearSelection();
      }}
      sx={{
        flexWrap: "wrap",
        "& .MuiTextField-root": {
          width: "auto",
          flexGrow: 1,
        }
      }}
      InputProps={{
        inputRef: ref,
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={clearSelection}>
              <Close fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={openMenu}>
              <ArrowDropDown />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}

interface RenderSuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<"div", { button?: never }>;
  selectedItem: OwnerOptionType | null;
  suggestion: OwnerOptionType;
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = suggestion === selectedItem;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 700 : 400,
      }}
    >
      {suggestion.code}-{suggestion.name}
    </MenuItem>
  );
}


type OwnerAutoCompleteProps = TextFieldProps & {
  onSelectedItemChange: (oldItem: OwnerOptionType| null) => void;
  selectedItem?: OwnerOptionType;
  className?: string;
};


type PopupMenuProps = {
  menuProps: any;
  getItemProps: (options: GetItemPropsOptions<OwnerOptionType>) => any;
  selectedItem: OwnerOptionType | null;
  inputValue?: string;
  highlightedIndex: number | null;
}
function PopupMenu({ menuProps: downshiftProps, selectedItem, inputValue, highlightedIndex, getItemProps }: PopupMenuProps) {
  const { data } = useQuery<OwnerFindAll, OwnerFindAllVariables>(ownerFindAllQuery, {
    variables: {
      where: {
        oR: [
          {
            name_Contains:
              selectedItem && selectedItem.name === inputValue
                ? ""
                : inputValue,
          },
          {
            code_Contains:
              selectedItem && selectedItem.name === inputValue
                ? ""
                : inputValue,
          },
        ],
      },
      pagination: {
        take: 20,
      },
      orderBy: [OwnerOrderByInput.code, OwnerOrderByInput.name],
    }
  });
  return (<Paper {...downshiftProps} sx={{
    zIndex: 2,
    maxHeight: 300,
    overflowY: "scroll",
    position: "absolute",
    left: 0,
    right: 0
  }} square>
    {
      data?.ownerQuery?.owners?.edges?.map(
        (suggestion: any, index: any) =>
          renderSuggestion({
            suggestion,
            index,
            itemProps: getItemProps({ item: suggestion }),
            highlightedIndex,
            selectedItem,
          })
      )
    }
  </Paper>);
}

function OwnerAutoComplete(props: OwnerAutoCompleteProps) {
  const { onSelectedItemChange, selectedItem, className, classes: textFieldclasses, ...textFieldProps } = props;
  return (
    <Downshift
      onSelect={onSelectedItemChange}
      selectedItem={selectedItem}
      itemToString={(item) => (item ? item.name : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        clearSelection,
        openMenu,
        getRootProps
      }) => {
        const { onBlur, ...inputProps } = getInputProps({});

        return (
          <Box {...getRootProps()} position="relative">
            {renderInput({
              fullWidth: true,
              label: "Owner",
              InputLabelProps: getLabelProps(),
              InputProps: {
                onBlur,
                onFocus: () => {
                  openMenu();
                },
              },
              inputProps,
              clearSelection,
              openMenu,
              ...textFieldProps
            })}
            {isOpen ? (
              <PopupMenu getItemProps={getItemProps} menuProps={getMenuProps()} selectedItem={selectedItem} inputValue={inputValue ?? ""} highlightedIndex={highlightedIndex}  />
            ) : null}
          </Box>
        );
      }}
    </Downshift>
  );
}


export default OwnerAutoComplete;

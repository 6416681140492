import { SnackbarProps } from "@mui/material/Snackbar";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import userSessionSlice, {
  UserSession,
  userSessionInitialState,
} from "./userSessionSlice";

export const pingAsync = createAsyncThunk<
  UserSession,
  void,
  { state: RootState; rejectValue: string }
>("site/ping", async (_, { rejectWithValue, getState }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`/Auth`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      return { authenticated: false };
    }
    const result = await response.json();
    if (!result) return { authenticated: false };
    const { userSession: pingedUserSession } = result;
    const {
      userId,
      userName: username,
      userRole,
      identityCode,
      defaultAdmin,
    } = pingedUserSession;
    return {
      userId,
      userName: username,
      userRole,
      identityCode,
      defaultAdmin,
      authenticated: true
    };
  } catch (e) {
    if (e instanceof Error) return rejectWithValue(e.message);
    return { authenticated: false };
  }
});

export const loginAsync = createAsyncThunk<
  UserSession,
  { userName: string; password: string },
  { state: RootState; rejectValue: string }
>("site/login", async ({userName,password}, { rejectWithValue, getState }) => {
  try {
    const response = await fetch("/Auth", {
      method: "POST",
      body: JSON.stringify({ UserName: userName, Password: password }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      return rejectWithValue("User name or password not valid!");
    }
    const resultUserSession = await response.json();
    if (!resultUserSession) return { authenticated: false };
    const {
      userId,
      userName: username,
      userRole,
      identityCode,
      defaultAdmin,
      accessToken,
      token
    } = resultUserSession;
    return {
      userId,
      accessToken,
      userName: username,
      userRole,
      identityCode,
      defaultAdmin,
      authenticated: true,
      validTo: token.validTo,
      validFrom: token.validFrom,
    };
  } catch (e) {
    if (e instanceof Error) return rejectWithValue(`Could  not login! ${e.message ? e.message : e.toString()}`);
    return { authenticated: false };
  }
});

export interface SiteState {
  snackbarProps: SnackbarProps;
  readonly isDrawerOpen: boolean;
  history: ReadonlyArray<string>;
  userSession: UserSession;
  pingStatus: "idle" | "loading" | "error";
  pingMessage?: string;
  loginStatus:"idle" | "loading" | "error" ;
  loginMessage?:string;
}

const initialState: SiteState = {
  snackbarProps: { open: false, message: "" },
  isDrawerOpen: false,
  history: [],
  userSession: userSessionInitialState,
  pingStatus: "loading",
  loginStatus:"idle"
};

export const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<SnackbarProps>) => {
      Object.assign(state.snackbarProps, action.payload);
      state.snackbarProps.open = true;
    },
    closeSnackbar: (state) => {
      state.snackbarProps = { open: false, message: "" };
    },
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    addHistory: (state, action: PayloadAction<string>) => {
      state.history.splice(0, 0, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pingAsync.pending, (state) => {
        state.pingStatus = "loading";
        state.pingMessage = "";
      })
      .addCase(pingAsync.rejected, (state, action) => {
        state.pingStatus = "error";
        state.pingMessage = action.payload;
      })
      .addCase(pingAsync.fulfilled,(state,action)=>{
        Object.assign(state.userSession ,action.payload);
        state.pingStatus = "idle";
        state.pingMessage = "";
      }).addCase(loginAsync.pending, (state) => {
        state.loginStatus = "loading";
        state.loginMessage = "";
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loginStatus = "error";
        state.loginMessage = action.payload;
      })
      .addCase(loginAsync.fulfilled,(state,action)=>{
        state.userSession = action.payload;
        localStorage.setItem("token", action.payload.accessToken??"");
        state.loginStatus = "idle";
        state.pingMessage = "";
      })
      .addMatcher(
        (action) => action.type.startsWith("userSession"),
        (state, action) => {
          state.userSession = userSessionSlice(state.userSession, action);
        }
      );
  },
});

export const {
  openSnackbar,
  closeSnackbar,
  toggleDrawer,
  addHistory,
} = siteSlice.actions;
export default siteSlice.reducer;

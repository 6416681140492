import * as React from "react";
import { userRoleOptions } from "./userRoleOptions";
import {
  checkUserNameExistQuery,
  updateUserMutation,
} from "./graphql";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Grid from "@mui/material/Grid/Grid";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { CheckUserNameExists, CheckUserNameExistsVariables } from "./__generated__/CheckUserNameExists";
import { UpdateUser, UpdateUserVariables } from "./__generated__/UpdateUser";
import { UserRole } from "../../types/global-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import TextEditor from "../../components/TextEditor";
import EnumSingleSelectEditor from "../../components/EnumSingleSelectEditor";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
export type UpdateUserDialogProps = {
  open: boolean;
  onClose: () => void;
  edit: UpdateUserType;
};

const _sx={
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
};

type UpdateUserType = {
  id: string;
  name: string;
  fullName: string;
  userRole: UserRole;
};

function UpdateUserDialog({
  open,
  onClose,
  edit,
}: UpdateUserDialogProps) {
  const [draft, setDraft] = React.useState<UpdateUserType>(edit);
  React.useEffect(() => {
    setDraft(edit);
  }, [edit]);
  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [checkUserName] = useLazyQuery<
    CheckUserNameExists,
    CheckUserNameExistsVariables
  >(checkUserNameExistQuery, {
    onCompleted: (data) => {
      if (data?.userQuery?.userNameExists) {
        setError({ ...error, name: "User name already exists!" });
      } else {
        setError({ ...error, name: "" });
      }
    },
    fetchPolicy: "network-only",
  });
  const [
    updateUser,
    { loading: updatingUser, error: updateError },
  ] = useMutation<UpdateUser, UpdateUserVariables>(
    updateUserMutation
  );
  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.name) {
      e.name = "";
    } else {
      valid = false;
      e.name = "User name is required";
    }
    if (draft.fullName) {
      e.fullName = "";
    } else {
      valid = false;
      e.fullName = "Full name is required";
    }
    setError(e);
    return valid;
  }, [draft.fullName, draft.name, error]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const { name, fullName, userRole, id } = draft;
      await updateUser({
        variables: {
          userName: name,
          fullName,
          role: userRole,
          id,
        },
      });
      onClose();
    }
  }, [draft, onClose, updateUser, validate]);
  const [openErrorMsgDialog, setOpenErrorMsgDialog] = React.useState(false);
  React.useEffect(() => {
    if (updateError) setOpenErrorMsgDialog(true);
  }, [updateError]);
  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle>Register User</DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <TextEditor
              sx={_sx.formControl}
              label="Full name"
              value={draft.fullName}
              error={!!error.fullName}
              helperText={error.fullName}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  fullName: v,
                });
                setError({ ...error, fullName: "" });
              }}
            />
            <EnumSingleSelectEditor
              options={userRoleOptions}
              sx={_sx.formControl}
              label="User role"
              value={draft.userRole}
              onChange={(e) => {
                setDraft({ ...draft, userRole: e.target.value as any });
              }}
            />
            <TextEditor
              sx={_sx.formControl}
              label="User name"
              value={draft.name}
              error={!!error.name}
              helperText={error.name}
              onValidated={(v) => {
                setDraft({
                  ...draft,
                  name: v,
                });
                setError({ ...error, name: "" });
                checkUserName({ variables: { id: draft.id, checkFor: v } });
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActionLoadingIndicator
            loading={updatingUser}
            text="Updating user..."
          />
          <Button disabled={updatingUser} onClick={submit} color="secondary">
            OK
          </Button>
          <Button disabled={updatingUser} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {updateError ? (
        <ErrorMessageDialog
          open={openErrorMsgDialog}
          onClose={() => {
            setOpenErrorMsgDialog(false);
          }}
          title={`Error`}
          message="Could not update user."
          detail={updateError.message}
        />
      ) : null}
    </React.Fragment>
  );
}

export default UpdateUserDialog;

import * as React from "react";
import TextEditor from "../components/TextEditor";
import { checkCityNameExistsQuery } from "./graphql";
import Grid from "@mui/material/Grid/Grid";
import NumberEditor from "../components/NumberEditor";
import RegionAutoComplete from "./RegionAutoComplete";
import { CheckCityNameExists, CheckCityNameExistsVariables } from "./__generated__/CheckCityNameExists";
import { CheckCityAliasExists, CheckCityAliasExistsVariables } from "./__generated__/CheckCityAliasExists";
import { CityFindAll_cityQuery_cities_edges } from "./__generated__/CityFindAll";
import { RegionFindAll_regionQuery_regions_edges } from "./__generated__/RegionFindAll";
import { useLazyQuery } from "@apollo/client";

export type CityEditControlProps = {
  value: CityFindAll_cityQuery_cities_edges;
  setValue: (value: CityFindAll_cityQuery_cities_edges) => void;
  error: { [key: string]: string };
  setError: (error: { [key: string]: string }) => void;
};
const _sx = {
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
};
function CityEditControl({
  value,
  setValue,
  error,
  setError,
}: CityEditControlProps) {
  const [checkCityName] = useLazyQuery<
    CheckCityNameExists,
    CheckCityNameExistsVariables
  >(checkCityNameExistsQuery, {
    onCompleted: (data) => {
      if (data.cityQuery?.nameExists) {
        setError({ ...error, name: "City name already exists!" });
      } else {
        setError({ ...error, name: "" });
      }
    },
    fetchPolicy: "network-only",
  });
  const [checkCityAlias] = useLazyQuery<
    CheckCityAliasExists,
    CheckCityAliasExistsVariables
  >(checkCityNameExistsQuery, {
    onCompleted: (data) => {
      if (data.cityQuery?.aliasExists) {
        setError({ ...error, alias: "City alias already exists!" });
      } else {
        setError({ ...error, alias: "" });
      }
    },
    fetchPolicy: "network-only",
  });
  return (
    <Grid container direction="column">
      <TextEditor
        sx={_sx.formControl}
        label="Name"
        value={value.name}
        error={!!error.name}
        helperText={error.name}
        onValidated={(v) => {
          setValue({
            ...value,
            name: v,
          });
          setError({ ...error, name: "" });
          if (v) checkCityName({ variables: { id: value.id, checkFor: v } });
        }}
      />

      <TextEditor
        sx={_sx.formControl}
        label="Alias"
        value={value.alias ?? undefined}
        error={!!error.alias}
        helperText={error.alias}
        onValidated={(v) => {
          setValue({
            ...value,
            alias: v,
          });
          setError({ ...error, alias: "" });
          if (v) checkCityAlias({ variables: { id: value.id, checkFor: v } });
        }}
      />

      <RegionAutoComplete
        sx={_sx.formControl}
        selectedItem={value.region}
        onSelectedItemChange={(item: RegionFindAll_regionQuery_regions_edges | null) => {
          setValue({ ...value, region: item });
          if (!item)
            setError({ ...error, region: "Select region." });
          else
            setError({ ...error, region: "" });
        }}
        error={!!error.region}
        helperText={error.region}
      />

      <NumberEditor
        sx={_sx.formControl}
        numberPrecision={0}
        label="Sort order"
        value={value.sortOrder ?? undefined}
        onValidated={async (v) => {
          setValue({ ...value, sortOrder: v ?? null });
        }}
      />
    </Grid>
  );
}

export default CityEditControl;

import gql from "graphql-tag";

const regionFindAllQuery = gql`
  query RegionFindAll($where:RegionWhereInput,$pagination:PaginationInput, $orderBy:[RegionOrderBy]){
  regionQuery{
    id
    regions(where:$where,pagination:$pagination,orderBy:$orderBy){
      pageInfo{
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        pageSize
        rowCount
      }
      edges{
        id
        alias
        name
        sortOrder
      }
    }
  }
}
`;

const cityFindAllQuery = gql`
query CityFindAll($where:CityWhereInput,$pagination:PaginationInput, $orderBy:[CityOrderBy]){
  cityQuery{
    id
    cities(where:$where,pagination:$pagination,orderBy:$orderBy){
      pageInfo{
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        pageSize
        rowCount
      }
      edges{
        id
        alias
        name
        sortOrder
        region{
          id
          alias
          name
          sortOrder
        }
      }
    }
  }
}
`;

const createCityMutation = gql`
mutation CreateCity($city:CityInput!){
  cityMutation{
    id
    createCity(city:$city){
      id
      alias
      name
      sortOrder
      region{
        id
        alias
        name
        sortOrder
      }
    }
  }
}
`;

const updateCityMutation = gql`
mutation UpdateCity($city:CityInput!){
  cityMutation{
    id
    updateCity(city:$city){
      id
      alias
      name
      sortOrder
      region{
        id
        alias
        name
        sortOrder
      }
    }
  }
}
`;

const updateRegionMutation = gql`
mutation UpdateRegion($region:RegionInput!){
  regionMutation{
    id
    updateRegion(region:$region){
      id
      alias
      name
      sortOrder
    }
  }
}
`;

const createRegionMutation = gql`
mutation CreateRegion($region:RegionInput!){
  regionMutation{
    id
    createRegion(region:$region){
      id
      alias
      name
      sortOrder
    }
  }
}
`;

const deleteRegionMutation  = gql`
mutation DeleteRegion($id:ID!){
  regionMutation{
    id
    deleteRegion(id:$id)
  }
}
`;

const deleteCityMutation = gql`
mutation DeleteCity($id:ID!){
  cityMutation{
    id
    deleteCity(id:$id)
  }
}
`;


const checkRegionNameExistsQuery = gql`
query CheckRegionNameExists($id: ID, $checkFor: String!) {
    regionQuery {
      id
      nameExists(id: $id, checkFor: $checkFor)
    }
  }
`;

const checkRegionAliasExistsQuery = gql`
query CheckRegionAliasExists($id: ID, $checkFor: String!) {
    regionQuery {
      id
      aliasExists(id: $id, checkFor: $checkFor)
    }
  }
`;

const checkCityNameExistsQuery = gql`
query CheckCityNameExists($id: ID, $checkFor: String!) {
    cityQuery {
      nameExists(id: $id, checkFor: $checkFor)
    }
  }
`;

const checkCityAliasExistsQuery = gql`
query CheckCityAliasExists($id: ID, $checkFor: String!) {
  cityQuery {
      aliasExists(id: $id, checkFor: $checkFor)
    }
  }
`;

const getRegionByIdQuery = gql`
query GetRegionById($id: ID!) {
    regionQuery {
      id
      region(id:$id){
        id
        name
        alias
        sortOrder
      }
    }
  }
`;

const getCityByIdQuery = gql`
query GetCityById($id: ID!) {
    cityQuery {
      id
      city(id:$id){
        id
        name
        alias
        sortOrder
        region{
          id
          name
          alias
          sortOrder
        }
      }
    }
  }
`;

export {cityFindAllQuery,regionFindAllQuery, updateCityMutation,updateRegionMutation,createCityMutation,createRegionMutation,checkRegionNameExistsQuery,checkRegionAliasExistsQuery , checkCityAliasExistsQuery ,checkCityNameExistsQuery, getCityByIdQuery,getRegionByIdQuery,deleteRegionMutation, deleteCityMutation };
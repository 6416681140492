import { OwnerType } from "../../types/global-types";



const ownerTypeOptions: {[ key: string] : OwnerTypeOptionType} = {
    [OwnerType.COMPANY]: { value: OwnerType.COMPANY, text: "Company"},
    [OwnerType.INDIVIDUAL]: { value: OwnerType.INDIVIDUAL, text: "Individual" }
};

const ownerTypeOptionList = Object.keys(ownerTypeOptions).map(e => ownerTypeOptions[e]);



export type OwnerTypeOptionType = {
    value: OwnerType,
    text: string
};

export  { ownerTypeOptions ,  ownerTypeOptionList  };
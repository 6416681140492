import * as React from "react";
import Snackbar from '@mui/material/Snackbar';
import LoadingBar from 'react-redux-loading-bar';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ProtectedLayout from "./ProtectedLayout";
import { defaultSnackbarProps, SnackbarProvider } from "./app/SnackbarContext";
import { SnackbarProps } from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import { closeSnackbar,openSnackbar } from "./features/site/siteSlice";


const theme = createTheme({
    typography: {
      fontWeightBold: 700,
      fontWeightMedium: 500,
      fontWeightRegular: 400,
      fontFamily: "Noto Sans Myanmar,Poppins, sans-serif",
      body1: {
        lineHeight: "2em",
      },
      subtitle1: {
        fontWeight: 500
      }
    }
  })
function App() {
    const snackbarProps = useSelector((state:RootState)=>state.site.snackbarProps);
    const dispatch = useDispatch();
    const hideSnackbar = React.useCallback(() => {
        dispatch(closeSnackbar());
    }, [dispatch]);
    const showSnackbar = React.useCallback(({ autoHideDuration, ...props }: SnackbarProps) => {
        dispatch(openSnackbar({ ...defaultSnackbarProps, open: true, autoHideDuration: autoHideDuration ?? defaultSnackbarProps.autoHideDuration, ...props }));
    }, [dispatch]);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LoadingBar />
            <SnackbarProvider value={{ showSnackbar }}>
                <ProtectedLayout/>
            </SnackbarProvider>
            <Snackbar {...snackbarProps} onClose={hideSnackbar} />
        </ThemeProvider>
    );
}

export default App
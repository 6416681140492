import gql from "graphql-tag";

const userFindAllQuery = gql`
  query UserFindAll(
    $where: UserWhereInput
    $orderBy: [UserOrderBy!]
    $pagination: PaginationInput
  ) {
    userQuery {
      id
      users(where: $where, orderBy: $orderBy, pagination: $pagination) {
        edges {
          id
          name
          fullName
          userRole
          defaultAdmin
          createdAt
          active
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          pageCount
          pageSize
          rowCount
        }
      }
    }
  }
`;

const registerUserMutation = gql`
  mutation RegisterUser($user: UserInput!) {
    userMutation {
      id
      registerUser(user: $user) {
        id
        name
        fullName
        userRole
        defaultAdmin
        createdAt
        active
      }
    }
  }
`;

const checkUserNameExistQuery = gql`
  query CheckUserNameExists($id: ID, $checkFor: String!) {
    userQuery {
      userNameExists(id: $id, checkFor: $checkFor)
    }
  }
`;

const updateUserMutation = gql`
  mutation UpdateUser(
    $userName: String!
    $fullName: String!
    $id: ID!
    $role: UserRole!
  ) {
    userMutation {
      id
      updateUser(
        userName: $userName
        fullName: $fullName
        id: $id
        role: $role
      ) {
        id
        name
        fullName
        userRole
        createdAt
        active
        defaultAdmin
      }
    }
  }
`;

const resetPasswordMutation = gql`
  mutation ResetPassword($id: ID!, $newPassword: String!) {
    userMutation {
      id
      resetPassword(id: $id, newPassword: $newPassword)
    }
  }
`;

const deleteUserMutation = gql`
  mutation DeleteUser($id: ID!) {
    userMutation {
      id
      deleteUser(id: $id)
    }
  }
`;

const activateUserMutation = gql`
  mutation ActivateUser($id: ID!) {
    userMutation {
      id
      activateUser(id: $id) {
        id
        name
        fullName
        userRole
        defaultAdmin
        createdAt
        active
      }
    }
  }
`;

const deactivateUserMutation = gql`
  mutation DeactivateUser($id: ID!) {
    userMutation {
      id
      deactivateUser(id: $id) {
        id
        name
        fullName
        userRole
        defaultAdmin
        createdAt
        active
      }
    }
  }
`;

export {
  userFindAllQuery,
  registerUserMutation,
  checkUserNameExistQuery,
  updateUserMutation,
  resetPasswordMutation,
  deleteUserMutation,
  activateUserMutation,
  deactivateUserMutation,
};

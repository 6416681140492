import * as React from "react";
import {
  FilterCondition,
  FilterOperator,
  OperandType,
  FilterValue,
  getDefaultOperatorForOperandType,
  stringifyFilterValues,
  getOperatorsForOperandType,
  getLabelForOperator,
  FilterOperands
} from "./CsSearchBox";
import NumberEditor from "./NumberEditor";
import TextEditor from "./TextEditor";
import TextListEditor from "./TextListEditor";
import update from "immutability-helper";
import NumberListEditor from "./NumberListEditor";
import Box from "@mui/material/Box";
import CsDateRangeEditor, {
  DateRangeWithLabel
} from "./CsDateRangeEditor";
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import settings from "../config.json";
import EnumMultiSelectEditor, { EnumSelectOptions } from "./EnumMultiSelectEditor";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Chip from "@mui/material/Chip/Chip";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
const _sx = {
  formControl: {
    marginTop: 1,
    marginBottom: 1
  },
  chips: {
    marginTop: 1,
    marginBottom: 1
  },
  chip: {
    margin: .5
  },
  paper: {
    overflow: "hidden",
    width: 400
  },
  operatorInChip: {
    fontStyle: "italic",
    marginLeft: 1,
    marginRight: 1
  },
  conditionEditor: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 1,
    paddingBottom: 1
  }
};

export type MultiOperandFilterCondition = {
  key: string;
  conditions: ReadonlyArray<FilterCondition>;
};

interface Props {
  availableOperands: FilterOperands;
  filterCondition: MultiOperandFilterCondition;
  open: boolean;
  onAccept: (condition: MultiOperandFilterCondition) => void;
  onReject: () => void;
}

type State = {
  editedConditions: ReadonlyArray<FilterCondition>;
  availableOperators: ReadonlyArray<FilterOperator>;
  selectedConditionIndex: number;
  previousFilterConditionProp?: MultiOperandFilterCondition;
};

class CsMultiOperandFilterEditor extends React.Component<Props, State> {
  state: State = {
    editedConditions: [],
    selectedConditionIndex: 0,
    availableOperators: new Array<FilterOperator>()
  };

  static getDerivedStateFromProps(nextProps: Props, previousState: State) {
    const { previousFilterConditionProp } = previousState;
    const { filterCondition } = nextProps;
    if (!filterCondition) {
      return {
        editedConditions: new Array<FilterCondition>()
      };
    } else if (previousFilterConditionProp !== filterCondition) {
      const { conditions } = filterCondition;
      const [condition] = conditions;
      return {
        editedConditions: conditions,
        previousFilterConditionProp: filterCondition,
        selectedConditionIndex: 0,
        availableOperators: condition
          ? getOperatorsForOperandType(condition.operand.type, true)
          : []
      };
    } else {
      return null;
    }
  }

  renderNumberRangeValueEditor(value: [number?, number?]) {
    const [from = undefined, to = undefined] = value
      ? value
      : [undefined, undefined];
    return (
      <React.Fragment>
        <NumberEditor
          label="From"
          sx={_sx.formControl}
          value={from}
          onValidated={v => {
            if (v && to)
              this.updateFilterValue([v, to]);
          }}
          error={from === null || from === undefined}
          helperText={
            from === null || from === undefined ? "Enter number." : ""
          }
        />
        <NumberEditor
          label="To"
          sx={_sx.formControl}
          value={to}
          onValidated={v => {
            if (v && from)
              this.updateFilterValue([from, v]);
          }}
          error={to === null || to === undefined}
          helperText={to === null || to === undefined ? "Enter number." : ""}
        />
      </React.Fragment>
    );
  }

  renderTextRangeValueEditor(value: [string, string]) {
    const [from = "", to = ""] = value ? value : ["", ""];
    return (
      <React.Fragment>
        <TextEditor
          label="From"
          sx={_sx.formControl}
          value={from}
          onValidated={v => {
            this.updateFilterValue([v, to]);
          }}
          error={!from}
          helperText={!from ? "Enter text." : ""}
        />
        <TextEditor
          label="To"
          sx={_sx.formControl}
          value={to}
          onValidated={v => {
            this.updateFilterValue([from, v]);
          }}
          error={!to}
          helperText={!to ? "Enter text." : ""}
        />
      </React.Fragment>
    );
  }

  renderTextListValueEditor(value: ReadonlyArray<string>) {
    return (
      <TextListEditor
        value={value || undefined}
        onChanged={v => {
          this.updateFilterValue(v);
        }}
      />
    );
  }

  renderNumberListValueEditor(value: ReadonlyArray<number>) {
    return (
      <NumberListEditor
        value={value || undefined}
        onChanged={v => {
          this.updateFilterValue(v);
        }}
      />
    );
  }

  renderSingleNumberValueEditor(value: number) {
    return (
      <NumberEditor
        label="Filter value"
        sx={_sx.formControl}
        value={value}
        onValidated={v => {
          if (v)
            this.updateFilterValue(v);
        }}
        error={value === null || value === undefined}
        helperText={
          value === null || value === undefined ? "Enter number." : ""
        }
      />
    );
  }

  renderSingleTextValueEditor(value: string) {
    return (
      <TextEditor
        label="Filter value"
        sx={_sx.formControl}
        value={value}
        onValidated={v => {
          this.updateFilterValue(v);
        }}
        error={!value}
        helperText={!value ? "Enter text." : ""}
      />
    );
  }

  renderSingleDateValueEditor(value: Date, enableTime: boolean = false) {
    const Picker = (enableTime ? DateTimePicker : DatePicker) as React.ElementType<DatePickerProps<Date, any> | DateTimePickerProps<Date, any>>
    return (
      <Picker
        renderInput={(props) => (<TextField {...props} />)}
        label="Date"
        InputProps={{ sx: _sx.formControl }}
        value={value}
        inputFormat={`${settings.shortDateFormat} ${enableTime ? settings.shortTimeFormat : ""
          }`}
        onChange={(date: Date) => {
          this.updateFilterValue(date);
        }}
      />
    );
  }

  renderEnumSelectEditor(value: any[], options: EnumSelectOptions) {
    return (<EnumMultiSelectEditor options={options} value={value} sx={_sx.formControl} onChange={(value: readonly any[]) => {
      this.updateFilterValue(value);
    }} />)
  }

  renderDateRangeValueEditor(
    value: DateRangeWithLabel,
    enableTime: boolean = false
  ) {
    return (
      <CsDateRangeEditor
        value={value}
        enableTime={enableTime}
        error={!value}
        onChange={(value: DateRangeWithLabel) => {
          this.updateFilterValue(value);
        }}
      />
    );
  }

  updateFilterValue(value: FilterValue) {
    const { selectedConditionIndex, editedConditions } = this.state;
    const newEditedConditions = update(editedConditions, {
      [selectedConditionIndex]: {
        value: {
          $set: value
        }
      }
    });
    this.setState({ editedConditions: newEditedConditions });
  }

  validateEditingCondition() {
    const {
      selectedConditionIndex = 0,
      editedConditions: conditions
    } = this.state;
    const selectedCondition = conditions[selectedConditionIndex];

    if (!selectedCondition) return true;
    const { operand } = selectedCondition;
    const { operator, value } = selectedCondition;
    switch (operator) {
      case FilterOperator.CONTAINS:
      case FilterOperator.END_WITH:
      case FilterOperator.EQUAL:
      case FilterOperator.GREATER_THAN:
      case FilterOperator.GREATER_THAN_EQUAL:
      case FilterOperator.LESS_THAN:
      case FilterOperator.LESS_THAN_EQUAL:
      case FilterOperator.NOT_CONTAIN:
      case FilterOperator.NOT_END_WITH:
      case FilterOperator.NOT_EQUAL:
      case FilterOperator.NOT_START_WITH:
      case FilterOperator.STARTS_WITH:
        switch (operand.type) {
          case OperandType.STRING:
            return !!value;
          case OperandType.NUMBER:
            return value !== undefined && value !== null;
          default:
            return true;
        }
      case FilterOperator.BETWEEN:
      case FilterOperator.NOT_BETWEEN:
        switch (operand.type) {
          case OperandType.STRING:
            const [fromText, toText] = value as ReadonlyArray<string>;
            return fromText && toText;
          case OperandType.NUMBER:
            const [fromNum, toNum] = value as ReadonlyArray<number>;
            return (
              fromNum !== null &&
              fromNum !== undefined &&
              toNum !== null &&
              toNum !== null
            );
          case OperandType.DATE:
          case OperandType.DATETIME:
            return value;
          default:
            return true;
        }
      case FilterOperator.IN:
      case FilterOperator.NOT_IN:
        return value && (value as []).length > 0;
      default:
        return true;
    }
  }

  renderFilterValueEditor() {
    const { editedConditions: conditions } = this.state;
    const { selectedConditionIndex = 0 } = this.state;
    const selectedCondition = conditions[selectedConditionIndex];
    const { operator, value } = selectedCondition;
    if (!selectedCondition) return null;
    const { operand } = selectedCondition;
    switch (operator) {
      case FilterOperator.CONTAINS:
      case FilterOperator.END_WITH:
      case FilterOperator.EQUAL:
      case FilterOperator.GREATER_THAN:
      case FilterOperator.GREATER_THAN_EQUAL:
      case FilterOperator.LESS_THAN:
      case FilterOperator.LESS_THAN_EQUAL:
      case FilterOperator.NOT_CONTAIN:
      case FilterOperator.NOT_END_WITH:
      case FilterOperator.NOT_EQUAL:
      case FilterOperator.NOT_START_WITH:
      case FilterOperator.STARTS_WITH:
        switch (operand.type) {
          case OperandType.STRING:
            return this.renderSingleTextValueEditor(value as string);
          case OperandType.NUMBER:
            return this.renderSingleNumberValueEditor(value as number);
          case OperandType.DATE:
            return this.renderSingleDateValueEditor(value as Date);
          case OperandType.DATETIME:
            return this.renderSingleDateValueEditor(value as Date, true);
          default:
            return null;
        }
      case FilterOperator.BETWEEN:
      case FilterOperator.NOT_BETWEEN:
        switch (operand.type) {
          case OperandType.STRING:
            return this.renderTextRangeValueEditor(value as [string, string]);
          case OperandType.NUMBER:
            return this.renderNumberRangeValueEditor(value as [number?, number?]);
          case OperandType.DATE:
            return this.renderDateRangeValueEditor(value as DateRangeWithLabel);
          case OperandType.DATETIME:
            return this.renderDateRangeValueEditor(
              value as DateRangeWithLabel,
              true
            );
        }
        break;
      case FilterOperator.IN:
      case FilterOperator.NOT_IN:
        switch (operand.type) {
          case OperandType.STRING:
            return this.renderTextListValueEditor(value as ReadonlyArray<
              string
            >);
          case OperandType.NUMBER:
            return this.renderNumberListValueEditor(value as ReadonlyArray<
              number
            >);
          case OperandType.ENUM:
            if (operand.enumOptions)
              return this.renderEnumSelectEditor(value as any[], operand.enumOptions);
        }
        break;
      default:
    }
  }

  createNewCondition() {
    if (!this.validateEditingCondition()) return;
    const { editedConditions } = this.state;
    const { availableOperands } = this.props;
    const operand = availableOperands[Object.keys(availableOperands)[0]];
    const newCondition: FilterCondition = {
      operand: operand,
      operator: getDefaultOperatorForOperandType(operand.type),
      value: undefined
    };
    const newEditedConditions = [...editedConditions, newCondition];
    const availableOperators = getOperatorsForOperandType(operand.type, true);
    this.setState({
      editedConditions: newEditedConditions,
      selectedConditionIndex: newEditedConditions.indexOf(newCondition),
      availableOperators
    });
  }

  render() {
    const {
      filterCondition,
      open,
      onReject,
      onAccept,
      availableOperands
    } = this.props;
    if (!filterCondition) return null;
    const {
      editedConditions: conditions,
      selectedConditionIndex = 0,
      availableOperators
    } = this.state;
    const selectedCondition = conditions[selectedConditionIndex];
    return (
      <Dialog
        open={open}
        aria-labelledby="Filter Conditions"
        PaperProps={{ sx: _sx.paper }}
      >
        <DialogTitle>Filter Conditions</DialogTitle>
        <DialogContent>
          <Box sx={_sx.chips}>
            {conditions.map((condition, index) => {
              const { operator, operand } = condition;
              let operatorLabel = ":";
              switch (operand.type) {
                case OperandType.DATE:
                case OperandType.DATETIME:
                  switch (operator) {
                    case FilterOperator.BETWEEN:
                      operatorLabel = ":";
                      break;
                    case FilterOperator.NOT_BETWEEN:
                      operatorLabel = "not";
                      break;
                    default:
                      operatorLabel = getLabelForOperator(
                        operator
                      )?.shortLabel ?? "";
                      break;
                  }
                  break;
                default:
                  operatorLabel = getLabelForOperator(
                    operator,
                  )?.shortLabel ?? "";
                  break;
              }

              return (
                <Chip
                  sx={_sx.chip}
                  color={
                    selectedConditionIndex === index ? "primary" : "default"
                  }
                  key={index.toString()}
                  label={
                    <React.Fragment>
                      <Typography variant="body2">
                        {condition.operand.name}
                      </Typography>
                      <Typography
                        sx={_sx.operatorInChip}
                        variant="body2"
                      >
                        {operatorLabel}
                      </Typography>
                      <Typography variant="body2">
                        {stringifyFilterValues(condition)}
                      </Typography>
                    </React.Fragment>
                  }
                  size="small"
                  onDelete={() => {
                    if (conditions.length === 1) return;
                    const newConditions = update(conditions, {
                      $splice: [[index, 1]]
                    });
                    let newIndex = newConditions.indexOf(selectedCondition);
                    if (newIndex === -1) newIndex = newConditions.length - 1;
                    const newCondition = newConditions[newIndex];
                    this.setState({
                      editedConditions: newConditions,
                      selectedConditionIndex: newIndex,
                      availableOperators: newCondition
                        ? getOperatorsForOperandType(
                          newCondition.operand.type,
                          true
                        )
                        : []
                    });
                  }}
                  onClick={() => {
                    if (this.validateEditingCondition()) {
                      const operators = getOperatorsForOperandType(
                        condition.operand.type,
                        true
                      );
                      this.setState({
                        selectedConditionIndex: index,
                        availableOperators: operators
                      });
                    }
                  }}
                />
              );
            })}
          </Box>
          <Divider />
          <Box sx={_sx.conditionEditor}>
            <FormControl sx={_sx.formControl}>
              <InputLabel htmlFor="operand-select">Operand</InputLabel>
              <Select
                value={selectedCondition.operand.propName}
                onChange={e => {
                  const propName = e.target.value.toString();
                  const selectedOperand = availableOperands[propName];
                  const newConditions = update(conditions, {
                    [selectedConditionIndex]: {
                      $set: {
                        operand: selectedOperand,
                        operator: getDefaultOperatorForOperandType(
                          selectedOperand.type
                        ),
                        value: undefined
                      }
                    }
                  });
                  this.setState({
                    editedConditions: newConditions,
                    availableOperators: getOperatorsForOperandType(
                      selectedOperand.type,
                      true
                    )
                  });
                }}
                inputProps={{
                  name: "operator",
                  id: "operator-select"
                }}
              >
                {Object.keys(availableOperands).map((key, index) => {
                  const operand = availableOperands[key];
                  return (
                    <MenuItem value={operand.propName} key={operand.propName}>
                      {operand.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={_sx.formControl}>
              <InputLabel htmlFor="operator-select">Filter Operator</InputLabel>
              <Select
                value={selectedCondition.operator}
                onChange={e => {
                  const newConditions = update(conditions, {
                    [selectedConditionIndex]: {
                      operator: {
                        $set: e.target.value as FilterOperator
                      },
                      value: {
                        $set: undefined
                      }
                    }
                  });
                  this.setState({ editedConditions: newConditions });
                }}
                inputProps={{
                  name: "operator",
                  id: "operator-select"
                }}
              >
                {availableOperators.map(operator => {
                  const label = getLabelForOperator(
                    operator
                  )?.label;
                  return (
                    <MenuItem key={operator.toString()} value={operator}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {this.renderFilterValueEditor()}
          </Box>
          <DialogActions>
            <Button
              onClick={this.createNewCondition.bind(this)}
              color="primary"
            >
              New Condition
            </Button>
            <Button onClick={onReject} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (this.validateEditingCondition())
                  onAccept({
                    key: filterCondition.key,
                    conditions
                  });
              }}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default CsMultiOperandFilterEditor;

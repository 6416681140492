import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { RootState } from "./store";
import AccountBox from "@mui/icons-material/AccountBox";
import Apartment from "@mui/icons-material/Apartment";
import LocationOn from "@mui/icons-material/LocationOn";
import LocationCity from "@mui/icons-material/LocationCity";
import Build from "@mui/icons-material/Build";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import People from "@mui/icons-material/People";
import ConfirmDialog from "./ConfirmDialog";
import Collapse from "@mui/material/Collapse";
import { setUserSession } from "../features/site/userSessionSlice";

type DrawerMenuProps = {
    icon: React.ReactElement;
    text: string;
    url?: string;
    nested?: boolean;
};

function DrawerMenuItem(props: DrawerMenuProps) {
    const { icon, text } = props;
    return (
        <React.Fragment>
            <ListItemIcon sx={{color:"inherit"}}>{icon}</ListItemIcon>
            <ListItemText primary={text} />
        </React.Fragment>
    );
}

function SelectableDrawerMenuItem(
    props: DrawerMenuProps & { url: string }
) {
    const { url, nested } = props;
    const match = useMatch(url);
    const navigate = useNavigate();
    return (
        <ListItem
            sx={{ paddingLeft: nested ? 4 : undefined,'&.Mui-selected':{color:"primary.main"}}}
            button
            selected={Boolean(match)}
            onClick={() => {
                navigate(url);
            }}
        >
            <DrawerMenuItem {...props} />
        </ListItem>
    );
}

function ConfigurationDrawerMenuItem() {
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <ListItem
                button
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <DrawerMenuItem icon={<Build />} text="Configuration" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <SelectableDrawerMenuItem
                        url="/users"
                        text="Users"
                        nested
                        icon={<People />}
                    />
                    <SelectableDrawerMenuItem
                        url="/regions"
                        text="Regions"
                        nested
                        icon={<LocationOn />}
                    />
                    <SelectableDrawerMenuItem
                        url="/cities"
                        text="City"
                        nested
                        icon={<LocationCity />}
                    />
                </List>
            </Collapse>
        </React.Fragment>
    );
}

function DrawerContent() {
    const userSession = useSelector((state: RootState) => state.site.userSession);
    const [logoutConfirmDialogOpen, setLogoutConfirmDialog] = React.useState(
        false
    );
    const dispatch = useDispatch();
    return (
        <div>
            <List>
                <ListItem
                    button
                    onClick={() => {
                        setLogoutConfirmDialog(true);
                    }}
                >
                    <ListItemAvatar>
                        <Avatar>{(userSession.userName ?? " ")[0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={userSession.userName}
                        secondary={userSession.fullName}
                    />
                </ListItem>
            </List>
            <Divider />
            <List>
                <SelectableDrawerMenuItem
                    url="/properties"
                    text="Properties"
                    icon={<Apartment />}
                />
                <SelectableDrawerMenuItem
                    url="/owners"
                    text="Owners"
                    icon={<AccountBox />}
                />
                <ConfigurationDrawerMenuItem />
            </List>
            <ConfirmDialog
                title="Confirm log out!"
                message="Are you sure to log out?"
                acceptLabel="yes"
                rejectLabel="no"
                open={logoutConfirmDialogOpen}
                onAccepted={() => {
                    dispatch(
                        setUserSession({
                            authenticated: false,
                            accessToken: "",
                        })
                    );
                    setLogoutConfirmDialog(false);
                }}
                onRejected={() => {
                    setLogoutConfirmDialog(false);
                }}
            />
        </div>
    );
}

export default DrawerContent;
import * as React from "react";
import { PhotoProps, renderImageClickHandler } from "react-photo-gallery";
import { SortableElementProps } from "react-sortable-hoc";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton/IconButton";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { SxProps } from "@mui/material";
import { Theme, styled } from '@mui/material/styles'
import Box from '@mui/material/Box';
import { PropertyGalleryPhotoGraphType } from "./GalleryView";
const Img = styled('img')({});
const _sx = {
  img: {
    objectFit: "cover",
    position: "relative",
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
  },
  loader: {
    position: "absolute",
    zIndex: 5,
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20
  }
};
const selectedImgStyle = {
  transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
  transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};
export type GalleryImageProps = {
  photo: PropertyGalleryPhotoGraphType;
  margin: number | string;
  direction: string;
  top?: number;
  left?: number;
  sortMode?: boolean;
  onClick: renderImageClickHandler | null;
  onDelete?: (index: number) => void;
  loading?: boolean;
  disableDelete?: boolean;
};
const GalleryImage = ({
  photo:{propertyPhoto,file,...photo},
  margin,
  direction,
  top,
  left,
  index,
  onClick,
  sortMode,
  onDelete,
  loading,
  disableDelete
}: GalleryImageProps & SortableElementProps) => {
  const rootStyle: SxProps<Theme> = { margin: margin };
  const deleteBtn: SxProps<Theme> = {
    position: "absolute",
    zIndex: 5
  };
  if (direction === "column") {
    rootStyle.position = "absolute";
    rootStyle.left = left;
    rootStyle.top = top;
    deleteBtn.left = left;
    deleteBtn.top = top;
  } else {
    rootStyle.position = "relative";
  }


  return (
    <Box sx={rootStyle}>
      <Img
        onClick={(event) => {
          const obj: any = { index, photo };
          if (onClick)
            onClick(event, obj);
        }}
        sx={sortMode ? { ..._sx.img, ...selectedImgStyle } : { ..._sx.img }}
        {...(photo as any)}
      />
      {
        disableDelete || sortMode ? null : (
          <IconButton
            onClick={() => {
              if (onDelete)
                onDelete(index);
            }}
            sx={deleteBtn}
            color="secondary"
          >
            <Close />
          </IconButton>
        )
      }
      {loading ? <CircularProgress sx={_sx.loader} /> : null}
    </Box>
  );
};

export default GalleryImage;

import gql from "graphql-tag";
const ownerFindAllQuery = gql`
  query OwnerFindAll(
    $where: OwnerWhereInput
    $pagination: PaginationInput
    $orderBy: [OwnerOrderByInput]
  ) {
    ownerQuery {
      id
      owners(where: $where, pagination: $pagination, orderBy: $orderBy) {
        edges {
          address
          code
          contactNumber
          contactPerson
          coverPhoto
          id
          name
          ownerType
          profilePhoto
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          pageCount
          pageSize
          rowCount
        }
      }
    }
  }
`;

const ownerFragment = gql`
  fragment OwnerPhoto on Owner {
    id
    coverPhoto
    profilePhoto
  }
`;

const checkOwnerCodeExistsQuery = gql`
  query CheckOwnerCodeExists($id: ID, $checkFor: String!) {
    ownerQuery {
      id
      ownerCodeExists(id: $id, checkFor: $checkFor)
    }
  }
`;

const createOwnerMutation = gql`
  mutation CreateOwner($owner: OwnerInput!) {
    ownerMutation {
      createOwner(owner: $owner) {
        address
        code
        contactNumber
        contactPerson
        coverPhoto
        id
        name
        ownerType
        profilePhoto
        properties(pagination: { pageSize: 1, page: 1 }) {
          pageInfo {
            rowCount
          }
        }
      }
    }
  }
`;

const updateOwnerMutation = gql`
  mutation UpdateOwner($owner: OwnerInput!) {
    ownerMutation {
      id
      updateOwner(owner: $owner) {
        address
        code
        contactNumber
        contactPerson
        coverPhoto
        id
        name
        ownerType
        profilePhoto
        properties(pagination: { pageSize: 1, page: 1 }) {
          pageInfo {
            rowCount
          }
        }
      }
    }
  }
`;

const getOwnerByIdQuery = gql`
  query GetOwnerById($id: ID!) {
    ownerQuery {
      id
      owner(id: $id) {
        address
        code
        contactNumber
        contactPerson
        coverPhoto
        id
        name
        ownerType
        profilePhoto
        properties(pagination: { pageSize: 1, page: 1 }) {
          pageInfo {
            rowCount
          }
        }
      }
    }
  }
`;

const deleteOwnerMutation = gql`
  mutation DeleteOwner($id: ID!) {
    ownerMutation {
      id
      deleteOwner(id: $id)
    }
  }
`;

export {
  ownerFindAllQuery,
  checkOwnerCodeExistsQuery,
  createOwnerMutation,
  getOwnerByIdQuery,
  updateOwnerMutation,
  deleteOwnerMutation,
  ownerFragment,
};

import * as React from "react";
import {
  FilterOperand,
  FilterCondition,
  OperandType,
  stringifyFilterValues,
  getLabelForOperator,
  getOperatorsForOperandType,
  FilterValue,
  FilterOperator,
  getDefaultOperatorForOperandType
} from "./CsSearchBox";
import TextListEditor from "./TextListEditor";
import TextEditor from "./TextEditor";
import Box from '@mui/material/Box';
import update from "immutability-helper";
import NumberEditor from "./NumberEditor";
import NumberListEditor from "./NumberListEditor";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Chip from "@mui/material/Chip/Chip";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
export type SingleOperandFilterCondition = {
  operand: FilterOperand;
  conditions: ReadonlyArray<FilterCondition>;
};

const _sx = {
  chips: {
    marginTop: .5,
    marginBottom: .5
  },
  chip: {
    margin: .5
  },
  operatorIconImg: {
    width: 24,
    height: 24
  },
  operatorInChip: {
    fontStyle: "italic",
    marginRight: 1
  },
  paper: {
    overflow: "hidden",
    width: 400
  },
  conditionEditor: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 1,
    paddingBottom: 1
  },
  formControl: {
    marginTop: 1,
    marginBottom: 1
  }
};

interface Props {
  filterCondition: SingleOperandFilterCondition;
  open: boolean;
  onAccept: (conditions: SingleOperandFilterCondition) => void;
  onReject: () => void;
}
type State = {
  editedConditions: ReadonlyArray<FilterCondition>;
  selectedConditionIndex: number;
  previousFilterConditionProp?: SingleOperandFilterCondition;
};
class CsSingleOperandFilterEditor extends React.Component<Props, State> {
  state: State = {
    editedConditions: [],
    selectedConditionIndex: 0
  };

  static getDerivedStateFromProps(nextProps: Props, previousState: State) {
    const { previousFilterConditionProp } = previousState;
    const { filterCondition } = nextProps;
    if (!filterCondition) {
      return {
        editedConditions: new Array<FilterCondition>()
      };
    } else if (previousFilterConditionProp !== filterCondition) {
      const { conditions } = filterCondition;
      return {
        editedConditions: conditions,
        previousFilterConditionProp: filterCondition
      };
    } else {
      return null;
    }
  }

  renderNumberRangeValueEditor(value: [number?, number?]) {
    const [from = undefined, to = undefined] = value
      ? value
      : [undefined, undefined];
    return (
      <React.Fragment>
        <NumberEditor
          label="From"
          sx={_sx.formControl}
          value={from}
          onValidated={v => {
            if (v && to)
              this.updateFilterValue([v, to]);
          }}
          error={from === null || from === undefined}
          helperText={
            from === null || from === undefined ? "Enter number." : ""
          }
        />
        <NumberEditor
          label="To"
          sx={_sx.formControl}
          value={from}
          onValidated={v => {
            if (v && from)
              this.updateFilterValue([from, v]);
          }}
          error={to === null || to === undefined}
          helperText={to === null || to === undefined ? "Enter number." : ""}
        />
      </React.Fragment>
    );
  }

  renderTextRangeValueEditor(value: ReadonlyArray<string>) {
    const [from = "", to = ""] = value ? value : ["", ""];
    return (
      <React.Fragment>
        <TextEditor
          label="From"
          sx={_sx.formControl}
          value={from}
          onValidated={v => {
            this.updateFilterValue([v, to]);
          }}
          error={!from}
          helperText={!from ? "Enter text." : ""}
        />
        <TextEditor
          label="To"
          sx={_sx.formControl}
          value={to}
          onValidated={v => {
            this.updateFilterValue([from, v]);
          }}
          error={!to}
          helperText={!to ? "Enter text." : ""}
        />
      </React.Fragment>
    );
  }

  renderTextListValueEditor(value: ReadonlyArray<string>) {
    return (
      <TextListEditor
        value={value ? value : []}
        onChanged={v => {
          this.updateFilterValue(v);
        }}
      />
    );
  }

  renderNumberListValueEditor(value: ReadonlyArray<number>) {
    return (
      <NumberListEditor
        value={value ? value : []}
        onChanged={v => {
          this.updateFilterValue(v);
        }}
      />
    );
  }

  renderSingleNumberValueEditor(value: number) {
    return (
      <NumberEditor
        label="Filter value"
        sx={_sx.formControl}
        value={value}
        onValidated={v => {
          if (v)
            this.updateFilterValue(v);
        }}
        error={value === null || value === undefined}
        helperText={
          value === null || value === undefined ? "Enter number." : ""
        }
      />
    );
  }

  renderSingleTextValueEditor(value: string) {
    return (
      <TextEditor
        label="Filter value"
        sx={_sx.formControl}
        value={value}
        onValidated={v => {
          this.updateFilterValue(v);
        }}
        error={!value}
        helperText={!value ? "Enter text." : ""}
      />
    );
  }
  updateFilterValue(value: FilterValue) {
    const { selectedConditionIndex, editedConditions } = this.state;
    const newEditedConditions = update(editedConditions, {
      [selectedConditionIndex]: {
        value: {
          $set: value
        }
      }
    });
    this.setState({ editedConditions: newEditedConditions });
  }

  validateEditingCondition() {
    const {
      filterCondition: { operand }
    } = this.props;
    const {
      selectedConditionIndex = 0,
      editedConditions: conditions
    } = this.state;
    const selectedCondition = conditions[selectedConditionIndex];

    if (!selectedCondition) return true;
    const { operator, value } = selectedCondition;
    switch (operator) {
      case FilterOperator.CONTAINS:
      case FilterOperator.END_WITH:
      case FilterOperator.EQUAL:
      case FilterOperator.GREATER_THAN:
      case FilterOperator.GREATER_THAN_EQUAL:
      case FilterOperator.LESS_THAN:
      case FilterOperator.LESS_THAN_EQUAL:
      case FilterOperator.NOT_CONTAIN:
      case FilterOperator.NOT_END_WITH:
      case FilterOperator.NOT_EQUAL:
      case FilterOperator.NOT_START_WITH:
      case FilterOperator.STARTS_WITH:
        switch (operand.type) {
          case OperandType.STRING:
            return !!value;
          case OperandType.NUMBER:
            return value !== undefined && value !== null;
          default:
            return true;
        }
      case FilterOperator.BETWEEN:
      case FilterOperator.NOT_BETWEEN:
        switch (operand.type) {
          case OperandType.STRING:
            const [fromText, toText] = value as ReadonlyArray<string>;
            return fromText && toText;
          case OperandType.NUMBER:
            const [fromNum, toNum] = value as ReadonlyArray<number>;
            return (
              fromNum !== null &&
              fromNum !== undefined &&
              toNum !== null &&
              toNum !== null
            );
          default:
            return true;
        }
      default:
        return true;
    }
  }

  renderFilterValueEditor() {
    const {
      filterCondition: { operand }
    } = this.props;
    const { editedConditions: conditions } = this.state;
    const { selectedConditionIndex = 0 } = this.state;
    const selectedCondition = conditions[selectedConditionIndex];
    const { operator, value } = selectedCondition;
    if (!selectedCondition) return null;
    switch (operator) {
      case FilterOperator.CONTAINS:
      case FilterOperator.END_WITH:
      case FilterOperator.EQUAL:
      case FilterOperator.GREATER_THAN:
      case FilterOperator.GREATER_THAN_EQUAL:
      case FilterOperator.LESS_THAN:
      case FilterOperator.LESS_THAN_EQUAL:
      case FilterOperator.NOT_CONTAIN:
      case FilterOperator.NOT_END_WITH:
      case FilterOperator.NOT_EQUAL:
      case FilterOperator.NOT_START_WITH:
      case FilterOperator.STARTS_WITH:
        switch (operand.type) {
          case OperandType.STRING:
            return this.renderSingleTextValueEditor(value as string);
          case OperandType.NUMBER:
            return this.renderSingleNumberValueEditor(value as number);
          default:
            return null;
        }
      case FilterOperator.BETWEEN:
      case FilterOperator.NOT_BETWEEN:
        switch (operand.type) {
          case OperandType.STRING:
            return this.renderTextRangeValueEditor(value as ReadonlyArray<
              string
            >);
          case OperandType.NUMBER:
            return this.renderNumberRangeValueEditor(value as [number?, number?]);
        }
        break;
      case FilterOperator.IN:
      case FilterOperator.NOT_IN:
        switch (operand.type) {
          case OperandType.STRING:
            return this.renderTextListValueEditor(value as ReadonlyArray<
              string
            >);
          case OperandType.NUMBER:
            return this.renderNumberListValueEditor(value as ReadonlyArray<
              number
            >);
        }
        break;
      default:
    }
  }
  createNewCondition() {
    if (!this.validateEditingCondition()) return;
    const {
      filterCondition: { operand }
    } = this.props;
    const { editedConditions } = this.state;
    const newCondition: FilterCondition = {
      operand,
      operator: getDefaultOperatorForOperandType(operand.type)
    };
    const newEditedConditions = [...editedConditions, newCondition];
    this.setState({
      editedConditions: newEditedConditions,
      selectedConditionIndex: newEditedConditions.indexOf(newCondition)
    });
  }
  render() {
    const {
      filterCondition,
      open,
      onReject,
      onAccept
    } = this.props;
    if (!filterCondition) return null;
    const { operand } = filterCondition;
    const {
      editedConditions: conditions,
      selectedConditionIndex = 0
    } = this.state;
    const selectedCondition = conditions[selectedConditionIndex];
    const operators = getOperatorsForOperandType(operand.type);
    return (
      <Dialog
        open={open}
        aria-labelledby={`Filter by "${operand.name}"`}
        PaperProps={{ sx: _sx.paper }}
      >
        <DialogTitle>{`Filter by "${operand.name}"`}</DialogTitle>
        <DialogContent>
          <Box sx={_sx.chips}>
            {conditions.map((condition, index) => {
              const shortLabel = getLabelForOperator(
                condition.operator
              )?.shortLabel;
              return (
                <Chip
                  sx={_sx.chip}
                  color={
                    selectedConditionIndex === index ? "primary" : "default"
                  }
                  key={index.toString()}
                  label={
                    <React.Fragment>
                      <Typography
                        sx={_sx.operatorInChip}
                        variant="body2"
                      >
                        {shortLabel}
                      </Typography>
                      <Typography variant="body2">
                        {stringifyFilterValues(condition)}
                      </Typography>
                    </React.Fragment>
                  }
                  size="small"
                  onDelete={() => {
                    if (conditions.length === 1) return;
                    const newConditions = update(conditions, {
                      $splice: [[index, 1]]
                    });
                    let newIndex = newConditions.indexOf(selectedCondition);
                    if (newIndex === -1) newIndex = newConditions.length - 1;
                    this.setState({
                      editedConditions: newConditions,
                      selectedConditionIndex: newIndex
                    });
                  }}
                  onClick={() => {
                    if (this.validateEditingCondition())
                      this.setState({ selectedConditionIndex: index });
                  }}
                />
              );
            })}
          </Box>
          <Divider />
          <Box sx={_sx.conditionEditor}>
            <FormControl sx={_sx.formControl}>
              <InputLabel htmlFor="operator-select">Filter Operator</InputLabel>
              <Select
                value={selectedCondition.operator}
                onChange={e => {
                  const newConditions = update(conditions, {
                    [selectedConditionIndex]: {
                      operator: {
                        $set: e.target.value as FilterOperator
                      },
                      value: {
                        $set: undefined
                      }
                    }
                  });
                  this.setState({ editedConditions: newConditions });
                }}
                inputProps={{
                  name: "operator",
                  id: "operator-select"
                }}
              >
                {operators.map(operator => {
                  const label = getLabelForOperator(
                    operator,
                  )?.label;
                  return (
                    <MenuItem key={operator.toString()} value={operator}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {this.renderFilterValueEditor()}
          </Box>
          <DialogActions>
            <Button
              onClick={this.createNewCondition.bind(this)}
              color="primary"
            >
              New Condition
            </Button>
            <Button onClick={onReject} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (this.validateEditingCondition())
                  onAccept({
                    operand,
                    conditions
                  });
              }}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default CsSingleOperandFilterEditor;

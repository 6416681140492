import * as React from "react";
import update from "immutability-helper";
import { propertyTypeOptions } from "./propertyTypeOptions";
import { propertyStatusOptions } from "./propertyStatusOptions";
import { landTypeOptions } from "./landTypeOptions";
import { pricingMethodOptions } from "./pricingMethodOptions";
import Close from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import FileCopy from "@mui/icons-material/FileCopy";
import Add from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import PropertyGrid from "./PropertyGrid";
import { deletePropertyMutation, propertyFindAllQuery } from "./graphql";
import { propertyActiveStatusOptions } from "./propertyActiveStatusOptions";
import Grid from "@mui/material/Grid/Grid";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Box from "@mui/material/Box/Box";
import DuplicatePropertyDialog from "./DuplicatePropertyDialog";
import { DeleteProperty, DeletePropertyVariables } from "./__generated__/DeleteProperty";
import { PropertyFindAll, PropertyFindAllVariables } from "./__generated__/PropertyFindAll";
import { GetPropertyById_propertyQuery_property } from "./__generated__/GetPropertyById";
import { useMutation } from "@apollo/client/react/hooks";
import DrawerToggleButton from "../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import { Outlet, useMatch, useNavigate, useOutletContext, useParams } from "react-router-dom";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import ConfirmDialog from "../../app/ConfirmDialog";
const _sx = {
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
};

export type PropertySaveCallback = () => Promise<GetPropertyById_propertyQuery_property | null>;

export type PropertyOutletContextType = {
  registerSaveEvent: null | ((e: PropertySaveCallback) => void),
  fetchAllVariables?: PropertyFindAllVariables
}
export function usePropertyOutletContext() {
  return useOutletContext<PropertyOutletContextType>();
}

function PropertyBrowser() {
  const { id: propertyId } = useParams<{ id: string }>();
  const [variables, setVariables] = React.useState<
    PropertyFindAllVariables
  >({ pagination: { pageSize: 30, page: 1 } });
  const saveEvent = React.useRef<PropertySaveCallback | null>(null);
  const registerSaveEvent = React.useCallback<((callback: PropertySaveCallback) => void)>((callback) => {
    saveEvent.current = callback;
  }, []);
  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(
    false
  );
  const [deleteProperty] = useMutation<
    DeleteProperty,
    DeletePropertyVariables
  >(deletePropertyMutation, {
    update: (cache, { data }) => {
      if (data?.propertyMutation?.deleteProperty) {
        const old = cache.readQuery<
          PropertyFindAll,
          PropertyFindAllVariables
        >({ query: propertyFindAllQuery, variables });
        const index = old?.propertyQuery?.properties?.edges?.findIndex(
          (property) => property.id === propertyId
        ) ?? -1;
        if (index > -1)
          cache.writeQuery<PropertyFindAll, PropertyFindAllVariables>(
            {
              query: propertyFindAllQuery,
              variables: variables,
              data: update(old!, {
                propertyQuery: {
                  properties: {
                    edges: {
                      $splice: [[index, 1]],
                    },
                    pageInfo: {
                      rowCount: {
                        $set: old?.propertyQuery?.properties?.pageInfo?.rowCount ?? 1 - 1,
                      },
                    },
                  },
                },
              }),
            }
          );
      }
    },
  });
  const navigate = useNavigate();
  const [openDuplicatePropertyDialog, setOpenDuplicatePropertyDialog] = React.useState(false);
  const handleDuplicatePropertyDialogClose = React.useCallback((newId?: string) => {
    setOpenDuplicatePropertyDialog(false);
    if (newId) {
      navigate(`/properties/${newId}/edit`);
    }
  }, [navigate]);
  const isCreateRoute = useMatch("/properties/create");
  return (
    <>
      <Grid container direction="column">
        <AppBar position="static" >
          <Toolbar sx={theme => ({
            [theme.breakpoints.up("md")]: {
              minHeight: 48,
            },
            paddingLeft: 12,
          })}>
            <DrawerToggleButton />
            <Typography
              variant="h6"
              sx={_sx.title}
              color="inherit"
              noWrap
            >
              Properties
            </Typography>
            <CsSearchBox
              onConditionChanged={(conditions: any) => {
                setVariables(
                  update(variables, {
                    where: {
                      $set: { aND: conditions },
                    },
                  })
                );
              }}
              popperSx={theme => ({
                marginTop: 2,
                [theme.breakpoints.up("md")]: {
                  marginTop: 1,
                },
              })}
              operands={{
                propertyCode: {
                  name: "Code",
                  propName: "propertyCode",
                  type: OperandType.STRING,
                },
                owner_Name: {
                  name: "Owner Name",
                  propName: "owner_Name",
                  type: OperandType.STRING,
                },
                owner_Code: {
                  name: "Owner Code",
                  propName: "owner_Name",
                  type: OperandType.STRING,
                },
                about: {
                  name: "About",
                  propName: "about",
                  type: OperandType.STRING
                },
                propertyType: {
                  name: "Property Type",
                  propName: "propertyType",
                  type: OperandType.ENUM,
                  enumOptions: propertyTypeOptions,
                },
                propertyStatus: {
                  name: "Status",
                  propName: "propertyStatus",
                  type: OperandType.ENUM,
                  enumOptions: propertyStatusOptions,
                },
                landType: {
                  name: "Land Type",
                  propName: "landType",
                  type: OperandType.ENUM,
                  enumOptions: landTypeOptions,
                },
                activeStatus: {
                  name: "Active",
                  propName: "activeStatus",
                  type: OperandType.ENUM,
                  enumOptions: propertyActiveStatusOptions
                },
                price: {
                  name: "Price",
                  propName: "price",
                  type: OperandType.NUMBER,
                },
                area: {
                  name: "Area",
                  propName: "area",
                  type: OperandType.NUMBER,
                },
                width: {
                  name: "Width",
                  propName: "width",
                  type: OperandType.NUMBER,
                },
                height: {
                  name: "Height",
                  propName: "height",
                  type: OperandType.NUMBER,
                },
                mBRoom: {
                  name: "MB Room",
                  propName: "mBRoom",
                  type: OperandType.NUMBER,
                },
                sBRoom: {
                  name: "SB Room",
                  propName: "sBRoom",
                  type: OperandType.NUMBER,
                },
                floor: {
                  name: "Floor",
                  propName: "floor",
                  type: OperandType.NUMBER,
                },
                city_Name: {
                  name: "City",
                  propName: "city_Name",
                  type: OperandType.STRING,
                },
                address: {
                  name: "Address",
                  propName: "address",
                  type: OperandType.STRING,
                },
                pricingMethod: {
                  name: "Pricing",
                  propName: "pricingMethod",
                  type: OperandType.ENUM,
                  enumOptions: pricingMethodOptions,
                },
                elevator: {
                  name: "Elevator",
                  propName: "elevator",
                  type: OperandType.BOOLEAN,
                },
                swimmingPool: {
                  name: "Swimming Pool",
                  propName: "swimmingPool",
                  type: OperandType.BOOLEAN,
                },
                electricAndWaterMeter: {
                  name: "Electric and Water",
                  propName: "electricAndWaterMeter",
                  type: OperandType.BOOLEAN,
                },
                homeLoanAvailable: {
                  name: "Loan Available",
                  propName: "homeLoanAvailable",
                  type: OperandType.BOOLEAN,
                },
              }}
            />
            {
              !isCreateRoute && !propertyId ? <Tooltip title="New Property">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    navigate("/properties/create");
                  }}
                >
                  <Add />
                </IconButton>
              </Tooltip> : null
            }
            {
              isCreateRoute ? (<>
                <Tooltip title="Cancel property creation.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/properties");
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save property.">
                  <IconButton
                    color="inherit"
                    onClick={async () => {
                      if (saveEvent.current) {
                        const saved = await saveEvent.current();
                        if (saved)
                          navigate(`/properties/${saved.id}/edit`, { replace: true });
                      }
                    }}
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
              </>) : null
            }
            {
              propertyId ? <>
                <Tooltip title="Close property edit view.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate("/properties");
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save property.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      if (saveEvent.current) {
                        saveEvent.current();
                      }
                    }}
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Duplicate property.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setOpenDuplicatePropertyDialog(true);
                    }}
                  >
                    <FileCopy />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete property.">
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setDeleteConfirmDialogOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </> : null
            }
          </Toolbar>
        </AppBar>
        <Box
          sx={_sx.box}
          flex={1}
          display="flex"
          flexDirection="row"
        >
          <div
            style={{
              minWidth: 300,
              flex:
                propertyId || isCreateRoute
                  ? 0
                  : 1,
              display: "flex",
            }}
          >
            <PropertyGrid
              fetchAllVariables={variables}
              onVariableChange={setVariables}
            />
          </div>
          <Outlet context={{ registerSaveEvent, fetchAllVariables: variables }} />
        </Box>
      </Grid>
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          title={`Error`}
          message={operationErrorMessage}
          detail={operationError.message}
        />
      ) : null}

      <ConfirmDialog
        open={deleteConfirmDialogOpen && Boolean(propertyId)}
        title="Delete property"
        message={`Are you sure to delete Property?`}
        rejectLabel="No"
        acceptLabel="Yes"
        onRejected={() => {
          setDeleteConfirmDialogOpen(false);
        }}
        onAccepted={async () => {
          await deleteProperty({
            variables: { id: propertyId! },
          });
          setDeleteConfirmDialogOpen(false);
          navigate("/properties");
        }}
      />
      <DuplicatePropertyDialog open={openDuplicatePropertyDialog && Boolean(propertyId)}
        sourceId={propertyId!}
        fetchAllVariables={variables}
        onClose={handleDuplicatePropertyDialogClose}
      />
    </>
  );
}


export default PropertyBrowser;
import * as React from "react";
import UserGrid from "./UserGrid";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Check from "@mui/icons-material/Check";
import Lock from "@mui/icons-material/Lock";
import { userRoleOptions } from "./userRoleOptions";
import RegisterUserDialog from "./RegisterUserDialog";
import UpdateUserDialog from "./UpdateUserDialog";
import {
  deleteUserMutation,
  userFindAllQuery,
  activateUserMutation,
  deactivateUserMutation,
} from "./graphql";
import update from "immutability-helper";
import ResetPasswordDialog from "./ResetPasswordDialog";
import Grid from "@mui/material/Grid/Grid";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Box from "@mui/material/Box/Box";
import { ActivateUser, ActivateUserVariables } from "./__generated__/ActivateUser";
import { DeactivateUser, DeactivateUserVariables } from "./__generated__/DeactivateUser";
import { DeleteUser, DeleteUserVariables } from "./__generated__/DeleteUser";
import { UserFindAll, UserFindAllVariables, UserFindAll_userQuery_users_edges } from "./__generated__/UserFindAll";
import { useMutation } from "@apollo/client";
import ConfirmDialog from "../../app/ConfirmDialog";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import DrawerToggleButton from "../../app/DrawerToggleButton";
const _sx = {
  appBar: {},
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
};

function UserBrowser() {
  const [openRegisterUserDialog, setOpenRegisterUserDialog] = React.useState(
    false
  );

  const [openUpdateUserDialog, setOpenUpdateUserDialog] = React.useState(false);
  const [variables, setVariables] = React.useState<UserFindAllVariables>({
    pagination: { pageSize: 30, page: 1 },
  });
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(
    false
  );
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = React.useState(
    false
  );
  const [selectedUser, setSelectedUser] = React.useState<UserFindAll_userQuery_users_edges | null>(null);

  const [activateUser] = useMutation<
    ActivateUser,
    ActivateUserVariables
  >(activateUserMutation);
  const [deactivateUser] = useMutation<
    DeactivateUser,
    DeactivateUserVariables
  >(deactivateUserMutation);

  const [deleteUser] = useMutation<
    DeleteUser,
    DeleteUserVariables
  >(deleteUserMutation, {
    update: (cache, { data }) => {
      if (data?.userMutation?.deleteUser) {
        const old = cache.readQuery<
          UserFindAll,
          UserFindAllVariables
        >({ query: userFindAllQuery, variables });
        if (!old)
          return;
        const index = old?.userQuery?.users?.edges?.indexOf(selectedUser!) ?? -1;
        if (index > -1)
          cache.writeQuery<UserFindAll, UserFindAllVariables>({
            query: userFindAllQuery,
            variables: variables,
            data: update(old, {
              userQuery: {
                users: {
                  edges: {
                    $splice: [[index, 1]],
                  },
                  pageInfo: {
                    rowCount: {
                      $set: old?.userQuery?.users?.pageInfo?.rowCount ?? 1 - 1,
                    },
                  },
                },
              },
            }),
          });
      }
    },
  });

  return (
    <Grid container direction="column">
      <AppBar position="static" sx={_sx.appBar}>
        <Toolbar sx={theme => ({
          [theme.breakpoints.up("md")]: {
            minHeight: 48,
          },
          paddingLeft: 1.5,
        })}>
          <DrawerToggleButton />
          <Typography
            variant="h6"
            sx={_sx.title}
            color="inherit"
            noWrap
          >
            Users
          </Typography>
          <CsSearchBox
            onConditionChanged={(conditions: any) => {
              setVariables({
                ...variables,
                where: { aND: conditions },
              });
            }}
            popperSx={theme => ({
              marginTop: 2,
              [theme.breakpoints.up("md")]: {
                marginTop: 1,
              },
            })}
            operands={{
              name: {
                name: "User Name",
                propName: "name",
                type: OperandType.STRING,
              },
              fullName: {
                name: "Full Name",
                propName: "fullName",
                type: OperandType.STRING,
              },
              active: {
                name: "Status",
                propName: "active",
                type: OperandType.BOOLEAN,
              },
              userRole: {
                name: "User Role",
                propName: "status",
                type: OperandType.ENUM,
                enumOptions: userRoleOptions,
              },
              createdAt: {
                name: "Created At",
                propName: "createdAt",
                type: OperandType.DATETIME,
              },
            }}
          />
          <Tooltip title="Create New">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpenRegisterUserDialog(true);
              }}
            >
              <PersonAdd />
            </IconButton>
          </Tooltip>
          {selectedUser && !selectedUser.defaultAdmin ? (
            <Tooltip title="Edit">
              <IconButton
                color="inherit"
                onClick={() => {
                  setOpenUpdateUserDialog(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          ) : null}
          {selectedUser && !selectedUser.defaultAdmin ? (
            <Tooltip title="Delete">
              <IconButton
                color="inherit"
                onClick={() => {
                  setDeleteConfirmDialogOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          ) : null}
          {selectedUser &&
            !selectedUser.defaultAdmin &&
            !selectedUser.active ? (
            <Tooltip title="Activate user">
              <IconButton
                color="inherit"
                onClick={() => {
                  activateUser({ variables: { id: selectedUser.id! } });
                }}
              >
                <Check />
              </IconButton>
            </Tooltip>
          ) : null}
          {selectedUser && !selectedUser.defaultAdmin && selectedUser.active ? (
            <Tooltip title="Deactivate user">
              <IconButton
                color="inherit"
                onClick={() => {
                  deactivateUser({ variables: { id: selectedUser.id! } });
                }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          ) : null}

          {selectedUser ? (
            <Tooltip title="Reset password">
              <IconButton
                color="inherit"
                onClick={() => {
                  setResetPasswordDialogOpen(true);
                }}
              >
                <Lock />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
      </AppBar>
      <Box sx={_sx.box} flex={1} display="flex">
        <UserGrid
          setSelectedItem={setSelectedUser}
          fetchAllVariables={variables}
          onVariableChange={setVariables}
        />
      </Box>
      <RegisterUserDialog
        fetchAllVariables={variables}
        open={openRegisterUserDialog}
        onClose={() => {
          setOpenRegisterUserDialog(false);
        }}
      />
      <ResetPasswordDialog
        edit={selectedUser!}
        open={resetPasswordDialogOpen && Boolean(!selectedUser)}
        onClose={() => {
          setResetPasswordDialogOpen(false);
        }}
      />
      {selectedUser?.id ? (
        <UpdateUserDialog
          open={openUpdateUserDialog}
          onClose={() => {
            setOpenUpdateUserDialog(false);
          }}
          edit={{ ...selectedUser, id: selectedUser.id! }}
        />
      ) : null}
      <ConfirmDialog
        open={deleteConfirmDialogOpen && Boolean(selectedUser?.id)}
        title="Delete User Account"
        message={`Are you sure to delete User Account?`}
        rejectLabel="No"
        acceptLabel="Yes"
        onRejected={() => {
          setDeleteConfirmDialogOpen(false);
        }}
        onAccepted={async () => {
          await deleteUser({
            variables: { id: selectedUser?.id! },
          });
          setDeleteConfirmDialogOpen(false);
        }}
      />
    </Grid>
  );
}
export default UserBrowser;

import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./app/Layout";
import Login from "./app/Login";
import { AppDispatch, RootState } from "./app/store";
import { pingAsync } from "./features/site/siteSlice";
import { Navigate, Route, Routes } from "react-router-dom";
import RegionBrowser from "./city/RegionBrowser";
import CityBrowser from "./city/CityBrowser";
import UserBrowser from "./features/user";
import OwnerBrowser from "./features/owner";
import PropertyBrowser from "./features/property";
import PropertyCreator from "./features/property/PropertyCreator";
import PropertyEditor from "./features/property/PropertyEditor";

function Ping() {
  const { userSession, pingStatus, pingMessage } = useSelector((state: RootState) => state.site);
  const dispatch: AppDispatch = useDispatch();
  const ping = React.useCallback(() => {
    dispatch(pingAsync());
  }, [dispatch]);
  React.useEffect(() => {
    ping();
  }, [ping]);
  if (pingStatus === "error") {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Box
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          display="flex"
        >
          <Typography padding={2}>{pingMessage}</Typography>
          <Button
            color="primary"
            onClick={() => {
              ping();
            }}
          >
            Try Again
          </Button>
        </Box>
      </Box>
    );
  } else if (pingStatus === "loading") {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Box
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          display="flex"
        >
          <CircularProgress />
          <Typography padding={2}>Loading ....</Typography>
        </Box>
      </Box>
    );
  } else if (
    userSession &&
    userSession.authenticated &&
    userSession.validTo &&
    new Date(userSession.validTo).getTime() > new Date().getTime() &&
    userSession.authenticated
  ) {
    return (<Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<PropertyBrowser/>} />
        <Route path="regions" element={<RegionBrowser />} />
        <Route path="regions/:id" element={<RegionBrowser />} />
        <Route path="cities" element={<CityBrowser />} />
        <Route path="cities/:id" element={<CityBrowser />} />
        <Route path="users" element={<UserBrowser />} />
        <Route path="owners" element={<OwnerBrowser />} />
        <Route path="owners/:id" element={<OwnerBrowser />} />
        <Route path="properties" element={<PropertyBrowser />}>
          <Route path="create"  element={<PropertyCreator/>} />
          <Route path=":id/edit"  element={<PropertyEditor/>} />
          <Route path=":id"  element={<Navigate to=":id/edit" replace/>} />
        </Route>
      </Route>
    </Routes>)
  } else {
    return <Login />
  }
}

export default Ping;
import * as React from "react";
import { ownerTypeOptions } from "./ownerTypeOptions";
import OwnerGrid from "./OwnerGrid";
import update from "immutability-helper";
import OwnerCreateDialog from "./OwnerCreateDialog";
import Profile from "./profile";
import OwnerUpdateDialog from "./OwnerUpdateDialog";
import { deleteOwnerMutation, ownerFindAllQuery } from "./graphql";
import Grid from "@mui/material/Grid/Grid";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Box from "@mui/material/Box/Box";
import { OwnerFindAll, OwnerFindAllVariables } from "./__generated__/OwnerFindAll";
import { DeleteOwner, DeleteOwnerVariables } from "./__generated__/DeleteOwner";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import DrawerToggleButton from "../../app/DrawerToggleButton";
import CsSearchBox, { OperandType } from "../../components/CsSearchBox";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import ConfirmDialog from "../../app/ConfirmDialog";
const _sx = {
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
};
function OwnerBrowser() {
  const { id: ownerId } = useParams<{ id: string }>();
  const [variables, setVariables] = React.useState<OwnerFindAllVariables>({
    pagination: { pageSize: 30, page: 1 },
  });
  const [createOwnerDialogOpen, setCreateOwnerDialogOpen] = React.useState(
    false
  );
  const [updateOwnerDialogOpen, setUpdateOwnerDialogOpen] = React.useState(
    false
  );


  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(
    false
  );

  const [deleteOwner] = useMutation<
    DeleteOwner,
    DeleteOwnerVariables
  >(deleteOwnerMutation, {
    update: (cache, { data }) => {
      if (data?.ownerMutation?.deleteOwner) {
        const old = cache.readQuery<
          OwnerFindAll,
          OwnerFindAllVariables
        >({ query: ownerFindAllQuery, variables });
        if (!old)
          return;
        const index = old?.ownerQuery?.owners?.edges?.findIndex(owner => owner.id === ownerId) ?? -1
        if (index > -1)
          cache.writeQuery<OwnerFindAll, OwnerFindAllVariables>({
            query: ownerFindAllQuery,
            variables: variables,
            data: update(old, {
              ownerQuery: {
                owners: {
                  edges: {
                    $splice: [[index, 1]],
                  },
                  pageInfo: {
                    rowCount: {
                      $set: old?.ownerQuery?.owners?.pageInfo?.rowCount ?? 1 - 1,
                    },
                  },
                },
              },
            }),
          });
      }
    },
  });

  const navigate = useNavigate();

  return (
    <>
      <Grid container direction="column">
        <AppBar position="static">
          <Toolbar sx={theme => ({
            [theme.breakpoints.up("md")]: {
              minHeight: 48,
            },
            paddingLeft: 1.5,
          })}>
            <DrawerToggleButton />
            <Typography
              variant="h6"
              sx={{ marginRight: 1 }}
              color="inherit"
              noWrap
            >
              Owners
            </Typography>
            <CsSearchBox
              onConditionChanged={(conditions: any) => {
                setVariables(
                  update(variables, {
                    where: {
                      $set: { aND: conditions },
                    },
                  })
                );
              }}
              popperSx={theme => ({
                marginTop: 2,
                [theme.breakpoints.up("md")]: {
                  marginTop: 1,
                },
              })}
              operands={{
                code: {
                  name: "Code",
                  propName: "code",
                  type: OperandType.STRING,
                },
                name: {
                  name: "Name",
                  propName: "name",
                  type: OperandType.STRING,
                },

                ownerType: {
                  name: "Owner Type",
                  propName: "ownerType",
                  type: OperandType.ENUM,
                  enumOptions: ownerTypeOptions,
                },
                contactPerson: {
                  name: "Contact",
                  propName: "contactPerson",
                  type: OperandType.STRING,
                },
                contactNumber: {
                  name: "Phone No",
                  propName: "contactNumber",
                  type: OperandType.STRING,
                },
                address: {
                  name: "Address",
                  propName: "address",
                  type: OperandType.STRING
                },
              }}
            />
            {
              ownerId ? (
                <>
                  <Tooltip title="Close owner detail view.">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        navigate("/owners", { replace: true });
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit owner info.">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        setUpdateOwnerDialogOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete owner.">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        setDeleteConfirmDialogOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null
            }
            <Tooltip title="New Owner">
              <IconButton
                color="inherit"
                onClick={() => {
                  setCreateOwnerDialogOpen(true);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box
          sx={_sx.box}
          flex={1}
          display="flex"
          flexDirection="row"
        >
          <div
            style={{ minWidth: 300, flex: ownerId ? 0 : 1, display: "flex" }}
          >
            <OwnerGrid
              fetchAllVariables={variables}
              onVariableChange={setVariables}
            />
          </div>
          {ownerId ? <Profile /> : null}
        </Box>
      </Grid>
      <OwnerCreateDialog
        fetchAllVariables={variables}
        open={createOwnerDialogOpen}
        onClose={() => {
          setCreateOwnerDialogOpen(false);
        }}
        onError={(error) => {
          setOperationError(error);
          setOperationErrorMessage("Could not create owner.");
          setErrorMessageDialogOpen(true);
        }}
      />
      <OwnerUpdateDialog
        open={updateOwnerDialogOpen && Boolean(ownerId)}
        onClose={() => {
          setUpdateOwnerDialogOpen(false);
        }}
        onError={(error) => {
          setOperationError(error);
          setOperationErrorMessage("Could not update owner info.");
          setErrorMessageDialogOpen(true);
        }}
        id={ownerId!}
      />
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          title={`Error`}
          message={operationErrorMessage}
          detail={operationError.message}
        />
      ) : null}

      <ConfirmDialog
        open={deleteConfirmDialogOpen && Boolean(ownerId)}
        title="Delete Owner"
        message={`Are you sure to delete Owner?`}
        rejectLabel="No"
        acceptLabel="Yes"
        onRejected={() => {
          setDeleteConfirmDialogOpen(false);
        }}
        onAccepted={async () => {
          await deleteOwner({
            variables: { id: ownerId! },
          });
          setDeleteConfirmDialogOpen(false);
          navigate("/owners", { replace: true });
        }}
      />
    </>
  );
}

export default OwnerBrowser;

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { store } from "./store";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { onError } from "@apollo/client/link/error";
import { openSnackbar } from "../features/site/siteSlice";
import { OwnerFindAll_ownerQuery_owners } from "../features/owner/__generated__/OwnerFindAll";
import update from "immutability-helper";
import { PropertyFindAll_propertyQuery_properties } from "../features/property/__generated__/PropertyFindAll";
const httpLink = new HttpLink({
  uri: "/graphql",
  fetch: (input: RequestInfo, init?: RequestInit) => {
    return fetch(input, init).catch((error) => {
      if (error.code === 20 && error.name === "AbortError") {
        store.dispatch(hideLoading());
      } else if (error.code === 401) {
        store.dispatch(hideLoading());
      }
      throw error;
    });
  },
});
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem("token") || null}`,
    },
  }));

  return forward(operation);
});

const activityMiddleware = new ApolloLink((operation, forward) => {
  store.dispatch(showLoading());
  return forward(operation).map((response) => {
    store.dispatch(hideLoading());
    return response;
  });
});
const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    store.dispatch(
      openSnackbar({ message: "Can't connect to server. Check connection!" })
    );
  }
  store.dispatch(hideLoading());
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      OwnerQuery: {
        fields: {
          owners: {
            keyArgs: ["where", "orderBy"],
            merge: (
              existing: OwnerFindAll_ownerQuery_owners,
              incoming: OwnerFindAll_ownerQuery_owners
            ) => {
              if (!existing) return incoming;
              const merged = update(incoming, {
                edges: {
                  $set: [...existing.edges, ...incoming.edges],
                },
              });
              return merged;
            },
          },
        },
      },
      PropertyQuery: {
        fields: {
          properties: {
            keyArgs: ["where", "orderBy"],
            merge: (
              existing: PropertyFindAll_propertyQuery_properties,
              incoming: PropertyFindAll_propertyQuery_properties
            ) => {
              if (!existing) return incoming;
              const merged = update(incoming, {
                edges: {
                  $set: [...existing.edges, ...incoming.edges],
                },
              });
              return merged;
            },
          },
        },
      },
    },
  }),
  link: from([errorLink, authMiddleware, activityMiddleware, httpLink]),
});

export default apolloClient;

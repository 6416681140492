import * as React from "react";
import OwnerEditControl from "./OwnerEditControl";
import { getOwnerByIdQuery, updateOwnerMutation } from "./graphql";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { GetOwnerById, GetOwnerByIdVariables, GetOwnerById_ownerQuery_owner } from "./__generated__/GetOwnerById";
import { UpdateOwner, UpdateOwnerVariables } from "./__generated__/UpdateOwner";
import {OwnerType} from '../../types/global-types';
import { useMutation, useQuery } from "@apollo/client";
import DialogActionLoadingIndicator from "../../components/DialogActionLoadingIndicator";
export type OwnerUpdateDialogProps = {
  open: boolean;
  onClose: () => void;
  onError: (error: Error) => void;
  id: string;
};
const ownerDefaultValue: GetOwnerById_ownerQuery_owner = {
  code: "",
  name: "",
  ownerType: OwnerType.INDIVIDUAL,
  contactPerson: "",
  contactNumber: "",
  profilePhoto: "",
  coverPhoto: "",
  address: "",
  id: null,
  properties: null,
};
function OwnerUpdateDialog({
  open,
  onClose,
  onError,
  id,
}: OwnerUpdateDialogProps) {
  const { data: ownerQueryResult, loading } = useQuery<
    GetOwnerById,
    GetOwnerByIdVariables
  >(getOwnerByIdQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "network-only",
  });
  const [draft, setDraft] = React.useState<GetOwnerById_ownerQuery_owner>(ownerDefaultValue);
  React.useEffect(() => {
    if (
      ownerQueryResult &&
      ownerQueryResult.ownerQuery &&
      ownerQueryResult.ownerQuery.owner
    ) {
      setDraft({
        ...ownerQueryResult.ownerQuery.owner,
      });
      setError({});
    }
  }, [ownerQueryResult]);

  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [
    createOwner,
    { loading: updatingOwner, error: createError },
  ] = useMutation<UpdateOwner, UpdateOwnerVariables>(
    updateOwnerMutation
  );

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.code) {
      e.code = "";
    } else {
      valid = false;
      e.code = "Owner code is required";
    }
    if (draft.name) {
      e.name = "";
    } else {
      valid = false;
      e.name = "Name is required";
    }

    setError(e);
    return valid;
  }, [draft]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const {
        name,
        code,
        ownerType,
        contactPerson,
        contactNumber,
        address,
        profilePhoto,
        coverPhoto,
      } = draft;
      await createOwner({
        variables: {
          owner: {
            id,
            code,
            name,
            ownerType,
            contactPerson,
            contactNumber,
            address,
            profilePhoto,
            coverPhoto,
          },
        },
      });
      onClose();
    }
  }, [createOwner, draft, id, onClose, validate]);

  React.useEffect(() => {
    if (createError) onError(createError);
  }, [createError, onError]);
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Update Owner Info</DialogTitle>
      <DialogContent>
        <OwnerEditControl
          value={draft}
          setValue={setDraft}
          error={error}
          setError={setError}
        />
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={updatingOwner || loading}
          text={loading ? "Loading owner info..." : "Updating owner info..."}
        />
        <Button
          disabled={updatingOwner || loading}
          onClick={submit}
          color="secondary"
        >
          OK
        </Button>
        <Button disabled={updatingOwner || loading} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OwnerUpdateDialog;

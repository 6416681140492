import MenuItem from "@mui/material/MenuItem/MenuItem";
import TextField from "@mui/material/TextField/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import * as React from "react";
import { EnumSelectOptions } from "./EnumMultiSelectEditor";

type EnumSingleSelectEditorProps = TextFieldProps & {
  options: EnumSelectOptions;
  value: string;
};

function EnumSingleSelectEditor({
  select,
  options,
  value,
  ...props
}: EnumSingleSelectEditorProps) {
  const selectOptions = React.useMemo(() => {
    return Object.keys(options).map((o) => (
      <MenuItem key={options[o].value} value={options[o].value}>
        {options[o].text}
      </MenuItem>
    ));
  }, [options]);
  return (
    <TextField select value={value} {...props}>
      {selectOptions}
    </TextField>
  );
}

export default EnumSingleSelectEditor;

import Hidden from '@mui/material/Hidden';
import { IconButtonProps } from '@mui/material/IconButton';
import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react'
import { useDispatch } from 'react-redux';
import { toggleDrawer } from '../features/site/siteSlice';
import Menu from '@mui/icons-material/Menu';
import ToolbarIconButton from '../components/ToolbarIconButton'

function DrawerToggleButton(props: IconButtonProps) {
  const { onClick, ...p } = props;
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      toggleDrawer
    },
    dispatch
  );
  const handleToggleDrawer = React.useCallback(() => { actions.toggleDrawer() }, [actions]);
  return (
    <Hidden mdUp>
      <ToolbarIconButton {...p} onClick={handleToggleDrawer}>
        <Menu />
      </ToolbarIconButton>
    </Hidden>
  );
}

export default DrawerToggleButton
import * as React from "react";
import PropertyEditControl, { PropertyEdit } from "./PropertyEditControl";
import { AutoSizer, AutoSizerProps } from "react-virtualized";
import {
  savePropertyMutation,
  getPropertyByIdQuery,
} from "./graphql";
import GalleryView, { PhotosResultCallback } from "./GalleryView";
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import { GetPropertyById, GetPropertyByIdVariables } from "./__generated__/GetPropertyById";
import { LandType, PricingMethod, PropertyActiveStatus, PropertyStatus, PropertyType } from "../../types/global-types";
import { SaveProperty, SavePropertyVariables } from "./__generated__/SaveProperty";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import ActivityIndicatorDialog from "../../components/ActivityIndicatorDialog";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
import { useParams } from "react-router-dom";
import { usePropertyOutletContext } from ".";
const propertyDefaultValue: PropertyEdit = {
  id: "",
  propertyCode: "",
  owner: null,
  landType: LandType.FREEHOLD,
  propertyType: PropertyType.APARTMENT,
  propertyStatus: PropertyStatus.RENT,
  price: null,
  area: null,
  length: null,
  width: null,
  height: null,
  mBRoom: null,
  sBRoom: null,
  floor: null,
  city: null,
  address: "",
  pricingMethod: PricingMethod.GFA,
  elevator: false,
  swimmingPool: false,
  electricAndWaterMeter: false,
  homeLoanAvailable: false,
  propertyPhotos: [],
  activeStatus: PropertyActiveStatus.ACTIVE,
  about: "",
  sortOrder: null,
  createdAt: undefined,
  updatedAt: undefined
};


const AutoSizerFixed = AutoSizer as unknown as React.ElementType<AutoSizerProps>;



function PropertyEditor() {
  const { registerSaveEvent } = usePropertyOutletContext();
  const { id: propertyId } = useParams<{ id: string }>();
  const [draft, setDraft] = React.useState<
    PropertyEdit
  >(propertyDefaultValue);
  const [error, setError] = React.useState<{ [key: string]: string }>({});

  const {
    data: propertyByIdQueryResult,
    loading: gettingPropertyById,
  } = useQuery<GetPropertyById, GetPropertyByIdVariables>(
    getPropertyByIdQuery,
    {
      variables: {
        id: propertyId!,
      },
      skip: !propertyId,
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    const propertyById = propertyByIdQueryResult?.propertyQuery?.property;
    if (!propertyId || !propertyById) {
      setDraft(propertyDefaultValue);
    } else if (propertyById) {
      setDraft(propertyById);
    } else {
      setDraft(propertyDefaultValue);
    }
  }, [propertyId, propertyByIdQueryResult]);

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.propertyCode) {
      e.propertyCode = "";
    } else {
      valid = false;
      e.propertyCode = "Property code is required";
    }
    if (draft.owner) {
      e.owner = "";
    } else {
      valid = false;
      e.owner = "Choose owner.";
    }

    if (draft.city) {
      e.city = "";
    } else {
      valid = false;
      e.city = "Choose city.";
    }

    setError(e);
    return valid;
  }, [draft.city, draft.owner, draft.propertyCode, error]);

  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );

  const [
    updateProperty,
    { loading: updatingProperty, error: updateError },
  ] = useMutation<SaveProperty, SavePropertyVariables>(
    savePropertyMutation
  );

  const getPhotosResult = React.useRef<PhotosResultCallback | null>(null);

  React.useEffect(() => {
    if (updateError) {
      setOperationError(updateError);
      setOperationErrorMessage("Could not update property.");
      setErrorMessageDialogOpen(true);
    }
  }, [updateError]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const { owner, city, __typename, propertyPhotos, createdAt, updatedAt, ...propertyInput } = draft;
      const variables: SavePropertyVariables = {
        property: {
          ...propertyInput,
          ownerId: owner?.id!,
          cityId: city?.id!,
          propertyPhotos: getPhotosResult.current ? [...getPhotosResult.current()] : propertyPhotos
        },
      };
      const { data } = await updateProperty({
        variables,
      });
      return data?.propertyMutation?.saveProperty ?? null;
    } else return null;
  }, [draft, updateProperty, validate]);

  React.useEffect(() => {
    if (registerSaveEvent)
      registerSaveEvent(submit);
  }, [registerSaveEvent, submit]);
  if(!propertyId)
    return null;
  return (
    <Box flex={1}>
      <AutoSizerFixed disableWidth>
        {({ height }) => (
          <div style={{ height, overflowY: "scroll" }}>
            <Box flex={1} sx={{
              paddingTop: 2,
              paddingBottom: 2
            }}>
              <Container maxWidth="lg">
                <Box
                  flexDirection="row"
                  boxShadow={1}
                  p={2}
                  display="flex"
                  flexWrap="wrap"
                  className="bg"
                >
                  <PropertyEditControl
                    value={draft}
                    setValue={setDraft}
                    error={error}
                    setError={setError}
                  />
                  <GalleryView
                    propertyId={propertyId}
                    propertyPhotos={draft.propertyPhotos ?? []}
                    registerPhotoResultCallback={(callback) => {
                      getPhotosResult.current = callback;
                    }}
                    savePhotos={submit}
                  />
                </Box>
              </Container>
            </Box>
            <ActivityIndicatorDialog
              open={updatingProperty || gettingPropertyById}
              message={updatingProperty ? "Saving property" : "Loading..."}
            />
            <ErrorMessageDialog
              open={errorMessageDialogOpen}
              onClose={() => {
                setErrorMessageDialogOpen(false);
              }}
              title="Error!"
              message={operationErrorMessage}
              detail={operationError?.message ?? ""}
            />
          </div>
        )}
      </AutoSizerFixed>
    </Box>
  );
}

export default PropertyEditor;

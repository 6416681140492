import AppBar from "@mui/material/AppBar/AppBar";
import Grid from "@mui/material/Grid/Grid";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";
import CsSearchBox, { OperandType } from "../components/CsSearchBox";
import DrawerToggleButton from "../app/DrawerToggleButton";
import update from "immutability-helper";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton/IconButton";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box/Box";
import ConfirmDialog from "../components/ConfirmDialog";
import ErrorMessageDialog from "../components/ErrorMessageDialog";
import CityCreateDialog from "./CityCreateDialog";
import CityUpdateDialog from "./CityUpdateDialog";
import CityGrid from "./CityGrid";
import { deleteCityMutation, cityFindAllQuery } from "./graphql";
import { CityFindAll, CityFindAllVariables } from "./__generated__/CityFindAll";
import { DeleteCity, DeleteCityVariables } from "./__generated__/DeleteCity";
import { useMutation } from "@apollo/client";
const _sx = {
  appBar: {},
  title: {
    marginRight: 1,
  },
  box: {
    backgroundColor: "rgba(255,255,255,.1)",
  },
};

function CityBrowser() {
  const [variables, setVariables] = React.useState<CityFindAllVariables>(
    {
      pagination: {
        pageSize: 80,
      },
    }
  );
  const { id: cityId } = useParams();
  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );
  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");
  const [deleteCity] = useMutation<
    DeleteCity,
    DeleteCityVariables
  >(deleteCityMutation, {
    update: (cache, { data }) => {
      if (data?.cityMutation?.deleteCity) {
        const old = cache.readQuery<
          CityFindAll,
          CityFindAllVariables
        >({ query: cityFindAllQuery, variables });
        const index = old?.cityQuery?.cities?.edges?.findIndex(
          (city) => city.id === cityId
        ) ?? -1;
        if (index > -1 && old)
          cache.writeQuery<CityFindAll, CityFindAllVariables>({
            query: cityFindAllQuery,
            variables: variables,
            data: update(old, {
              cityQuery: {
                cities: {
                  edges: {
                    $splice: [[index, 1]],
                  },
                  pageInfo: {
                    rowCount: {
                      $set: old?.cityQuery?.cities?.pageInfo?.rowCount ?? 1 - 1,
                    },
                  },
                },
              },
            }),
          });
      }
    },
  });
  const navigate = useNavigate();
  return (
    <>
      <Grid container direction="column">
        <AppBar position="static">
          <Toolbar sx={theme => ({
            [theme.breakpoints.up("md")]: {
              minHeight: 48,
            },
            paddingLeft: 12,
          })}>
            <DrawerToggleButton />
            <Typography
              variant="h6"
              sx={_sx.title}
              color="inherit"
              noWrap
            >
              Citys
            </Typography>
            <CsSearchBox
              onConditionChanged={(conditions: any) => {
                setVariables(
                  update(variables, {
                    where: {
                      $set: { aND: conditions },
                    },
                  })
                );
              }}
              popperSx={theme => ({
                marginTop: 2,
                [theme.breakpoints.up("md")]: {
                  marginTop: 1,
                },
              })}
              operands={{
                "alias": {
                  name: "Alias",
                  propName: "alias",
                  type: OperandType.STRING,
                },
                "name": {
                  name: "Name",
                  propName: "name",
                  type: OperandType.STRING,
                },
              }}
            />
            <Tooltip title="Edit city info.">
              <IconButton
                color="inherit"
                onClick={() => {
                  setUpdateDialogOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete city.">
              <IconButton
                color="inherit"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="New City">
              <IconButton
                color="inherit"
                onClick={() => {
                  setCreateDialogOpen(true);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box
          sx={_sx.box}
          flex={1}
          display="flex"
          flexDirection="row"
        >
          <CityGrid
            fetchAllVariables={variables}
            onVariableChange={setVariables}
          />
        </Box>
      </Grid>
      <CityCreateDialog
        fetchAllVariables={variables}
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
        }}
        onError={(error) => {
          setOperationError(error);
          setOperationErrorMessage("Could not create city.");
          setErrorMessageDialogOpen(true);
        }}
      />
      {cityId ? <CityUpdateDialog
        open={updateDialogOpen}
        onClose={() => {
          setUpdateDialogOpen(false);
        }}
        onError={(error) => {
          setOperationError(error);
          setOperationErrorMessage("Could not update city info.");
          setErrorMessageDialogOpen(true);
        }}
        id={cityId}
      /> : null}
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          title={`Error`}
          message={operationErrorMessage}
          detail={operationError.message}
        />
      ) : null}

      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete City"
        message={`Are you sure to delete City?`}
        rejectLabel="No"
        acceptLabel="Yes"
        onRejected={() => {
          setDeleteDialogOpen(false);
        }}
        onAccepted={async () => {
          if (!cityId)
            return;
          await deleteCity({
            variables: { id: cityId },
          });
          setDeleteDialogOpen(false);
          navigate("/cities", { replace: true });
        }}
      />
    </>
  );
}
export default CityBrowser;

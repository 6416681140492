import IconButton from "@mui/material/IconButton/IconButton";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import * as React from "react";
const _sx={
  root: {
    color: "#fff"
  }
};
function ToolbarIconButton(props: IconButtonProps) {
  const { children, sx, ...p } = props;
  return (
    <IconButton color="inherit" {...p} sx={{...sx,..._sx}}>
      {children}
    </IconButton>
  );
}

export default ToolbarIconButton;

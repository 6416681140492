import * as React from "react";
import Downshift, { GetItemPropsOptions } from "downshift";
import { cityFindAllQuery } from "./graphql";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Close from "@mui/icons-material/Close";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper/Paper";
import { CityFindAll, CityFindAllVariables, CityFindAll_cityQuery_cities_edges } from "./__generated__/CityFindAll";
import { CityOrderBy } from "../types/global-types";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";



type RenderInputProps = TextFieldProps & {
  ref?: React.Ref<HTMLDivElement>;
  className?: string;
  clearSelection?: () => void;
  openMenu?: () => void;
};

function renderInput(inputProps: RenderInputProps) {
  const {
    InputProps,
    classes,
    clearSelection,
    ref,
    openMenu,
    ...other
  } = inputProps;

  return (
    <TextField
      onChange={(e) => {
        if (e.target.value === "" && clearSelection) clearSelection();
      }}
      sx={{
        flexWrap: "wrap",
        "& .MuiTextField-root": {
          width: "auto",
          flexGrow: 1,
        }
      }}
      InputProps={{
        inputRef: ref,
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={clearSelection}>
              <Close fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={openMenu}>
              <ArrowDropDown />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}

interface RenderSuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<"div", { button?: never }>;
  selectedItem: CityFindAll_cityQuery_cities_edges | null;
  suggestion: CityFindAll_cityQuery_cities_edges;
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = suggestion === selectedItem;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 700 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  );
}


type CityAutoCompleteProps = TextFieldProps & {
  onSelectedItemChange: (oldItem: CityFindAll_cityQuery_cities_edges | null) => void;
  selectedItem: CityFindAll_cityQuery_cities_edges | null;
  className?: string;
  regionId?: string;
};

type PopupMenuProps = {
  menuProps: any;
  getItemProps: (options: GetItemPropsOptions<CityFindAll_cityQuery_cities_edges>) => any;
  selectedItem: CityFindAll_cityQuery_cities_edges | null;
  inputValue?: string;
  highlightedIndex: number | null;
  regionId?: string;
}
function PopupMenu({ menuProps: downshiftProps, selectedItem, inputValue, regionId, highlightedIndex, getItemProps }: PopupMenuProps) {

  const regionWhere = regionId ? { id: regionId } : null;
  const { data } = useQuery<CityFindAll, CityFindAllVariables>(cityFindAllQuery, {
    variables: {
      where: {
        region: regionWhere,
        name_Contains:
          selectedItem && selectedItem.name === inputValue
            ? ""
            : inputValue,
      },
      pagination: {
        take: 20,
      },
      orderBy: [CityOrderBy.name],
    }
  });
  return (<Paper {...downshiftProps} sx={{
    zIndex: 2,
    maxHeight: 300,
    overflowY: "scroll",
    position: "absolute",
    left: 0,
    right: 0
  }} square>
    {
      !data || !data.cityQuery || !data.cityQuery.cities || !data.cityQuery.cities.edges ? null :
        data.cityQuery.cities.edges.map(
          (suggestion: any, index: any) =>
            renderSuggestion({
              suggestion,
              index,
              itemProps: getItemProps({ item: suggestion }),
              highlightedIndex,
              selectedItem,
            })
        )
    }
  </Paper>);
}

function CityAutoComplete(props: CityAutoCompleteProps) {
  const {
    regionId,
    onSelectedItemChange,
    selectedItem,
    className,
    classes: textFieldclasses,
    ...textFieldProps
  } = props;
  return (
    <Downshift
      onSelect={onSelectedItemChange}
      selectedItem={selectedItem}
      itemToString={(item) => (item ? item.name : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        clearSelection,
        openMenu,
        getRootProps
      }) => {
        const { onBlur, ...inputProps } = getInputProps({});

        return (
          <Box {...getRootProps()} position="relative">
            {renderInput({
              fullWidth: true,
              label: "City",
              InputLabelProps: getLabelProps(),
              InputProps: {
                onBlur,
                onFocus: () => {
                  openMenu();
                },
              },
              inputProps,
              clearSelection,
              openMenu,
              ...textFieldProps,
            })}
            {isOpen ? (
              <PopupMenu getItemProps={getItemProps} menuProps={getMenuProps()} selectedItem={selectedItem} inputValue={inputValue ?? ""} highlightedIndex={highlightedIndex} regionId={regionId} />
            ) : null}
          </Box>
        );
      }}
    </Downshift>
  );
}

export default CityAutoComplete;

import * as React from "react";
import { ownerTypeOptions } from "./ownerTypeOptions";
import { checkOwnerCodeExistsQuery } from "./graphql";
import Grid from "@mui/material/Grid/Grid";
import { GetOwnerById_ownerQuery_owner } from "./__generated__/GetOwnerById";
import { CheckOwnerCodeExists, CheckOwnerCodeExistsVariables } from "./__generated__/CheckOwnerCodeExists";
import { useLazyQuery } from "@apollo/client/react";
import TextEditor from "../../components/TextEditor";
import EnumSingleSelectEditor from "../../components/EnumSingleSelectEditor";

export type OwnerEditControlProps = {
  value: GetOwnerById_ownerQuery_owner;
  setValue: (value: GetOwnerById_ownerQuery_owner) => void;
  error: { [key: string]: string };
  setError: (error: { [key: string]: string }) => void;
};
const _sx = {
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
};
function OwnerEditControl({
  value,
  setValue,
  error,
  setError,
}: OwnerEditControlProps) {
  const [checkOwnerCode] = useLazyQuery<
    CheckOwnerCodeExists,
    CheckOwnerCodeExistsVariables
  >(checkOwnerCodeExistsQuery, {
    onCompleted: (data) => {
      if (data?.ownerQuery?.ownerCodeExists) {
        setError({ ...error, code: "Owner code already exists!" });
      } else {
        setError({ ...error, code: "" });
      }
    },
    fetchPolicy: "network-only",
  });
  return (
    <Grid container direction="column">
      <TextEditor
        sx={_sx.formControl}
        label="Owner code"
        value={value.code}
        error={!!error.code}
        helperText={error.code}
        onValidated={(v) => {
          setValue({
            ...value,
            code: v,
          });
          setError({ ...error, code: "" });
          if(v)
            checkOwnerCode({ variables: { id: value.id, checkFor: v } });
        }}
      />
      <TextEditor
        sx={_sx.formControl}
        label="Owner name"
        value={value.name}
        error={!!error.name}
        helperText={error.name}
        onValidated={(v) => {
          setValue({
            ...value,
            name: v,
          });
          setError({ ...error, name: "" });
        }}
      />

      <EnumSingleSelectEditor
        options={ownerTypeOptions}
        sx={_sx.formControl}
        label="Owner type"
        value={value.ownerType}
        onChange={(e) => {
          setValue({ ...value, ownerType: e.target.value as any });
        }}
      />
      <TextEditor
        sx={_sx.formControl}
        label="Contact name"
        value={value.contactPerson}
        error={!!error.contactPerson}
        helperText={error.contactPerson}
        onValidated={(v) => {
          setValue({
            ...value,
            contactPerson: v,
          });
          setError({ ...error, contactPerson: "" });
        }}
      />
      <TextEditor
        sx={_sx.formControl}
        label="Contact number"
        value={value.contactNumber}
        error={!!error.contactNumber}
        helperText={error.contactNumber}
        onValidated={(v) => {
          setValue({
            ...value,
            contactNumber: v,
          });
          setError({ ...error, contactNumber: "" });
        }}
      />
      <TextEditor
        sx={_sx.formControl}
        label="Address"
        rows={4}
        multiline
        value={value.address}
        error={!!error.address}
        helperText={error.address}
        onValidated={(v) => {
          setValue({
            ...value,
            address: v,
          });
          setError({ ...error, address: "" });
        }}
      />
    </Grid>
  );
}

export default OwnerEditControl;

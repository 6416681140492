import { PropertyActiveStatus } from "../../types/global-types";

const propertyActiveStatusOptions: {[ key: string] : PropertyActiveStatusOptionType} = {
    [PropertyActiveStatus.ACTIVE]: { value: PropertyActiveStatus.ACTIVE, text: "Active" },
    [PropertyActiveStatus.SOLD_OUT]: { value: PropertyActiveStatus.SOLD_OUT, text: "Sold out"},
    [PropertyActiveStatus.ARCHIVED]:{value:PropertyActiveStatus.ARCHIVED, text:"Archived"},
};

const propertyActiveStatusOptionList = Object.keys(propertyActiveStatusOptions).map(e => propertyActiveStatusOptions[e]);



export type PropertyActiveStatusOptionType = {
    value: PropertyActiveStatus,
    text: string
};

export  { propertyActiveStatusOptions ,  propertyActiveStatusOptionList  };
import * as React from "react";
import {
  FilterOperand,
  FilterOperator,
  getOperatorsForOperandType,
  getLabelForOperator,
  FilterOperands,
  FilterCondition,
  OperandType
} from "./CsSearchBox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton/IconButton";
import Collapse from "@mui/material/Collapse/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import Icon from "@mui/material/Icon/Icon";
import List from "@mui/material/List/List";
import Popper from "@mui/material/Popper/Popper";
import Paper from "@mui/material/Paper/Paper";
import { styled, Theme } from '@mui/material/styles';
import ClickAwayListener from "@mui/base/ClickAwayListener";
import {SxProps} from '@mui/system';
const Img = styled("img")(
  () => {
    return {
      width: 24,
      height: 24
    };
  }
);



const _sxx = {
  operatorListItem: {
    paddingLeft: 4
  },
  rootList: {
    maxWidth: 400,
    minWidth: 320,
    maxHeight: 400,
    overflowY: "scroll"
  }
};


interface Props {
  operands: FilterOperands;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  selectedFilterCondition: FilterCondition;
  operatorsVisibility: boolean;
  onSelectedConditionChanged: (
    condition: FilterCondition,
    operatorsVisibility: boolean,
    createNewCondition: boolean
  ) => void;
  sx?:SxProps<Theme>;
}

function populateSelectedFilterConditionForOperand(newOperand: FilterOperand, currentSelectedCondition: FilterCondition) {
  const { operand: selectedOperand,
    operator: selectedOperator } = currentSelectedCondition;
  let operator = selectedOperator;
  if (newOperand !== selectedOperand) {
    switch (newOperand.type) {
      case OperandType.STRING:
        operator = FilterOperator.CONTAINS;
        break;
      default:
        operator = FilterOperator.EQUAL;
    }
  }
  const selectedFilterCondition = {
    operand: newOperand,
    operator
  };
  return selectedFilterCondition;
}

function CsSearchBoxPoper(props: Props) {
  const {
    operands,
    open,
    anchorEl,
    onClose,
    onSelectedConditionChanged,
    selectedFilterCondition,
    operatorsVisibility,
    sx
  } = props;
  const { operand: selectedOperand,
    operator: selectedOperator } = selectedFilterCondition;
  const items = React.useMemo(() => Object.keys(operands).map(key => {
    const operand = operands[key];
    if (operand.type === OperandType.DATE || operand.type === OperandType.DATETIME || operand.type === OperandType.ENUM) {
      return null;
    }
    const operators = getOperatorsForOperandType(
      operand.type,
      false
    );
    return (
      <React.Fragment key={operand.propName}>
        <ListItem
          selected={operand.propName === selectedOperand.propName}
          button
          onClick={() => {
            const newCondition = populateSelectedFilterConditionForOperand(operand, selectedFilterCondition);
            onSelectedConditionChanged(newCondition, operatorsVisibility, true);
          }}
        >
          <ListItemText
            id={operand.propName}
            primary={operand.name}
          />
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                const opVisibility = selectedOperand !== operand || !operatorsVisibility;
                const newCondition = populateSelectedFilterConditionForOperand(operand, selectedFilterCondition);
                onSelectedConditionChanged(newCondition, opVisibility, false);
              }}
            >
              {selectedOperand === operand &&
                operatorsVisibility ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse
          in={selectedOperand === operand && operatorsVisibility}
          timeout="auto"
        >
          <List component="div" disablePadding dense>
            {operators.map(operator => {
              const operatorLabel = getLabelForOperator(
                operator
              );
              return (
                <ListItem
                  key={`${operand.propName}_${operator.toString()}`}
                  button
                  sx={_sxx.operatorListItem}
                  selected={operator === selectedOperator}
                  onClick={() => {
                    const selectedFilterCondition = {
                      operand: selectedOperand,
                      operator
                    };
                    onSelectedConditionChanged(
                      selectedFilterCondition,
                      true,
                      true
                    );
                  }}
                >
                  {operatorLabel?.svg ? (
                    <ListItemIcon>
                      <Icon>
                        <Img
                          src={operatorLabel?.svg}
                        />
                      </Icon>
                    </ListItemIcon>
                  ) : null}
                  <ListItemText primary={operatorLabel?.label} />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }), [operands, selectedOperand, operatorsVisibility, selectedFilterCondition, onSelectedConditionChanged, selectedOperator]);
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      sx={sx}
    >
      <Paper elevation={5}  >
        <ClickAwayListener onClickAway={onClose}>
          <List dense sx={_sxx.rootList}>
            {items}
          </List>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
}

export default CsSearchBoxPoper;

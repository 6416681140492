import * as React from "react";
import RegionEditControl from "./RegionEditControl";
import { getRegionByIdQuery, updateRegionMutation } from "./graphql";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { CityFindAll_cityQuery_cities_edges_region } from "./__generated__/CityFindAll";
import { GetRegionById, GetRegionByIdVariables } from "./__generated__/GetRegionById";
import { UpdateRegion, UpdateRegionVariables } from "./__generated__/UpdateRegion";
import DialogActionLoadingIndicator from "../components/DialogActionLoadingIndicator";
import { useMutation, useQuery } from "@apollo/client";
export type RegionUpdateDialogProps = {
  open: boolean;
  onClose: () => void;
  onError: (error: Error) => void;
  id: string;
};
const regionDefaultValue: CityFindAll_cityQuery_cities_edges_region = {
  id:null,
  alias:"",
  name:"",
  sortOrder:null
};
function RegionUpdateDialog({
  open,
  onClose,
  onError,
  id,
}: RegionUpdateDialogProps) {
  const { data: regionQueryResult, loading } = useQuery<
    GetRegionById,
    GetRegionByIdVariables
  >(getRegionByIdQuery, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "network-only",
  });
  const [draft, setDraft] = React.useState<CityFindAll_cityQuery_cities_edges_region>(regionDefaultValue);
  React.useEffect(() => {
    if (
      regionQueryResult &&
      regionQueryResult.regionQuery &&
      regionQueryResult.regionQuery.region
    ) {
      setDraft({
        ...regionQueryResult.regionQuery.region,
      });
      setError({});
    }
  }, [regionQueryResult]);

  const [error, setError] = React.useState<{ [key: string]: string }>({});
  const [
    createRegion,
    { loading: updatingRegion, error: createError },
  ] = useMutation<UpdateRegion, UpdateRegionVariables>(
    updateRegionMutation
  );

  const validate = React.useCallback(() => {
    var valid = true;
    const e = { ...error };
    if (draft.alias) {
      e.alias = "";
    } else {
      valid = false;
      e.alias = "Alias is required";
    }
    if (draft.name) {
      e.name = "";
    } else {
      valid = false;
      e.alias = "Name is required";
    }

    setError(e);
    return valid;
  }, [draft.alias, draft.name, error]);

  const submit = React.useCallback(async () => {
    if (validate()) {
      const {
          id,
        name,
        alias,
        sortOrder
      } = draft;
      await createRegion({
        variables: {
          region: {
            id,
            name,
            alias,
            sortOrder
          },
        },
      });
      onClose();
    }
  }, [createRegion, draft, onClose, validate]);

  React.useEffect(() => {
    if (createError) onError(createError);
  }, [createError, onError]);
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Update Region Info</DialogTitle>
      <DialogContent>
        <RegionEditControl
          value={draft}
          setValue={setDraft}
          error={error}
          setError={setError}
        />
      </DialogContent>
      <DialogActions>
        <DialogActionLoadingIndicator
          loading={updatingRegion || loading}
          text={loading? "Loading region info..." : "Updating region info..."}
        />
        <Button disabled={updatingRegion || loading} onClick={submit} color="secondary">
          OK
        </Button>
        <Button disabled={updatingRegion || loading} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RegionUpdateDialog;

import * as React from "react";
import Gallery, { GalleryProps, PhotoProps, renderImageClickHandler, RenderImageProps } from "react-photo-gallery";
import { useDropzone } from "react-dropzone";
import GalleryImage, { GalleryImageProps } from "./GalleryImage";
import {
  SortableContainer,
  SortEndHandler,
  SortableElement,
} from "react-sortable-hoc";
import {arrayMoveImmutable} from "array-move";
import Carousel, { Modal, ModalGateway } from "react-images";
import Sort from "@mui/icons-material/Sort";
import Close from "@mui/icons-material/Close";
import update from "immutability-helper";
import generateUniqueId from "../../generateUniqueId";
import config from "../../config.json";
import { store } from '../../app/store';
import { deletePropertyPhotoMutation, propertyPhotosFragment } from "./graphql";
import Fab from "@mui/material/Fab/Fab";
import Grid from "@mui/material/Grid/Grid";
import { GetPropertyById_propertyQuery_property, GetPropertyById_propertyQuery_property_propertyPhotos } from "./__generated__/GetPropertyById";
import { DeletePropertyPhoto } from "./__generated__/DeletePropertyPhoto";
import { DeletePropertyVariables } from "./__generated__/DeleteProperty";
import { useMutation } from "@apollo/client/react/hooks";
import Box from '@mui/material/Box';
import ActivityIndicatorDialog from "../../components/ActivityIndicatorDialog";
import ErrorMessageDialog from "../../components/ErrorMessageDialog";
const _sx={
  dropZone: {
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
  },
  galleryWrapper: {
    position: "relative",
  },
  fab: {
    position: "absolute",
    right: 16,
    bottom: 16,
  },
};
export type PropertyGalleryPhotoGraphType = PhotoProps<{
  propertyPhoto: GetPropertyById_propertyQuery_property_propertyPhotos & { __typename?: string };
  file?: File;
}>;
export type PhotosResultCallback = () => ReadonlyArray<GetPropertyById_propertyQuery_property_propertyPhotos>;
export type GalleryViewProps = {
  propertyPhotos: ReadonlyArray<GetPropertyById_propertyQuery_property_propertyPhotos>;
  propertyId: string;
  registerPhotoResultCallback: (callback: PhotosResultCallback) => void;
  savePhotos: () => void;
};

const PropertyPhotoGallery = Gallery as unknown as React.ElementType<GalleryProps<PropertyGalleryPhotoGraphType>>;

const ImageModalGateway = ModalGateway as unknown as React.ElementType;
const SortableGalleryImage = SortableElement<GalleryImageProps>(GalleryImage);
const SortableGallery = SortableContainer<{ photos: Array<PropertyGalleryPhotoGraphType> }>(
  ({ photos }: { photos: Array<PropertyGalleryPhotoGraphType> }) => {
    const imageRenderer = React.useCallback(
      ({ index, left, top, photo, direction, onClick, key }: RenderImageProps<PropertyGalleryPhotoGraphType> & {key:string}) => (
        <SortableGalleryImage
          direction={direction}
          key={key}
          margin={"2px"}
          photo={photo}
          left={left}
          top={top}
          index={index}
          onClick={onClick}
          sortMode
          loading={!!photo.file}
        />
      ),
      []
    );
    return <PropertyPhotoGallery photos={photos} renderImage={imageRenderer as any} />;
  }
);

const uploadPhoto = async (photo: PropertyGalleryPhotoGraphType) => {
  const {
    file,
    propertyPhoto: { id },
  } = photo;

  if (!file)
    return null;
  const form = new FormData();
  form.append("file", file, `${id}.${file.name.split(".")[1]}`);
  form.append("id", photo?.propertyPhoto?.id!);
  form.append("propertyId", photo.propertyPhoto.propertyId);
  form.append("caption", photo.propertyPhoto.caption);
  form.append("description", photo.propertyPhoto.description);
  form.append(
    "sortOrder",
    photo.propertyPhoto.sortOrder
      ? photo.propertyPhoto.sortOrder.toString()
      : ""
  );
  form.append(
    "photo",
    JSON.stringify({
      Caption: photo.propertyPhoto.caption,
      Description: photo.propertyPhoto.description,
      Id: photo.propertyPhoto.id,
      Url: photo.propertyPhoto.url,
      SortOrder: photo.propertyPhoto.sortOrder,
      PropertyId: photo.propertyPhoto.propertyId,
    })
  );
  const result = await fetch("/FileUpload/PropertyPhoto", {
    method: "POST",
    body: form,
    headers: {
      Authorization: `Bearer ${store.getState().site.userSession.accessToken}`,
    },
  });
  const uploadedPropertyPhoto = await result.json();
  const uploadedPhoto: PropertyGalleryPhotoGraphType = {
    ...photo,
    propertyPhoto: uploadedPropertyPhoto,
    src: `${config.cloudfront_url}${uploadedPropertyPhoto.url}`,
  };
  return uploadedPhoto;
};
function GalleryView({
  propertyId,
  propertyPhotos,
  registerPhotoResultCallback,
  savePhotos
}: GalleryViewProps) {
  const [photos, setPhotos] = React.useState<
    Array<PropertyGalleryPhotoGraphType>
  >([]);

  const [uploading, setUploading] = React.useState(false);
  const [operationError, setOperationError] = React.useState<Error | null>(null);
  const [operationErrorMessage, setOperationErrorMessage] = React.useState<
    string
  >("");
  const [errorMessageDialogOpen, setErrorMessageDialogOpen] = React.useState(
    false
  );

  const performUpload = React.useCallback(
    (photos: PropertyGalleryPhotoGraphType[]) => {
      setUploading(true);
      var snapShop = photos;
      const starterPromise = Promise.resolve(null);
      photos
        .reduce(
          (p: Promise<PropertyGalleryPhotoGraphType | null>, current, index) =>
            p.then(() => {
              if (!current.file)
                return Promise.resolve(null);
              return uploadPhoto({
                ...current,
                propertyPhoto: {
                  ...current.propertyPhoto,
                  sortOrder: index,
                },
              }).then((uploaded) => {
                if (uploaded)
                  snapShop = update(snapShop, {
                    [index]: { $set: uploaded },
                  });
                setPhotos(snapShop);
                return uploaded;
              });
            }
            ),
          starterPromise
        )
        .finally(() => {
          setUploading(false);
        });
    },
    []
  );

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      var newPhotos = acceptedFiles.map((file) => {
        const photo: PropertyGalleryPhotoGraphType = {
          src: window.URL.createObjectURL(file),
          width: 4,
          height: 4,
          propertyPhoto: {
            id: generateUniqueId(),
            propertyId,
            caption: "",
            description: "",
            sortOrder: null,
            url: "",
          },
          file,
        };
        return photo;
      });
      newPhotos = [...photos, ...newPhotos];
      setPhotos(newPhotos);
      try {
        await performUpload(newPhotos);
      } catch (e: any) {
        setOperationError(e);
        setOperationErrorMessage(
          "Could not upload photos! Please reload the page."
        );
        setErrorMessageDialogOpen(true);
      }
    },
    [performUpload, photos, propertyId]
  );

  React.useEffect(() => {
    if (propertyPhotos) {
      const photos: Array<PropertyGalleryPhotoGraphType> = propertyPhotos.map(
        (photo) => ({
          src: `${config.cloudfront_url}${photo.url}`,
          width: 4,
          height: 4,
          propertyPhoto: photo,
        })
      );
      setPhotos(photos);
    } else {
      setPhotos([]);
    }
  }, [propertyPhotos]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  const { className: dropZoneClassName, ...dropZoneProps } = getRootProps();
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    setPhotos(arrayMoveImmutable(photos, oldIndex, newIndex));
  };
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = React.useCallback<renderImageClickHandler>((_, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const [
    executeDeletePropertyPhotoMutation,
    { loading: deleting, error: deleteError },
  ] = useMutation<DeletePropertyPhoto, DeletePropertyVariables>(
    deletePropertyPhotoMutation
  );
  React.useEffect(() => {
    if (deleteError) {
      setOperationErrorMessage("Could not delete photo");
      setOperationError(deleteError);
      setErrorMessageDialogOpen(true);
    }
  }, [deleteError]);
  const deletePropertyPhoto = React.useCallback(
    (photo: PropertyGalleryPhotoGraphType, index:number) => {
      if(!photo?.propertyPhoto?.id)
        return;
      executeDeletePropertyPhotoMutation({
        variables: {
          id: photo?.propertyPhoto?.id!,
        },
        update: (cache) => {
          var cachedPropertyPhoto = cache.readFragment<Partial<GetPropertyById_propertyQuery_property>>({
            id: `Property:${photo.propertyPhoto.propertyId}`,
            fragment: propertyPhotosFragment,
          });
          cache.writeFragment({
            id: `Property:${photo.propertyPhoto.propertyId}`,
            fragment: propertyPhotosFragment,
            data: update(cachedPropertyPhoto, {
              propertyPhotos: {
                $splice: [[index, 1]],
              },
            }),
          });
        },
      });
    },
    [executeDeletePropertyPhotoMutation]
  );

  const imageRenderer = React.useCallback(
    ({ index, left, top, photo,key, direction, onClick }:RenderImageProps<PropertyGalleryPhotoGraphType> & {key:string}) => (
      <GalleryImage
        direction={direction}
        margin={"2px"}
        photo={photo}
        left={left}
        top={top}
        key={key}
        index={index}
        onClick={onClick}
        onDelete={(index) => {
          deletePropertyPhoto(photo, index);
        }}
        loading={!!photo.file}
        disableDelete={uploading}
      />
    ),
    [deletePropertyPhoto, uploading]
  );
  const [sortMode, setSortMode] = React.useState(false);
  const photoResultCallback = React.useCallback(() => {
    return photos.map((p, index) => {
      const { __typename, ...photo } = p.propertyPhoto;
      return {
        ...photo,
        sortOrder: index,
      };
    });
  }, [photos]);
  React.useEffect(() => {
    registerPhotoResultCallback(photoResultCallback);
  }, [photoResultCallback, registerPhotoResultCallback]);

  return (
    <>
      <Grid item xs={12}>
        <Box sx={_sx.galleryWrapper}>
          {sortMode ? (
            <SortableGallery
              photos={photos}
              onSortEnd={onSortEnd}
              axis={"xy"}
            />
          ) : (
            <PropertyPhotoGallery
              onClick={openLightbox}
              photos={photos}
              renderImage={imageRenderer as any}
            />
          )}
          {photos && photos.length > 1 && !uploading ? (
            !sortMode ? (
              <Fab
                color="secondary"
                sx={_sx.fab}
                onClick={() => {
                  setSortMode(true);
                }}
                aria-label="Order photo"
              >
                <Sort></Sort>
              </Fab>
            ) : (
              <Fab
                color="secondary"
                sx={_sx.fab}
                onClick={() => {
                  setSortMode(false);
                  savePhotos();
                }}
                aria-label="Close"
              >
                <Close />
              </Fab>
            )
          ) : null}
        </Box>
      </Grid>
      {uploading ? null : (
        <Grid item xs={12}>
          <Box
            {...dropZoneProps}
            sx={_sx.dropZone}
            className={dropZoneClassName}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the photos here ...</p>
            ) : (
              <p>Drag 'n' drop some photos here, or click to select files</p>
            )}
          </Box>
        </Grid>
      )}

      <ImageModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                source: x.src,
                caption: x.propertyPhoto.caption,
              }))}
            />
          </Modal>
        ) : null}
      </ImageModalGateway>
      <ActivityIndicatorDialog open={deleting} message={"Deleting photo..."} />
      {operationError ? (
        <ErrorMessageDialog
          open={errorMessageDialogOpen}
          onClose={() => {
            setErrorMessageDialogOpen(false);
          }}
          title={`Error`}
          message={operationErrorMessage}
          detail={operationError.message}
        />
      ) : null}
    </>
  );
}
export default GalleryView;

import * as React from "react";
import { checkPropertyCodeExistsQuery } from "./graphql";
import OwnerAutoComplete, { OwnerOptionType } from "../owner/OwnerAutoComplete";
import { propertyTypeOptions } from "./propertyTypeOptions";
import { propertyStatusOptions } from "./propertyStatusOptions";
import { landTypeOptions } from "./landTypeOptions";
import { pricingMethodOptions } from "./pricingMethodOptions";
import { propertyActiveStatusOptions } from "./propertyActiveStatusOptions";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { GetPropertyById_propertyQuery_property, GetPropertyById_propertyQuery_property_city, GetPropertyById_propertyQuery_property_owner } from "./__generated__/GetPropertyById";
import { CheckPropertyCodeExists, CheckPropertyCodeExistsVariables } from "./__generated__/CheckPropertyCodeExists";
import { useLazyQuery } from "@apollo/client/react/hooks";
import { GetCityById_cityQuery_city, GetCityById_cityQuery_city_region } from "../../city/__generated__/GetCityById";
import FieldGroup from "../../components/FieldGroup";
import TextEditor from "../../components/TextEditor";
import EnumSingleSelectEditor from "../../components/EnumSingleSelectEditor";
import NumberEditor from "../../components/NumberEditor";
import RegionAutoComplete from "../../city/RegionAutoComplete";
import CityAutoComplete from "../../city/CityAutoComplete";

export type PropertyEdit =  Omit<Omit<GetPropertyById_propertyQuery_property,"owner">,"city"> & {__typename?:string, owner:GetPropertyById_propertyQuery_property_owner | null, city:GetPropertyById_propertyQuery_property_city| null};

export type PropertyEditControlProps = {
  value: PropertyEdit;
  setValue: (value: PropertyEdit) => void;
  error: { [key: string]: string };
  setError: (error: { [key: string]: string }) => void;
};

const _sx={
  formControl: {
    marginTop: 1,
    marginBottom: 1,
  },
};

function PropertyEditControl({
  value,
  setValue,
  error,
  setError,
}: PropertyEditControlProps) {
  const [checkPropertyCode] = useLazyQuery<
    CheckPropertyCodeExists,
    CheckPropertyCodeExistsVariables
  >(checkPropertyCodeExistsQuery, {
    onCompleted: (data) => {
      if (data?.propertyQuery?.propertyCodeExists) {
        setError({ ...error, propertyCode: "Property code already exists!" });
      } else {
        setError({ ...error, propertyCode: "" });
      }
    },
    fetchPolicy: "network-only",
  });

  const [region, setRegion] = React.useState<GetCityById_cityQuery_city_region| null>(null);

  React.useEffect(() => {
    if (region && value.city && region.id !== value?.city?.region?.id) {
      setValue({ ...value, city: null });
    }
  }, [region, setValue, value]);


  return (
    <>
      <FieldGroup>
        <TextEditor
          sx={_sx.formControl}
          label="Property code"
          value={value.propertyCode}
          error={!!error.propertyCode}
          helperText={error.propertyCode}
          onValidated={(v) => {
            setValue({
              ...value,
              propertyCode: v,
            });
            setError({ ...error, propertyCode: "" });
            if (v)
              checkPropertyCode({
                variables: { id: value.id, checkFor: v },
              });
          }}
        />
        <OwnerAutoComplete
          sx={_sx.formControl}
          selectedItem={value.owner as OwnerOptionType}
          onSelectedItemChange={(item: OwnerOptionType|null) => {
            setValue({ ...value, owner: item });
            setError({ ...error, owner: "" });
          }}
          error={!!error.owner}
          helperText={error.owner}
        />
        <EnumSingleSelectEditor
          options={propertyTypeOptions}
          sx={_sx.formControl}
          label="Property type"
          value={value.propertyType}
          onChange={(e) => {
            setValue({ ...value, propertyType: e.target.value as any });
          }}
        />
        <EnumSingleSelectEditor
          options={propertyStatusOptions}
          sx={_sx.formControl}
          label="Property status"
          value={value.propertyStatus}
          onChange={(e) => {
            setValue({ ...value, propertyStatus: e.target.value as any });
          }}
        />
        <EnumSingleSelectEditor
          options={landTypeOptions}
          sx={_sx.formControl}
          label="Land type"
          value={value.landType}
          onChange={(e) => {
            setValue({ ...value, landType: e.target.value as any });
          }}
        />
        <EnumSingleSelectEditor
          options={pricingMethodOptions}
          sx={_sx.formControl}
          label="Pricing Method"
          value={value.pricingMethod}
          onChange={(e) => {
            setValue({ ...value, pricingMethod: e.target.value as any });
          }}
        />
        <EnumSingleSelectEditor
          options={propertyActiveStatusOptions}
          sx={_sx.formControl}
          label="Active Status"
          value={value.activeStatus}
          onChange={(e)=>{
            setValue({...value,activeStatus: e.target.value as any});
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Price"
          value={value.price??undefined}
          error={!!error.price}
          helperText={error.price}
          onValidated={(v) => {
            setValue({
              ...value,
              price: v??null,
            });
            setError({ ...error, price: "" });
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Area"
          value={value.area??undefined}
          error={!!error.area}
          helperText={error.area}
          onValidated={(v) => {
            setValue({
              ...value,
              area: v??null,
            });
            setError({ ...error, area: "" });
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Length"
          value={value.length??undefined}
          error={!!error.length}
          helperText={error.length}
          onValidated={(v) => {
            setValue({
              ...value,
              length: v??null,
            });
            setError({ ...error, length: "" });
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Width"
          value={value.width??undefined}
          error={!!error.width}
          helperText={error.width}
          onValidated={(v) => {
            setValue({
              ...value,
              width: v??null,
            });
            setError({ ...error, width: "" });
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Height"
          value={value.height??undefined}
          error={!!error.height}
          helperText={error.height}
          onValidated={(v) => {
            setValue({
              ...value,
              height: v??null,
            });
            setError({ ...error, height: "" });
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Sort Order"
          value={value.sortOrder??undefined}
          error={!!error.sortOrder}
          helperText={error.sortOrder}
          numberPrecision={0}
          onValidated={(v) => {
            setValue({
              ...value,
              sortOrder: v??null,
            });
            setError({ ...error, sortOrder: "" });
          }}
        />
      </FieldGroup>
      <FieldGroup>
        <NumberEditor
          sx={_sx.formControl}
          label="Master Bed Room"
          value={value.mBRoom??undefined}
          error={!!error.mBRoom}
          helperText={error.mBRoom}
          onValidated={(v) => {
            setValue({
              ...value,
              mBRoom: v??null,
            });
            setError({ ...error, mBRoom: "" });
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Single Bed Room"
          value={value.sBRoom??undefined}
          error={!!error.sBRoom}
          helperText={error.sBRoom}
          onValidated={(v) => {
            setValue({
              ...value,
              sBRoom: v??null,
            });
            setError({ ...error, sBRoom: "" });
          }}
        />
        <NumberEditor
          sx={_sx.formControl}
          label="Floor"
          value={value.floor??undefined}
          error={!!error.floor}
          helperText={error.floor}
          onValidated={(v) => {
            setValue({
              ...value,
              floor: v??null,
            });
            setError({ ...error, floor: "" });
          }}
        />
        <RegionAutoComplete
          sx={_sx.formControl}
          selectedItem={region}
          onSelectedItemChange={(item: GetCityById_cityQuery_city_region | null) => {
            setRegion(item);
            setError({ ...error, region: "" });
          }}
          error={!!error.region}
          helperText={error.region}
        />
        <CityAutoComplete
          sx={_sx.formControl}
          selectedItem={value.city}
          onSelectedItemChange={(item: GetCityById_cityQuery_city|null) => {
            setValue({
              ...value,
              city: item,
            });
            setError({ ...error, city: "" });
          }}
          regionId={region?.id??undefined}
          error={!!error.city}
          helperText={error.city}
        />
        <TextEditor
          sx={_sx.formControl}
          label="Address"
          rows={4}
          multiline
          value={value.address}
          error={!!error.address}
          helperText={error.address}
          onValidated={(v) => {
            setValue({
              ...value,
              address: v,
            });
            setError({ ...error, address: "" });
          }}
        />
        <TextEditor
          sx={_sx.formControl}
          label="About"
          rows={4}
          multiline
          value={value.about??undefined}
          error={!!error.about}
          helperText={error.about}
          onValidated={(v) => {
            setValue({
              ...value,
              about: v,
            });
            setError({ ...error, about: "" });
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={_sx.formControl}
              checked={value.elevator}
              onChange={(e) => {
                setValue({ ...value, elevator: e.target.checked });
              }}
            />
          }
          label="Elevator"
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={_sx.formControl}
              checked={value.swimmingPool}
              onChange={(e) => {
                setValue({ ...value, swimmingPool: e.target.checked });
              }}
            />
          }
          label="Swimming pool"
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={_sx.formControl}
              checked={value.electricAndWaterMeter}
              onChange={(e) => {
                setValue({
                  ...value,
                  electricAndWaterMeter: e.target.checked,
                });
              }}
            />
          }
          label="Electric and Water Meter"
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={_sx.formControl}
              checked={value.homeLoanAvailable}
              onChange={(e) => {
                setValue({ ...value, homeLoanAvailable: e.target.checked });
              }}
            />
          }
          label="Home Loan Available"
        />
      </FieldGroup>
    </>
  );
}

export default PropertyEditControl;

import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
export type UserSession = Readonly<{
  accessToken?: string;
  userId?: string;
  userName?: string;
  fullName?: string;
  userRole?: string;
  identityCode?: number;
  defaultAdmin?: boolean;
  validFrom?: Date;
  validTo?: Date;
  authenticated: boolean;
}>;

export const userSessionInitialState: UserSession = {
  authenticated: false,
};



export const userSessionSlice = createSlice({
  name: "userSession",
  initialState: userSessionInitialState,
  reducers: {
    setUserSession: (state, action: PayloadAction<UserSession>) => {
      localStorage.setItem("token", action.payload.accessToken ?? "");
      return action.payload;
    },
  },
});
export const { setUserSession } = userSessionSlice.actions;
export default userSessionSlice.reducer;
